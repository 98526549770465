import React from 'react';

import {
  Content,
  Link,
  LinkSection,
  LogoContainer,
  SocialLink,
  SocialLinkSection,
  Wrapper,
} from './elements';

function Footer(props: any) {
  const { termsAndConditions, privacy, socialLinks, logo } = props;

  return (
    <Wrapper>
      <LogoContainer src={logo.src} alt={logo.alt} />
      <Content hasSocialBlock={socialLinks && socialLinks.length > 0}>
        <LinkSection>
          <Link href={termsAndConditions.href}>{termsAndConditions.label}</Link>
          <Link href={privacy.href}>{privacy.label}</Link>
        </LinkSection>
        {socialLinks && (
          <SocialLinkSection>
            {socialLinks.map(({ href, src, alt }) => (
              <SocialLink href={href} key={alt}>
                <img src={src} alt={alt} />
              </SocialLink>
            ))}
          </SocialLinkSection>
        )}
      </Content>
    </Wrapper>
  );
}

export default Footer;

import React from 'react';
import { Wrapper, Caption, Description, Image, Text } from './elements';

type Props = {
  imageUrl: string;
  caption: string;
  text?: string;
  children?: any;
};
const Step = ({ imageUrl, caption, text, children }: Props) => (
  <Wrapper>
    <Image imageUrl={imageUrl} />
    <Description>
      <Caption>{caption}</Caption>
      {text && <Text>{text}</Text>}
    </Description>
    {children}
  </Wrapper>
);

export default Step;

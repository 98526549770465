import { Button } from '_common/components';
import styled from 'styled-components';
import * as Layout from '_common/components/Layout/Layout';

export const RowWrapper = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  justify-content: space-between;

  .ant-row.ant-form-item {
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '48%')};
  }
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 480px;
  flex-shrink: 0;

  & > form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  @media only screen and (max-width: 768px) {
    width: auto;
    flex-shrink: 1;
  }
  .has-error .ant-input {
    border-color: #ff3654 !important;
  }
`;

export const LeftSectionContent = styled.div<{ isIntegratedFlow?: boolean }>`
  padding: 10px 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  flex-grow: 1;
  max-height: ${({ isIntegratedFlow }) =>
    `calc(100vh - ${isIntegratedFlow ? 370 : 191}px)`};
  @media only screen and (max-width: 768px) {
    padding: 10px 20px;
    margin-bottom: auto;
    max-height: initial;
    overflow: auto;
  }
`;

export const DetailsPageWrapper = styled.div`
  ${Layout.Container} {
    height: calc(100vh - 170px);
    max-height: calc(100vh - 170px);

    @media only screen and (max-width: 768px) {
      height: auto;
      max-height: initial;
    }
  }
  .has-error .ant-input {
    border-color: #ff3654 !important;
  }

  @media only screen and (max-width: 768px) {
    ${Layout.LeftSection} {
      padding: 0 20px;
      width: 100%;
      min-height: calc(100vh - 190px);
      margin-bottom: 0;
    }
  }
`;

export const BackButton = styled(Button)`
  && {
    border-radius: 3px;
    margin-top: 19px;
    font-family: Arial, sans-serif;
    font-size: 23px;
    letter-spacing: 0;
    text-align: center;
    color: #222222;
    background: #fff;
    border: 1px solid #c7c7cc;

    &:hover,
    &:active,
    &:focus {
      background: #fff;
      color: #222222;
      border: 1px solid #949499;
      opacity: 1;
    }

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
`;

export const Terms = styled.span`
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  color: #000000;
  margin-left: 10px;

  a {
    color: #000000;
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
      color: #000000;
    }

    &:focus {
      outline: 2px solid rgba(24, 144, 255, 0.2);
    }
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
`;

export const CheckboxFormItemContainer = styled.div`
  & .has-error {
    .ant-checkbox-inner {
      border-color: #f5222d !important;
    }
  }
`;

export const Title = styled.h2<{ ref: any }>`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.3px;
  color: #000000;

  @media only screen and (max-width: 768px) {
    font-size: 20px;
    margin-top: 0;
  }
`;

export const OrderTitle = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  color: #000000;
  margin-bottom: 8px;
`;

export const OrderNumber = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 16px;
  color: #000000;
  margin-bottom: 30px;
`;
export const SubTitle = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 16px;
  font-weight: bold;
  color: #282d32;
  margin-bottom: 10px;
`;

export const IntegratedButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 40px;
  @media only screen and (max-width: 768px) {
    padding: 10px 20px;
  }
`;

export const LoadingMessage = styled.div`
  padding-left: 39px;
  font-family: Arial, sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.3px;
  color: #000000;
  margin-top: 20px;
`;

import React from 'react';
import { observer, inject } from 'mobx-react';

import LocationStore from 'pages/success/stores/locationStore';
import StoreItem from '../StoreItem/StoreItem';
import { LocationsWrapper, LocationsItems } from './StoreListElements';
import { IStore } from 'types/store';

type Props = {
  locationStore?: LocationStore;
  onStoreClick?: (store: IStore) => void;
};
type State = {};

@observer
class StoreList extends React.Component<Props, State> {
  renderLocationItem = (store: IStore) => {
    const {
      locationStore: { activeStoreId, assetsHub },
    } = this.props;
    const isActive = activeStoreId === store.storeId;
    const assets = assetsHub.get(store.companyId);

    return (
      <StoreItem
        key={store.storeId}
        isActive={isActive}
        store={store}
        onClick={this.props.onStoreClick}
        customerLogo={assets && assets.logo}
      />
    );
  };

  render() {
    const {
      locationStore: { stores },
    } = this.props;
    return (
      <LocationsWrapper>
        <LocationsItems>{stores.map(this.renderLocationItem)}</LocationsItems>
      </LocationsWrapper>
    );
  }
}

export default inject('locationStore')(StoreList);

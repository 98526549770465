import styled from 'styled-components';
import { Button } from '_common/components';

export const ReturnReasonWrapper = styled.div<{ marginBottom: number }>`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px;`}
`;

export const ReturnReasonRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  width: 100%;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const ReturnReasonButtonWrapper = styled(Button)`
  && {
    border-radius: 3px;
    font-family: ${props => props.theme.fonts.basic};
    font-size: 14px;
    letter-spacing: 0;
    text-align: center;
    color: #231f20;
    background: #fff;
    border: 1px solid #c7c7cc;

    width: 92px;
    height: 92px;

    & > span {
      white-space: normal;
      word-break: break-word;
    }

    &:nth-child(-n + 3) {
      margin-right: 10px;
    }

    @media only screen and (max-width: 768px) {
      width: 77px;
      height: 77px;

      font-size: 10px;
      font-weight: bold;
      line-height: 1.3;
      text-align: center;
      color: #000000;

      &:nth-child(-n + 3) {
        margin-right: 9px;
      }
    }

    &:hover,
    &:active,
    &:focus {
      background: #fff;
      color: #222222;
      border: 1px solid #949499;
      opacity: 1;
    }
  }
`;

export const Heading = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #282d32;
`;

import styled, { css } from 'styled-components';

export const LeftSection = styled.div`
  position: relative;
  flex-grow: 1;

  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 480px;
  overflow-y: auto;
  & > div {
    width: 90%;
    min-width: 250px;
    max-width: 500px;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    min-height: calc(100vh - 70px);
    flex-shrink: 1;
    & > div {
      width: 100%;
    }
  }
`;

export const RightSection = styled.div<{ widthOfBackgroundImage: number }>`
  flex-basis: ${({ widthOfBackgroundImage }) => `${widthOfBackgroundImage}px`};
  position: relative;

  @media only screen and (max-width: 768px) {
    flex-basis: auto;
    display: none;
  }
`;

export const LeftSectionNotResponsive = styled.div<{
  integratedFlow?: boolean;
}>`
  width: 480px;
  position: relative;
  display: flex;
  flex-direction: column;
  border: none;
  padding-top: ${({ integratedFlow }) =>
    integratedFlow ? '100px' : undefined};

  @media only screen and (max-width: 768px) {
    width: 100%;
    min-height: auto;
    padding-top: unset;
  }
`;

export const RightSectionNotResponsive = styled.div<{
  integratedFlow?: boolean;
}>`
  width: calc(100% - 480px);
  ${({ integratedFlow }) =>
    integratedFlow
      ? css`
          padding-top: 100px;
        `
      : css`
          position: relative;
        `};
  @media only screen and (max-width: 768px) {
    display: none;
    padding-top: unset;
  }
`;

export const Container = styled.div<{ integratedFlow?: boolean }>`
  display: flex;
  position: ${({ integratedFlow }) =>
    integratedFlow ? 'relative' : undefined};
  height: 100%;
`;

import { Select } from 'antd';
import styled from 'styled-components';

export const StyledSelect = styled(Select)`
  width: 100%;

  .ant-select-selection--single {
    height: 48px;
  }

  .ant-select-selection__rendered {
    line-height: 48px;
    color: #000000;
  }

  .ant-select-selection__placeholder {
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    color: #000000;
    opacity: 0.5;
  }
`;

import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { Form } from 'antd';
import { reaction } from 'mobx';

import { LoaderArc, InfoPanel } from '_common/components';
import amplitude from '_common/utils/amplitude';
import { DownloadButton, Wrapper, LoaderBlock } from './elements';
import { AsyncStatus } from '_common/constants/common';
import { isPaymentPageAvailable } from '_common/utils';

import { OrderStore, LocationStore, DirectoryStore } from 'stores';
import commonStoresActions from '_common/actions';
import IntegratedFlowUI from './components/IntegratedFlowUI';
import NonIntegratedFlowUI from './components/NonIntegratedFlowUI';
import { WHITELABEL_UI, withWhitelabelProps } from '_common/whitelabelConfig';
import { isIE } from 'react-device-detect';
import { IStore } from 'types/store';
import { IRouterMatch } from 'types/core';
import { FormComponentProps } from 'antd/lib/form';

type Props = RouteComponentProps<IRouterMatch> &
  FormComponentProps & {
    locationStore: LocationStore;
    directoryStore: DirectoryStore;
    whitelabelProxyStore: any;
    orderStore: OrderStore;
    detailsPageStore: any;
    whiteLabeled: any;
  };

type State = {
  labelLoadingStatus: string;
  isUserAwaitingNewTab: boolean;
  geo: object;
};

@observer
class SuccessPage extends Component<Props, State> {
  static PAGE_NAME = 'Success page';

  _isMount = true;

  intergratedFlowView: any;

  state = {
    labelLoadingStatus: AsyncStatus.LOADING,
    isUserAwaitingNewTab: false,
    geo: {},
  };

  constructor(props: Props) {
    super(props);

    this.intergratedFlowView = WHITELABEL_UI.pages.success.isIntergratedFlowViewActive(
      this.props.orderStore
    );
    /** case when config loaded before (no redirects) */
    if (props.directoryStore.status === AsyncStatus.SUCCESS) {
      this.processPreadvice();
      console.log(
        'SUCCESS PAGE: props.directoryStore.status === AsyncStatus.SUCCESS'
      );
    }

    reaction(
      () => props.directoryStore.paymentConfig,
      (paymentConfig, reactionFunc) => {
        reactionFunc.dispose();
        const {
          match: {
            params: { company },
          },
          whitelabelProxyStore: { consumerPaymentWasSuccessful },
        } = props;

        /** AP specific.
         * 1. Need to check was payment required or not.
         * 2. Need to validate, that's we accessing page with valid fingerprint.
         * */
        if (
          paymentConfig &&
          isPaymentPageAvailable(paymentConfig.paymentType)
        ) {
          /** Means we redirected by SecureFrame here, need to validate FP. */
          if (consumerPaymentWasSuccessful) {
            /** here is code for secureFrame, when payment is enabled and redirected from SF. */
            this.processPreadvice();
          } else {
            console.log('fingerprint not valid!');
            return this.props.history.push(`/${company}/payment`);
          }
        } else if (this._isMount) {
          this.processPreadvice();
        }
      }
    );
  }

  componentDidMount() {
    amplitude.logEventWithOrganisationAndUrl('page_open', {
      page_name: SuccessPage.PAGE_NAME,
    });
  }

  componentWillUnmount() {
    this._isMount = false;
    if (this.props.whitelabelProxyStore.orderWasSubmitted) {
      commonStoresActions.resetStoresWithUserData();
    }
  }

  processPreadvice = async () => {
    const {
      match: {
        params: { company },
      },
      whitelabelProxyStore: { orderWasSubmitted },
      history: { push },
      orderStore: { asyncStatus: orderStoreAsyncStatus },
      detailsPageStore: {
        formFields: { returnReason },
      },
    } = this.props;

    if (this.state.labelLoadingStatus !== AsyncStatus.LOADING) {
      this.setState({
        labelLoadingStatus: AsyncStatus.LOADING,
      });
    }

    if (orderWasSubmitted) {
      return;
    }

    try {
      /** Need fetch this order data before going, to check which flow is running. */
      if (orderStoreAsyncStatus === AsyncStatus.IDLE) {
        const productJourney = commonStoresActions.getProductJourneyType();
        try {
          /** it is implicit - need to force user to select products to return. Old one should be used already. */
          if (productJourney.INTEGRATED || productJourney.NOT_SUPPORTED) {
            await commonStoresActions.getOrderById(company);
            return push(`/${company}/details`);
          }
        } catch (e) {
          // if error - just means non-integrated flow, store handle it
        }
      }
      await commonStoresActions.createShipment(company);
      /** If user already pressed Download button - need to open new tab */
      if (this.state.isUserAwaitingNewTab) {
        this.openNewTab();
      }

      this.setState({
        isUserAwaitingNewTab: false,
        labelLoadingStatus: AsyncStatus.SUCCESS,
      });
      this.logFieldEvent('confirmation success page loads', {
        'return reason': returnReason,
      });
    } catch (e) {
      this.setState({
        labelLoadingStatus: AsyncStatus.FAILED,
        isUserAwaitingNewTab: false,
      });
      console.error('processPreadvice::', e);
      return Promise.reject(e);
    }
  };

  searchStores = async () => {
    try {
      await commonStoresActions.searchFromLastLocation();
    } catch (error) {
      console.error('search stores error:', error);
    }
  };

  openNewTab = () => {
    if (isIE) {
      const element = document.createElement('a');
      element.setAttribute('href', this.props.whitelabelProxyStore.pdfLabelURL);
      element.setAttribute('target', '_blank');
      element.click();
    } else {
      window.open(this.props.whitelabelProxyStore.pdfLabelURL, '_blank');
    }
  };

  handlePDFDownloading = () => {
    const { labelLoadingStatus } = this.state;
    this.logFieldEvent('click on download & print label');

    switch (labelLoadingStatus) {
      case AsyncStatus.SUCCESS:
        /** label have been loaded, opening new tab with it */
        return this.openNewTab();
      case AsyncStatus.FAILED:
        /** Prev.attempt to load failed, need to retry it and disable button. */
        return this.setState({ isUserAwaitingNewTab: true }, () => {
          this.processPreadvice();
        });
      default:
        /** loading in process */
        return this.setState({ isUserAwaitingNewTab: true });
    }
  };

  logFieldEvent = (eventName, extraPayload?: any) => {
    const { href: url } = window.location;
    const { company: retailerName } = this.props.match.params;
    amplitude.logEvent(eventName, {
      url,
      retailerName,
      ...extraPayload,
    });
  };

  handleStoreClick = (store: IStore) => {
    this.setState({
      geo: {
        lat: store.geo.lat,
        lng: store.geo.lon,
      },
    });
    commonStoresActions.setActiveStoreId(store.storeId);
  };

  renderSuccessButton = () => {
    const { isUserAwaitingNewTab } = this.state;

    return (
      <DownloadButton
        onClick={this.handlePDFDownloading}
        disabled={isUserAwaitingNewTab}
        integratedFlow={this.intergratedFlowView}
      >
        {this.intergratedFlowView
          ? 'Download and print your label'
          : 'Download & print label'}
      </DownloadButton>
    );
  };

  renderRetryButton = () => (
    <DownloadButton
      onClick={this.processPreadvice}
      integratedFlow={this.intergratedFlowView}
    >
      Retry
    </DownloadButton>
  );

  renderLoaderBlock = text => (
    <LoaderBlock>
      <LoaderArc displayText={text} />
    </LoaderBlock>
  );

  handleToggleInfoPanelVisibility = (visibility: boolean) =>
    this.props.locationStore.setInfoPanelVisibility(visibility);

  renderInfoPanel = () => {
    const {
      locationStore: { showInfoPanel },
      whiteLabeled: { infoPanelText },
    } = this.props;
    return (
      showInfoPanel && (
        <InfoPanel onClick={this.handleToggleInfoPanelVisibility}>
          {infoPanelText}
        </InfoPanel>
      )
    );
  };

  render() {
    const { labelLoadingStatus, geo } = this.state;
    const {
      whitelabelProxyStore: { orderWasSubmitted, consumerPaymentWasSuccessful },
    } = this.props;
    return (
      <Wrapper
        withMargin={labelLoadingStatus !== AsyncStatus.LOADING}
        integrated={this.intergratedFlowView}
      >
        {this.renderInfoPanel()}
        {this.intergratedFlowView ? (
          <IntegratedFlowUI
            labelLoading={labelLoadingStatus}
            geo={geo}
            successButton={this.renderSuccessButton}
            retryButton={this.renderRetryButton}
            loaderText={this.renderLoaderBlock}
            onStoreClick={this.handleStoreClick}
            onMapInit={this.searchStores}
            orderCreateSuccess={orderWasSubmitted}
          />
        ) : (
          <NonIntegratedFlowUI
            labelLoading={labelLoadingStatus}
            geo={geo}
            successButton={this.renderSuccessButton}
            retryButton={this.renderRetryButton}
            onStoreClick={this.handleStoreClick}
            loaderText={this.renderLoaderBlock}
            onMapInit={this.searchStores}
            orderCreateSuccess={orderWasSubmitted}
            consumerPaymentWasSuccessful={consumerPaymentWasSuccessful}
          />
        )}
      </Wrapper>
    );
  }
}

export default compose(
  inject(
    'locationStore',
    'directoryStore',
    'whitelabelProxyStore',
    'orderStore',
    'detailsPageStore'
  ),
  withWhitelabelProps({
    infoPanelText: 'ui.pages.success.textValues.infoPanelText',
  }),
  Form.create<Props>({
    mapPropsToFields(props) {
      const { locationStore } = props;
      return {
        locationSearch: Form.createFormField({
          value: locationStore.formFields.locationSearch,
        }),
      };
    },
    onValuesChange(props, fields) {
      const keys = Object.keys(fields);
      if (!keys.length) {
        return;
      }

      const { href: url } = window.location;
      const { company: retailerName } = props.match.params;

      keys.forEach(fieldName => {
        amplitude.logEvent('entering info into search field', {
          url,
          locationInput: fields[fieldName].value,
          retailerName,
        });
      });

      // @ts-ignore
      const { name, value } = fields[keys[0]];

      props.locationStore.setFormField(name, value);
    },
  })
)(SuccessPage);

import {
  action,
  observable,
  runInAction,
  ObservableMap,
  ObservableSet,
} from 'mobx';
import locationService from '_common/services/locationService';
import {
  getAllLocationsMapper,
  OPTION_ALL_LOCATIONS,
  LOCATIONS_MAPPER,
  OPTION_EXTENDED_HOURS,
} from 'pages/success/utils/locationUtils';
import { requestQueue } from '_common/utils';
import { AXIOS_CANCELLED } from '_common/constants/apiErrorResponces';
import {
  WHITELABEL_UI,
  WhiteLabelUtils,
  WhiteLabelConstants,
} from '_common/whitelabelConfig';
import { ILocationStore } from 'types/mobxStores';
import { LatLng, IStore, ICompanyAsset } from 'types/store';
import { assetsClient } from '_common/api/clients/clients';
import apiEndpoints from '_common/api/endpoints';
import { GOOGLE_API_KEY } from '_common/constants/common';

const ADDRESS_NOT_FOUND = 'address not found';

class LocationStore implements ILocationStore {
  @observable
  clientGeoCoordinates = { ...WHITELABEL_UI.common.defaultMapCenter };

  @observable
  lastSearch: LatLng | null = null;

  @observable
  possibleSearch: LatLng | null = null;

  @observable
  formFields = {
    locationSearch: null,
  };

  @observable
  stores: IStore[] = [];

  @observable
  activeStoreId: string | null = null;

  @observable
  locationType: string = OPTION_ALL_LOCATIONS.value;

  @observable
  isLoading: boolean = false;

  @observable
  isMapShouldBeVisible: boolean = false;

  @observable
  showInfoPanel: boolean = false;

  @observable
  isNewLocationSearch: boolean = true;

  @observable
  mapDragActive: boolean = true;

  @observable
  assetsHub: ObservableMap<string, ICompanyAsset> = observable.map();

  @observable
  companiesWithNoAssets: ObservableSet<string> = observable.set();

  @action
  resetStore = () => {
    this.clientGeoCoordinates = { ...WHITELABEL_UI.common.defaultMapCenter };
    this.lastSearch = null;
    this.possibleSearch = null;
    this.formFields = {
      locationSearch: null,
    };
    this.stores = [];
    this.activeStoreId = null;
    this.locationType = OPTION_ALL_LOCATIONS.value;
    this.isLoading = false;
    this.isMapShouldBeVisible = false;
  };

  @action
  setFormField = (field: string, value: string) => {
    this.formFields[field] = value;
  };

  getDistanceToLastSearch({ distance, unit }) {
    if (this.lastSearch) {
      return `${distance} ${unit}`;
    }
    return null;
  }

  unifyStoresFormat(stores: any): IStore[] {
    if (!stores.length) return [];
    const isNewFormat = !!stores[0].locationInfo && !!stores[0].store;
    if (isNewFormat) {
      return stores.map(({ locationInfo, store }) => ({
        ...store,
        locationInfo,
      }));
    }
    return stores;
  }

  reorderStoresByCPO(stores, cpoAmount = 2) {
    let cpoCount = 0;
    for (let i = 0; i < stores.length; i++) {
      if (cpoCount === cpoAmount) return;
      if (stores[i].locationType === 'POSTOFFICE') {
        stores.splice(cpoCount, 0, stores[i]);
        stores.splice(i + 1, 1);
        cpoCount++;
      }
    }
  }

  /**
   * Extract unique companies from the list of stores provided.
   * @param stores - list of stores
   * @returns list of companyIds
   */
  getUniqueCompanies(stores: IStore[]): string[] {
    return [
      ...new Set(
        stores
          .map(
            ({ companyId }): string =>
              // ignore companies with no assets or with fetched assets
              !this.companiesWithNoAssets.has(companyId) &&
              !this.assetsHub.has(companyId) &&
              companyId
          )
          .filter(Boolean)
      ),
    ];
  }

  /**
   * Fetches company's logo.
   * @async
   * @param companyId - company Id
   * @param logoUrl - logo image url
   */
  @action
  fetchLogoForCompany = async (company: string, logoUrl: string) => {
    try {
      const storedAssets = this.assetsHub.get(company);
      const logoResult = await assetsClient.get(logoUrl);

      runInAction(() => {
        this.assetsHub.set(company, {
          ...storedAssets,
          logo: logoResult.data,
        });
      });
    } catch (error) {
      console.info(`Error getting logo for company ${company}:`, error);
    }
  };

  /**
   * Fetches company application-config.json file for given stores.
   * Makes requests for company's logos
   * @async
   * @param stores - List of stores
   */
  @action
  getAssetsForStores = async (stores: IStore[]) => {
    const uniqueCompanyIds = this.getUniqueCompanies(stores);

    await Promise.all(
      uniqueCompanyIds.map(company =>
        assetsClient
          .get(
            `/${WhiteLabelConstants.PRODUCT_NAME}/${company}/application-config.json`
          )
          .then(({ data }) => {
            const { logo, pin, activePin } = data.assets;
            // don't wait for logo, show pin right away
            if (pin) {
              runInAction(() => {
                this.assetsHub.set(company, {
                  logo: '',
                  pins: {
                    defaultPin: `${apiEndpoints.ASSETS_URL}/${pin}`,
                    activePin: `${apiEndpoints.ASSETS_URL}/${activePin || pin}`, // fallback if no greyscale pin provided
                  },
                });
              });
            }
            if (!pin && !logo) {
              runInAction(() => {
                this.companiesWithNoAssets.add(company);
              });
            }
            logo && this.fetchLogoForCompany(company, logo);
          })
          .catch(error => {
            // if there's no assets for a company - don't try to fetch again on following requests
            if (error.response?.status === 404) {
              runInAction(() => {
                this.companiesWithNoAssets.add(company);
              });
            }
          })
      )
    );
  };

  @action
  async searchStoresByCoords(
    coordinates: LatLng & { company?: string },
    onDrag?: boolean,
    distance?: number
  ) {
    /**
     * when searching on map drag - do not reset stores list, only update it
     * and do not change client location
     */
    if (!onDrag) {
      this.stores = [];
      this.clientGeoCoordinates = coordinates;
    }

    this.isLoading = true;
    this.lastSearch = coordinates;

    this.setActiveStoreId(null);

    const locationTypesMapper = getAllLocationsMapper();

    const channelConfig = {
      ...coordinates,
      locationTypes:
        this.locationType === 'STREET_POST_BOXES'
          ? `${locationTypesMapper[this.locationType]},${
              LOCATIONS_MAPPER.EXPRESS_POSTBOX
            }`
          : locationTypesMapper[this.locationType],
      distance,
      extendedHours: this.locationType === OPTION_EXTENDED_HOURS.value,
    };

    const onError = e => {
      if (e === AXIOS_CANCELLED) {
        return e;
      }
      runInAction(() => (this.isLoading = false));
      console.error(e);
      return [];
    };
    const { cancelToken } = requestQueue.enqueueNewRequest([
      'locationStore',
      'searchStoresByCoords',
    ]);

    let stores: IStore[] = await locationService
      .getGeoQueryStores(channelConfig, cancelToken)
      .catch(onError);

    // @ts-ignore
    if (stores === AXIOS_CANCELLED) return; // TODO: ??

    stores = this.unifyStoresFormat(stores);

    if (stores && stores.length) {
      WhiteLabelUtils.assetsForStoresEnabled && this.getAssetsForStores(stores);
      this.reorderStoresByCPO(stores, 2);
    }

    runInAction(() => {
      this.mapDragActive = onDrag;
      this.stores = stores as IStore[];
      this.isLoading = false;
    });
  }

  isBrowserSupportGeolocation(): boolean {
    return !!navigator.geolocation;
  }

  getUserGeoposition(): Promise<LatLng> {
    return new Promise((resolve, reject) => {
      if (!this.isBrowserSupportGeolocation()) {
        reject(new Error('Browser does not support geolocation'));
      }

      const onSuccess = position => {
        const coords = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        return resolve(coords);
      };

      const onError = error => {
        runInAction(() => {
          /** show info panel */
          this.setInfoPanelVisibility(true);
        });
        return reject(error);
      };

      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    });
  }

  @action
  searchStoresNearMe = async (company: string): Promise<string> => {
    this.isLoading = true;

    const onError = e => {
      console.error(e);
      // eslint-disable-next-line react/no-this-in-sfc
      runInAction(() => (this.isLoading = false));
      return null;
    };

    try {
      const userGeolocation: LatLng = await this.getUserGeoposition();
      let result = ADDRESS_NOT_FOUND;

      if (!userGeolocation) return result;

      const res = await locationService
        .getReverseGeocode({
          ...userGeolocation,
          key: GOOGLE_API_KEY,
        })
        .catch(onError);

      if (res && res.results && res.results.length) {
        result = res.results[0].formatted_address;
      }

      await this.searchStoresByCoords({ ...userGeolocation, company }).catch(
        onError
      );

      runInAction(() => {
        this.isLoading = false;
      });
      return result;
    } catch (e) {
      runInAction(() => (this.isLoading = false));
      return null;
    }
  };

  @action
  searchFromLastLocation = async (isNewLocation = false) => {
    if (this.possibleSearch) {
      if (isNewLocation) {
        this.setIsNewLocation(true);
      }
      this.checkCoordsForNewLocation(this.possibleSearch);
      this.lastSearch = this.possibleSearch;
      if (!this.mapDragActive && this.isNewLocationSearch) {
        this.possibleSearch = null;
      }
    } else this.setIsNewLocation(false);

    if (this.lastSearch) {
      this.isMapShouldBeVisible = true;
      this.searchStoresByCoords(this.lastSearch);
    }
  };

  @action
  setSearchGeoCoordinates = (coords: LatLng) => {
    this.isMapShouldBeVisible = true;
    this.possibleSearch = coords;
  };

  @action
  setActiveStoreId = (storeId?: string) => {
    this.activeStoreId = storeId;
  };

  @action
  setLocationType = (locationType: string) => {
    this.locationType = locationType;
  };

  @action
  hideMapForInitialState = () => {
    this.isMapShouldBeVisible = false;
  };

  @action
  setInfoPanelVisibility = (showInfoPanel: boolean = false) => {
    this.showInfoPanel = showInfoPanel;
  };

  @action
  checkCoordsForNewLocation = coords => {
    if (!this.lastSearch) return;
    const newLat = coords.lat;
    const newLng = coords.lng;
    const { lat, lng } = this.lastSearch;
    this.setIsNewLocation(newLat !== lat || newLng !== lng);
  };

  @action
  setIsNewLocation = (val: boolean) => {
    this.isNewLocationSearch = val;
  };
}

export default LocationStore;

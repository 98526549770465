import React from 'react';
import { inject, observer } from 'mobx-react';

import LocationStore from 'pages/success/stores/locationStore';
import {
  LocationAddress,
  LocationDistance,
  LocationDistanceInfo,
  LocationInfo,
  LocationItem,
  LocationOhTableBody,
  LocationOhTableData,
  LocationOhTableRow,
  LocationOpeningHours,
  LocationOpeningHoursTitle,
  LocationSeeMoreBtn,
  LocationSubInfo,
  LocationTitle,
  ToolTipSmaller,
  TooltipText,
  ToolTipWrapper,
  LocationIconContainer,
} from './StoreItemElements';

import { DAYS } from '_common/constants/date';
import { get } from 'lodash';
import { printerDarkIcon, printerRedIcon } from 'assets';
import amplitude from '_common/utils/amplitude';
import {
  LOCATIONS_MAPPER,
  normalizeStoreName,
} from '../../utils/locationUtils';
import { IStore } from 'types/store';
import { WhiteLabelUtils, WHITELABEL_UI } from '_common/whitelabelConfig';

type Props = {
  isActive: boolean;
  store: IStore;
  locationStore?: LocationStore;
  onClick?: (store: IStore) => void;
  customerLogo?: string;
};

type State = {
  seeMoreBtn: boolean;
};

@observer
class StoreItem extends React.Component<Props, State> {
  rootRef = React.createRef<HTMLElement>();

  state = {
    seeMoreBtn: false,
  };

  componentDidUpdate(props: Props) {
    if (this.props.isActive && !props.isActive && this.rootRef.current) {
      this.rootRef.current.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  }

  onClick = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props.store);
    }
  };

  getFormattedTime = timeString =>
    `${timeString.substring(0, 2)}:${timeString.substring(2, 4)}`;

  getWorkingTimeText = ({ hours, isOpen }) => {
    if (!isOpen) {
      return 'Closed';
    }

    return hours
      .reduce((accumulated, [openTime, closeTime]) => {
        const timeRange = `${this.getFormattedTime(
          openTime
        )} - ${this.getFormattedTime(closeTime)}`;
        accumulated.push(timeRange);
        return accumulated;
      }, [])
      .join(' & ');
  };

  renderMoreStoreDetails = openingHours => (
    <>
      <LocationOpeningHoursTitle>Opening hours</LocationOpeningHoursTitle>
      <table>
        <LocationOhTableBody>
          {DAYS.map(({ fullName, shortName }) => (
            <LocationOhTableRow key={fullName}>
              <LocationOhTableData>{shortName}</LocationOhTableData>
              <LocationOhTableData>
                {this.getWorkingTimeText(openingHours[fullName])}
              </LocationOhTableData>
            </LocationOhTableRow>
          ))}
        </LocationOhTableBody>
      </table>
    </>
  );

  seeMoreClickHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    const {
      store: { storeName, storeId },
    } = this.props;
    amplitude.logEventWithOrganisationAndUrl('clicked see more for store', {
      storeName,
      storeId,
    });

    this.setState(prevState => ({
      seeMoreBtn: !prevState.seeMoreBtn,
    }));
  };

  renderTooltipTitle = () => (
    <>
      <img src={printerRedIcon} alt="Printer logo" />
      <TooltipText>Paperless returns available</TooltipText>
    </>
  );

  get acceptsPaperless() {
    const {
      store: { services, locationType },
    } = this.props;
    const thirdPartyStoreType = LOCATIONS_MAPPER.OTHER_RETAILERS;
    return locationType === thirdPartyStoreType
      ? true
      : get(services, 'RETURNS.acceptsPaperless', false);
  }

  get normalizedStoreName() {
    const {
      store: { storeName, locationType },
    } = this.props;
    return normalizeStoreName(storeName, locationType);
  }

  render() {
    const {
      isActive,
      store: {
        locationInfo,
        place: { address },
        openingHours,
      },
      customerLogo,
    } = this.props;
    const distance = this.props.locationStore.getDistanceToLastSearch(
      locationInfo
    );

    return (
      <LocationItem
        isActive={isActive}
        ref={this.rootRef}
        onClick={this.onClick}
      >
        {WhiteLabelUtils.assetsForStoresEnabled && (
          <LocationIconContainer>
            {customerLogo && (
              <div dangerouslySetInnerHTML={{ __html: customerLogo }} />
            )}
          </LocationIconContainer>
        )}

        <LocationInfo>
          <LocationTitle>{this.normalizedStoreName}</LocationTitle>
          <LocationAddress>
            {address.line1}, {address.line2 ? `${address.line2}, ` : null}
            {address.town}, {address.area}, {address.postcode}
          </LocationAddress>
        </LocationInfo>

        <LocationSubInfo>
          <LocationDistanceInfo>
            <ToolTipWrapper>
              {this.acceptsPaperless && (
                <ToolTipSmaller
                  placement="right"
                  title={this.renderTooltipTitle}
                >
                  <img src={printerDarkIcon} alt="Printer logo" />
                </ToolTipSmaller>
              )}
            </ToolTipWrapper>
            <LocationDistance>{distance}</LocationDistance>
          </LocationDistanceInfo>
          {openingHours ? (
            <LocationSeeMoreBtn onClick={this.seeMoreClickHandler}>
              {this.state.seeMoreBtn ? 'See less' : 'See more'}
            </LocationSeeMoreBtn>
          ) : null}
        </LocationSubInfo>
        {this.state.seeMoreBtn && openingHours ? (
          <LocationOpeningHours
            paddingLeft={WHITELABEL_UI.pages.success.storeLogosEnabled}
          >
            {this.renderMoreStoreDetails(openingHours)}
          </LocationOpeningHours>
        ) : null}
      </LocationItem>
    );
  }
}

export default inject('locationStore')(StoreItem);

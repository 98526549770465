import { IMobXStores } from 'types/mobxStores';
import { TSPResult } from '_common/components/AustraliaPost/SecureFrame/SecureFrame';
import { Theme } from 'themes/defaultTheme';
import { IReturnFormFields } from 'types/company';

export enum EAsyncStatus {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  IDLE = 'IDLE',
}

export interface IOption {
  value: string;
  label: string;
}

export interface IProductJourneyType {
  NOT_SUPPORTED: boolean;
  INTEGRATED: boolean;
  NON_INTEGRATED: boolean;
}

export interface ICommonStoresActions {
  stores: IMobXStores | null;
  registerStores: (stores: IMobXStores) => void;
  loadTheme: () => Promise<Theme>;
  logout: () => void;
  refreshToken: () => Promise<void>;
  getCompany: (companyName: string) => Promise<void>;
  createShipment: (company: string) => Promise<void>;
  getTrackingInfo: (trackingId: string) => Promise<void>;
  saveToStorage: (storeName: string, model: object) => void;
  validateAddress: (address: any) => Promise<void>;
  validateSession: (
    merchantId: string,
    resetAfterOrdersSubmit?: boolean
  ) => void;
  getLocationTypes: () => IOption[];
  getAccountNumberForHeader: () => string;
  resetStoresWithUserData: () => void;
  setConsumerPaymentWasSuccessful: (paymentDetails: TSPResult) => void;
  setDetailsFormField: (field: any, value: any) => void;
  saveDetailsFormToStorage: () => void;
  getOrderById: (company: string) => Promise<void>;
  getJourneyType: () => string;
  isPaymentsRequiredForIntegrated: () => boolean;
  getProductJourneyType: () => IProductJourneyType;
  getPurchaseWarrantyPeriod: () => number;
  getReturnFormFields: () => IReturnFormFields;
  searchStoresByCoords: (
    config: { lat: number; lng: number; company?: string },
    onDrag?,
    distance?
  ) => Promise<void>;
  searchStoresNearMe: (company: string) => Promise<string>;
  setActiveStoreId: (storeId: string) => void;
  setLocationType: (locationType: string) => void;
  searchFromLastLocation: (isNewLocation?: boolean) => void;
  setSearchGeoCoordinates: (coords: any) => void;
}

export interface IRouterMatch {
  company: string;
  location?: string;
}

import AuthStore from '_common/stores/authStore';
import DirectoryStore from '_common/stores/directoryStore';
import ThemeStore from '_common/stores/themeStore';
import OrderStore from '_common/stores/orderStore';
import PaymentPageStore from 'pages/payment/stores/paymentPageStore';
import StartPageStore from 'pages/start/stores/startPageStore';
import LocationStore from 'pages/success/stores/locationStore';
import TrackingStore from 'pages/tracking/stores/trackingStore';
import { apiClient } from '_common/api/clients/clients';
import commonStoresActions from '_common/actions';
import { WHITELABEL_STORES, initServices } from '_common/whitelabelConfig';
import amplitude from '_common/utils/amplitude';
initServices({ apiClient });

const { DetailsPageStore, WhitelabelProxyStore } = WHITELABEL_STORES;

export {
  AuthStore,
  StartPageStore,
  DetailsPageStore,
  PaymentPageStore,
  ThemeStore,
  DirectoryStore,
  LocationStore,
  WhitelabelProxyStore,
  TrackingStore,
  OrderStore,
};

const authStore = new AuthStore();
const themeStore = new ThemeStore();
const startPageStore = new StartPageStore();
const detailsPageStore = new DetailsPageStore();
const paymentPageStore = new PaymentPageStore();
const directoryStore = new DirectoryStore();
const locationStore = new LocationStore();
const whitelabelProxyStore = new WhitelabelProxyStore();
const trackingStore = new TrackingStore();
const orderStore = new OrderStore();

const storesInstances = {
  authStore,
  startPageStore,
  detailsPageStore,
  paymentPageStore,
  themeStore,
  directoryStore,
  locationStore,
  whitelabelProxyStore,
  trackingStore,
  orderStore,
};

/** THIS is now our entry point for all stores actions. */
commonStoresActions.registerStores(storesInstances);

/** To avoid Circle dependencies - injecting actions after all stores were initialised */
Object.values(storesInstances).forEach(store => {
  if (store.registerCommonActions) {
    store.registerCommonActions(commonStoresActions);
  }
});

amplitude.registerCommonActions(commonStoresActions);

export default storesInstances;

import React from 'react';
import { Progress } from 'antd';
import { ProgressWrapper } from './elements';

interface Props {
  percent: number;
  strokeWidth: number;
  showInfo: boolean;
}

function Progressbar(props: Props) {
  return (
    <ProgressWrapper>
      <Progress {...props} />
    </ProgressWrapper>
  );
}

export default Progressbar;

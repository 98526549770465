import {
  LoaderDots,
  LoaderText,
  LoaderWrapper,
  PageWrapper,
} from '_common/components/PageStates/element';
import React from 'react';

const PageLoader = () => (
  <PageWrapper>
    <LoaderWrapper>
      <LoaderText>Loading</LoaderText>
      <LoaderDots>
        <div />
        <div />
        <div />
        <div />
      </LoaderDots>
    </LoaderWrapper>
  </PageWrapper>
);

export default PageLoader;

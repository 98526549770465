import styled from 'styled-components';

export const RightSectionWrapper = styled.div`
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
`;

export const RightSectionImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${({ theme }) => theme.assets.backgroundImage});
  background-position: top right;
  background-size: contain;
  background-repeat: no-repeat;
`;

import React, { Component } from 'react';
import {
  ReturnItemWrapper,
  Image,
  DetailsBlock,
  Description,
  DetailsRow,
  ButtonsBlock,
  ReturnButton,
  ItemContent,
  SelectedReturnButton,
  ReturnItemError,
} from './elements';
import { observer, inject } from 'mobx-react';
import ReturnReasons from '../ReturnReasons';
import { compose } from 'recompose';
import { computed } from 'mobx';
import { withWhitelabelProps } from '_common/whitelabelConfig';
import { TextArea } from '_common/components';
import { Heading } from '../ReturnReasons/elements';
import { IOrderProductModel } from 'types/order';
import { IReason } from 'types/company';
import { IOrderStore, IDetailsStore, IDirectoryStore } from 'types/mobxStores';
import { ORDER_ERRORS } from '_common/constants/orders';
import { truncate, validateOnSpecialCharacters } from '_common/utils';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

type Props = FormComponentProps & {
  form: Form;
  product: IOrderProductModel;
  directoryStore: IDirectoryStore;
  detailsPageStore: IDetailsStore;
  whiteLabeled: any;
  orderStore: IOrderStore;
  onInputClick: (val: string) => () => void;
  onInputBlur: (val: string) => () => void;
};

@observer
class ReturnItem extends Component<Props> {
  @computed
  get approvedReturnReasonDescription() {
    const {
      detailsPageStore: { selectedReturnReasonsForProduct },
      product: { productId },
    } = this.props;
    if (!selectedReturnReasonsForProduct.has(productId)) {
      return null;
    }
    return selectedReturnReasonsForProduct.get(productId).description;
  }

  /** Confirmed reason code */
  handleConfirmedReasonSelect = (selectedReason: IReason) => {
    const {
      detailsPageStore: {
        setSelectedReturnReasonsForProduct,
        setReturningProductId,
      },
      product,
    } = this.props;
    setSelectedReturnReasonsForProduct(product, selectedReason);
    setReturningProductId(null);
  };

  /** Open reasons list */
  handleSetSelectingReasonCode = () => {
    const {
      detailsPageStore: {
        setReturningProductId,
        setSelectedReturnReasonsForProduct,
      },
      directoryStore: { activeReturnReasons },
      product,
      whiteLabeled: { noActiveReasonsAllowed },
    } = this.props;
    // if no active reasons for merchant - select product with dummy reason - it will be skipped later
    if (!activeReturnReasons.length && noActiveReasonsAllowed) {
      setSelectedReturnReasonsForProduct(product, {
        enabled: false,
        paymentRequired: false,
        IS_EMPTY_RETURN_REASON: true,
        description: 'Selected',
      });
    } else {
      setReturningProductId(product.productId);
    }
  };

  removeSelectedReason = () => {
    const {
      detailsPageStore: { deleteSelectedReturnReasonsForProduct },
      product: { productId },
    } = this.props;
    deleteSelectedReturnReasonsForProduct(productId);
  };

  handleCommentsInput = e => {
    const {
      product: { productId },
      orderStore: { updateIntegratedComment },
    } = this.props;
    updateIntegratedComment(e.target.value, productId);
  };

  renderActionButton = () =>
    this.approvedReturnReasonDescription ? (
      <SelectedReturnButton
        onClick={this.removeSelectedReason}
        title={this.approvedReturnReasonDescription}
      >
        {truncate(String(this.approvedReturnReasonDescription), 20)}
      </SelectedReturnButton>
    ) : (
      <ReturnButton
        onClick={this.handleSetSelectingReasonCode}
        disabled={!!this.validateProduct()}
      >
        Return
      </ReturnButton>
    );

  validateProduct = () => {
    const {
      product: { isNotReturnable },
      orderStore: { getReturnedStatusForProduct },
    } = this.props;

    return isNotReturnable
      ? ORDER_ERRORS.NON_RETURNABLE_PRODUCT_ERROR
      : getReturnedStatusForProduct(this.props.product);
  };

  render() {
    const {
      form: { getFieldDecorator },
      product: { imageUrl, description, price, size, productId, priceCurrency },
      directoryStore: { activeReturnReasons, returnFormFields },
      detailsPageStore: { returningProductId, formFields },
      whiteLabeled: {
        noReasonsText,
        integratedCommentsEnabled,
        whitelabeledRules,
      },
      onInputClick,
      onInputBlur,
    } = this.props;
    const showComments =
      integratedCommentsEnabled && returnFormFields.reasonComments
        ? returningProductId === productId ||
          !!this.approvedReturnReasonDescription
        : false;
    const productError = this.validateProduct();
    return (
      <ReturnItemWrapper>
        <ItemContent>
          <Image imageUrl={imageUrl} />
          <DetailsBlock>
            <Description>{description}</Description>
            <DetailsRow>
              <div>Size {size}</div>
              <div>
                {priceCurrency}
                {price}
              </div>
            </DetailsRow>
            {productError && <ReturnItemError>{productError}</ReturnItemError>}
          </DetailsBlock>
          <ButtonsBlock>{this.renderActionButton()}</ButtonsBlock>
        </ItemContent>
        {returningProductId === productId && (
          <ReturnReasons
            returnReasons={activeReturnReasons}
            noReasonsText={noReasonsText}
            onSelect={this.handleConfirmedReasonSelect}
            withBottomMargin={integratedCommentsEnabled}
          />
        )}
        {showComments && (
          <Form.Item>
            <Heading>
              {returnFormFields.reasonCommentsFieldName || 'Reason Comments'}
              {returnFormFields.reasonCommentsMandatory && '*'}
            </Heading>
            {/* Ensures that the ID of the text area is unique for each product */}
            {getFieldDecorator(`reasonComments-${productId}`, {
              initialValue: formFields.reasonComments,
              rules: [
                {
                  required: returnFormFields.reasonCommentsMandatory,
                  message: whitelabeledRules.reasonCommentsRequiredText,
                },
                {
                  validator: (
                    rule: any,
                    value: string,
                    callback: (error?: Error) => void
                  ) => {
                    validateOnSpecialCharacters(rule, value, callback);
                  },
                },
              ],
            })(
              <TextArea
                rows={2}
                onChange={this.handleCommentsInput}
                onFocus={onInputClick('reasonComments')}
                onBlur={onInputBlur('reasonComments')}
              />
            )}
          </Form.Item>
        )}
      </ReturnItemWrapper>
    );
  }
}

export default compose(
  inject('detailsPageStore', 'directoryStore', 'orderStore'),
  withWhitelabelProps({
    noReasonsText: 'ui.pages.details.noReasonsText',
    noActiveReasonsAllowed: 'ui.pages.details.noActiveReasonsAllowed',
    integratedCommentsEnabled:
      'ui.pages.details.integratedReasonCommentsEnabled',
    whitelabeledRules: 'ui.pages.details.rules',
  })
)(ReturnItem);

export const DAYS = [
  { fullName: 'monday', shortName: 'Mon' },
  { fullName: 'tuesday', shortName: 'Tue' },
  { fullName: 'wednesday', shortName: 'Wed' },
  { fullName: 'thursday', shortName: 'Thu' },
  { fullName: 'friday', shortName: 'Fri' },
  { fullName: 'saturday', shortName: 'Sat' },
  { fullName: 'sunday', shortName: 'Sun' },
];

export const PURCHASE_DATE_ERRORS = {
  FULL_MESSAGE:
    'The purchase date selected is outside our return policy. Please contact customer services',
  SHORT_MESSAGE: 'Date is outside our return policy',
};

export const ALLOWED_DATEPICKER_FORMATS = [
  'DD-MM-YYYY',
  'DD/MM/YYYY',
  'DD.MM.YYYY',
  'DD,MM,YYYY',
];

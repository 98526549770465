import styled, { css } from 'styled-components';
import { Tooltip } from '_common/components';
import { isIE } from 'react-device-detect';

export const LocationItem = styled.div<{ isActive: boolean; ref: any }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-flow: row wrap;
  ${!isIE && 'flex-wrap: wrap; '};

  padding: 20px;
  box-shadow: 0 -1px 0 0 #e6e7e8, 0 1px 0 0 #e6e7e8;

  ${({ isActive }) =>
    isActive &&
    css`
      border-left: 8px solid #4a90e2;
      padding-left: 17px;
    `};

  @media only screen and (max-width: 768px) {
    padding: 20px 10px;
  }
`;

export const LocationInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 70%;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    flex-basis: 67%;
  }
`;

export const LocationIcon = styled.img`
  width: 30px;
  height: 30px;
`;

export const LocationIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${isIE ? 'flex-basis: 8%' : 'flex-basis: 10%'};
`;

export const LocationTitle = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 16px;
  font-weight: bold;
  color: #000000;

  @media only screen and (max-width: 768px) {
    padding-bottom: 5px;
    font-size: 14px;
  }
`;

export const LocationAddress = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 14px;
  font-weight: 300;
  line-height: 1.25;
  color: #000000;

  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

export const LocationSubInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-basis: 20%;
  flex-direction: column;
  justify-items: center;
  padding-left: 5px;
`;

export const LocationDistanceInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  justify-content: space-between;
`;

export const LocationDistance = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 12px;
  font-weight: 300;
  text-align: right;
  color: #000000;
`;

export const LocationOpeningHours = styled.div<{ paddingLeft?: boolean }>`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  font-size: 12px;
  font-weight: normal;
  border-top: 1px solid #e6e7e8;
  margin-top: 20px;
  padding-top: 20px;
  ${({ paddingLeft }) =>
    paddingLeft &&
    'padding-left: 10%;'}/**matches flex-basis 10% of icon wrapper */
`;

export const LocationOpeningHoursTitle = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

export const LocationOhTableBody = styled.tbody`
  border: none;
  padding: 0;
`;

export const LocationOhTableRow = styled.tr`
  border: none;
  padding: 0;
`;

export const LocationOhTableData = styled.td`
  border: none;
  padding: 0;
  text-align: left;
  font-weight: 200;
  font-size: 14px;
  line-height: 1.43;
  font-family: ${props => props.theme.fonts.basic};

  &:first-child {
    width: 80px;
  }
`;

export const LocationSeeMoreBtn = styled.button`
  align-self: flex-end;
  max-width: 80px;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  height: 30px;
  line-height: 30px;
  min-height: 30px;
  cursor: pointer;
  background-color: #ffffff;
  color: #000000;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: #e2e2e2;
  border-image: initial;
  padding: 0;
`;

export const TooltipText = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 16px;
  color: #282d32;
`;

export const ToolTipWrapper = styled.div`
  display: flex;
`;

export const ToolTipSmaller = styled(Tooltip)`
  max-width: 15px;
`;

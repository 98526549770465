import * as React from 'react';
import {
  Tracker,
  Icon,
  TextBlock,
  PrimaryText,
  SecondaryText,
} from '../elements/tracker';
import successStepIcon from 'assets/images/success-step-icon.svg';
import inactiveStepIcon from 'assets/images/inactive-step-icon.svg';
import moment from 'moment';
import { INTERNAL_EVENT_STATES } from '../stores/trackingStore';
import { TrackingEventWithInternalState } from 'types/tracking';

export const StatusTracker = ({
  eventDescription,
  eventDateTime,
  internalEventState,
}: TrackingEventWithInternalState) => {
  const getTimeString = () => {
    if (eventDateTime) {
      return moment.utc(eventDateTime).local().format('h:mm a - DD MMM YYYY');
    }

    return internalEventState === INTERNAL_EVENT_STATES.EVENT_SKIPPED
      ? 'Unavailable'
      : undefined;
  };

  const inactive =
    internalEventState === INTERNAL_EVENT_STATES.EVENT_NOT_OCCURRED;

  return (
    <Tracker inactive={inactive}>
      <Icon>
        <img
          src={inactive ? inactiveStepIcon : successStepIcon}
          alt="success icon"
        />
      </Icon>
      <TextBlock>
        <PrimaryText inactive={inactive}>{eventDescription}</PrimaryText>
        <SecondaryText>{getTimeString()}</SecondaryText>
      </TextBlock>
    </Tracker>
  );
};

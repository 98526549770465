import {
  ErrorWrapper,
  PageWrapper,
} from '_common/components/PageStates/element';
import React from 'react';

export const PageError = () => (
  <PageWrapper>
    <ErrorWrapper>Error occurred while loading</ErrorWrapper>
  </PageWrapper>
);

export default PageError;

/* eslint camelcase: 0 */
/* eslint no-console: 0 */
import amplitude from 'amplitude-js';
import { ICommonStoresActions } from 'types/core';

class Amplitude {
  _amplitude;

  company: string | void;

  commonStoresActions: ICommonStoresActions;

  isProduction = process.env.NODE_ENV === 'production';

  registerCommonActions = (commonStoresActions: ICommonStoresActions) => {
    this.commonStoresActions = commonStoresActions;
  };

  /**
   * Flatting array with error and send to amplitude.
   * @param errors
   * @param pageName
   */
  logValidationErrors = (errors: any, pageName) => {
    this.logEventWithOrganisationAndUrl('Form validation error', {
      page_name: pageName,
      errors: Object.values(errors).reduce(
        (acc: any, { errors: internalErrors }) => [
          ...acc,
          ...Object.values(internalErrors).map(
            ({ message: error_message, field: field_name }) => ({
              field_name,
              error_message,
            })
          ),
        ],
        []
      ),
    });
  };

  logEvent = (eventName, eventPayload) => {
    const payload = {
      journeyType: this.commonStoresActions.getJourneyType(),
      ...eventPayload,
    };

    if (this.isProduction) {
      this._amplitude.logEvent(eventName, payload);
    } else {
      console.log('%c [Event: %s]', 'color: #bada55', eventName, payload);
    }
  };

  logEventWithOrganisationAndUrl = (eventName, eventPayload?) => {
    const payload = {
      url: window.location.href,
      retailerName: this.company,
      journeyType: this.commonStoresActions.getJourneyType(),
      ...eventPayload,
    };
    if (this.isProduction) {
      this._amplitude.logEvent(eventName, payload);
    } else {
      console.log('%c [Event: %s]', 'color: #bada55', eventName, payload);
    }
  };

  changeProp = (method, propKey, propValue) => {
    this._amplitude.identify(
      new this._amplitude.Identify()[method](propKey, propValue)
    );
  };

  initAmplitude = (company: string) => {
    this.company = company;

    if (!window || !this.isProduction) {
      return;
    }

    this._amplitude = amplitude.getInstance();
    this._amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, null, {
      includeReferrer: true,
    });

    this.changeProp('set', 'Referrer', document.referrer);
    this.changeProp('set', 'Refering domain', document.referrer.split('/')[2]);
    this.changeProp('setOnce', 'returns completed', 0);
    this.changeProp('append', 'Retailers returned with', company);
    // To erase: this.changeProp('unset', 'Referrer', '-');
  };
}

export default new Amplitude();

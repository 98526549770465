import trackingService from '_common/services/trackingService';
import { action, observable, ObservableMap, runInAction } from 'mobx';
import moment from 'moment';
import { WHITELABEL_SERVICES, WHITELABEL_UI } from '_common/whitelabelConfig';
import { ITrackingStore } from 'types/mobxStores';
import { TrackingEvent, TrackingEventWithInternalState } from 'types/tracking';

export const INTERNAL_EVENT_STATES = {
  EVENT_OCCURRED: 'EVENT_OCCURRED',
  EVENT_SKIPPED: 'EVENT_SKIPPED',
  EVENT_NOT_OCCURRED: 'EVENT_NOT_OCCURRED',
};

class TrackingStore implements ITrackingStore {
  static NOT_FOUND_MESSAGE = 'Nothing found';

  @observable
  events: ObservableMap<
    string,
    TrackingEventWithInternalState
  > = observable.map();

  @observable
  latestEvent: string = TrackingStore.NOT_FOUND_MESSAGE;

  /**
   * Filters all incoming events by predicates and takes latest by time.
   * @param events - all order events.
   * @returns {*|T|T|number}
   */
  getFilteredEventsMap = events =>
    events.reduce((hashEvents, event: TrackingEvent) => {
      const { eventDateTime, doddleTrackingEventId, customerVisible } = event;
      /** We interested only in specific event types. */
      if (
        !WHITELABEL_SERVICES.trackingEventChecker(doddleTrackingEventId) ||
        !customerVisible
      ) {
        return hashEvents;
      }

      const storedEvent = hashEvents.get(doddleTrackingEventId);
      if (
        !storedEvent ||
        moment(eventDateTime) > moment(storedEvent.eventDateTime)
      ) {
        hashEvents.set(doddleTrackingEventId, event);
      }

      return hashEvents;
    }, new Map());

  /**
   * Sort events by expected order and adds internal helper state.
   * @param filteredEventsMap
   * @returns {*|T|T|number}
   */
  getOrderedEventsWithState = filteredEventsMap => {
    let shouldMarkSkippedEvents = false;
    return WHITELABEL_UI.get('pages.tracking.trackersList')
      .reverse()
      .reduce(
        (eventsInRightOrder, { type: doddleTrackingEventId, description }) => {
          if (filteredEventsMap.has(doddleTrackingEventId)) {
            /** We found the LATEST (logically) event, all skipped previous must be marked as 'skipped' */
            if (!shouldMarkSkippedEvents) {
              shouldMarkSkippedEvents = true;
              this.latestEvent = description;
            }
            eventsInRightOrder.unshift({
              ...filteredEventsMap.get(doddleTrackingEventId),
              eventDescription: description,
              internalEventState: INTERNAL_EVENT_STATES.EVENT_OCCURRED,
            });
          } else {
            /** This event is expected to be, but not occurred yet, or just skipped. */
            eventsInRightOrder.unshift({
              eventDescription: description,
              doddleTrackingEventId,
              internalEventState: shouldMarkSkippedEvents
                ? INTERNAL_EVENT_STATES.EVENT_SKIPPED
                : INTERNAL_EVENT_STATES.EVENT_NOT_OCCURRED,
            });
          }
          return eventsInRightOrder;
        },
        []
      );
  };

  @action
  getTrackingEvents = async trackingId => {
    this.resetStore();

    try {
      const events = await trackingService.getTrackingInfo(trackingId);
      const filteredEvents = this.getFilteredEventsMap(events);

      if (!filteredEvents.size) {
        return;
      }

      runInAction(() => {
        this.getOrderedEventsWithState(filteredEvents).forEach(event =>
          this.events.set(event.doddleTrackingEventId, event)
        );
      });
    } catch (e) {
      console.error('error::', e);
    }
  };

  @action
  resetStore = () => {
    this.latestEvent = TrackingStore.NOT_FOUND_MESSAGE;
    this.events.clear();
  };
}

export default TrackingStore;

import React from 'react';
import { AsyncStatus } from '_common/constants/common';

interface Props {
  dependenciesList: Array<Function>;
  loadingComponent: JSX.Element;
  errorComponent: JSX.Element;
  children: React.ReactNode;
}

type State = {
  status: string;
};

class DependenciesLoader extends React.Component<Props, State> {
  state = {
    status: AsyncStatus.IDLE,
  };

  async componentDidMount() {
    this.setState({ status: AsyncStatus.LOADING });
    try {
      await Promise.all(this.props.dependenciesList.map(fn => fn()));
      this.setState({ status: AsyncStatus.SUCCESS });
    } catch (e) {
      this.setState({ status: AsyncStatus.FAILED });
    }
  }

  render() {
    const { status } = this.state;
    const { children, errorComponent, loadingComponent } = this.props;

    switch (status) {
      case AsyncStatus.SUCCESS:
        return children;
      case AsyncStatus.LOADING:
        return loadingComponent;
      case AsyncStatus.FAILED:
        return errorComponent;
      default:
        return null;
    }
  }
}

export default DependenciesLoader;

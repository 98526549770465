import React from 'react';
import { GoogleApiWrapper, InfoWindow, Map } from 'google-maps-react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { reaction } from 'mobx';

import { geoIcon } from 'assets';
import commonStoresActions from '_common/actions';
import StoreList from '../StoreList/StoreList';
import Marker from '../Marker/Marker';
import amplitude from '_common/utils/amplitude';
import {
  ClearButton,
  GeoIcon,
  InfoAddress,
  InfoDistance,
  InfoHeader,
  InfoTitle,
  Input,
  InputWrapper,
  Root,
  Select,
  StoreListWrapper,
  TabContent,
  TabHeader,
  Tabs,
  TabWrapper,
} from './MobileMapElements';
import { GOOGLE_MAP_DEFAULT_STYLES } from '_common/constants/googleMap';
import {
  WHITELABEL_UI,
  WhiteLabelUtils,
  withWhitelabelProps,
} from '_common/whitelabelConfig';
import {
  getMapVisibleDistance,
  normalizeStoreName,
} from 'pages/success/utils/locationUtils';
import { IStore, LatLng } from 'types/store';
import {
  ILocationStore,
  IDirectoryStore,
  IOrderStore,
  IDetailsStore,
} from 'types/mobxStores';
import { RouteComponentProps } from 'react-router';
import { IRouterMatch } from 'types/core';
import { GOOGLE_API_KEY } from '_common/constants/common';

const { Option } = Select;
interface WrapperProps extends RouteComponentProps<IRouterMatch> {
  initialCenter: LatLng;
  zoom: number;
  google: any;
  map?: any;
  locationStore: ILocationStore;
  directoryStore: IDirectoryStore;
  orderStore: IOrderStore;
  detailsPageStore: IDetailsStore;
  orderCreateSuccess: boolean;
  whiteLabeled: any;
}

type State = {
  position?: {
    lat: () => number;
    lng: () => number;
  };
  key: string;
  activeMarker?: any;
  activeStore?: any;
  infoWindowShown: boolean;
  bounds: any;
  geo: any;
};

@observer
class MobileMap extends React.Component<WrapperProps, State> {
  state = {
    position: null,
    key: 'list',
    activeMarker: null,
    activeStore: null,
    infoWindowShown: false,
    geo: {
      lat: null,
      lng: null,
    },
    bounds: null,
  };

  markerRefs = {};

  inputRef = React.createRef();

  disposeReaction: any;

  componentDidMount() {
    this.initAutocomplete();
    this.disposeReaction = reaction(
      () => this.props.locationStore.stores,
      () => {
        this.setBounds();
      }
    );
  }

  componentWillUnmount() {
    this.disposeReaction();
  }

  componentDidUpdate(prevProps) {
    if (this.props.map !== prevProps.map) {
      this.initAutocomplete();
    }
  }

  setBounds = () => {
    const {
      locationStore: {
        stores,
        isNewLocationSearch,
        mapDragActive,
        clientGeoCoordinates,
      },
      google,
    } = this.props;
    if (!isNewLocationSearch || mapDragActive) return;
    const bounds = new google.maps.LatLngBounds();
    if (!stores.length) {
      bounds.extend({
        lat: clientGeoCoordinates.lat - 0.1,
        lng: clientGeoCoordinates.lng - 0.1,
      });
      bounds.extend({
        lat: clientGeoCoordinates.lat + 0.1,
        lng: clientGeoCoordinates.lng + 0.1,
      });
    } else {
      stores.forEach(({ geo }) => {
        bounds.extend({ lat: geo.lat, lng: geo.lon });
      });
    }
    this.setState({ bounds });
  };

  logFieldEvent = (eventName, extraPayload?: any) => {
    const { href: url } = window.location;
    const { company: retailerName } = this.props.match.params;
    amplitude.logEvent(eventName, {
      url,
      retailerName,
      ...extraPayload,
    });
  };

  onGeoIconClick = async () => {
    const searchInput: any = this.inputRef && this.inputRef.current;
    if (!searchInput) return;
    this.logFieldEvent('click my location icon');
    const { company } = this.props.match.params;
    this.setState({
      activeMarker: null,
      infoWindowShown: false,
    });
    searchInput.value = '';
    searchInput.value = await commonStoresActions.searchStoresNearMe(company);
  };

  onMarkerClick = (marker: any, storeData: IStore) => {
    this.setState({
      activeMarker: marker,
      activeStore: storeData,
      infoWindowShown: true,
    });
  };

  onMapClicked = () => {
    if (this.state.infoWindowShown) {
      this.setState({
        activeMarker: null,
        infoWindowShown: false,
      });
    }
  };

  onInputClick = () => {
    this.logFieldEvent('click on search field');
  };

  onStoreClick = (store: IStore) => {
    commonStoresActions.setActiveStoreId(store.storeId);
    this.changeTab('map');
    this.setState({
      activeMarker: this.markerRefs[store.storeId].current.wrappedInstance
        .googleMarker,
      activeStore: store,
      infoWindowShown: true,
      geo: {
        lat: store.geo.lat,
        lng: store.geo.lon,
      },
    });
  };

  changeTab = key => {
    this.setState({ key });
    key === 'map' && this.setBounds();
  };

  initAutocomplete() {
    const {
      google,
      map,
      match: {
        params: { company },
      },
      whiteLabeled: countryCode,
    } = this.props;
    const searchInput: any = this.inputRef.current;
    if (!google || !map || !searchInput) return;

    const autocomplete = new google.maps.places.Autocomplete(searchInput, {
      types: ['geocode'],
      componentRestrictions: { country: [countryCode.countryCode] },
    });
    autocomplete.bindTo('bounds', map);
    autocomplete.setComponentRestrictions({
      country: [countryCode.countryCode],
    });

    if (searchInput.value && searchInput.value.length > 0) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          address: searchInput.value,
          componentRestrictions: {
            country: countryCode.countryCode,
          },
        },
        async (results, status) => {
          if (status === 'OK') {
            const position = results[0].geometry.location;
            await commonStoresActions.setSearchGeoCoordinates({
              lat: position.lat(),
              lng: position.lng(),
            });
          }
        }
      );
    }

    autocomplete.addListener('place_changed', () => {
      this.logFieldEvent('click search', { locationInput: searchInput.value });
      const place = autocomplete.getPlace();

      if (!place.geometry) return;

      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(14);
      }

      const position = place.geometry.location;
      this.setState({
        position,
        activeMarker: null,
        infoWindowShown: false,
      });
      commonStoresActions.searchStoresByCoords({
        lat: position.lat(),
        lng: position.lng(),
        company,
      });
    });
  }

  renderOption = (option: { value: string; label: string }, index: number) => {
    const { value, label } = option;
    return (
      <Option key={`option-${index}`} value={value}>
        {label}
      </Option>
    );
  };

  onStoreTypeChange = (storeType: string) => {
    commonStoresActions.setLocationType(storeType);
    const { lastSearch } = this.props.locationStore;
    const {
      params: { company },
    } = this.props.match;
    if (!lastSearch) return;
    commonStoresActions.searchStoresByCoords({
      lat: lastSearch.lat,
      lng: lastSearch.lng,
      company,
    });
  };

  handleClearSearch = () => {
    // @ts-ignore
    this.inputRef.current.value = '';
  };

  renderSelect() {
    const { locationTypesOptions } = this.props.directoryStore;
    const { isLoading } = this.props.locationStore;
    const defaultValue = locationTypesOptions.length
      ? locationTypesOptions[0].value
      : null;
    return (
      <Select
        defaultValue={defaultValue}
        placeholder="Filter by location type"
        onChange={this.onStoreTypeChange}
        disabled={isLoading}
      >
        {locationTypesOptions.map(this.renderOption)}
      </Select>
    );
  }

  renderMarker = (storeData: IStore) => {
    const { storeId, geo, locationType, companyId } = storeData;
    const {
      locationStore: { assetsHub },
    } = this.props;
    if (!this.markerRefs[storeId]) {
      this.markerRefs[storeId] = React.createRef();
    }
    const assets = assetsHub.get(companyId);
    return (
      <Marker
        key={storeId}
        ref={this.markerRefs[storeId]}
        storeId={storeId}
        lat={geo.lat}
        lng={geo.lon}
        storeData={storeData}
        onClick={this.onMarkerClick}
        locationType={locationType}
        merchantPins={assets && assets.pins}
      />
    );
  };

  get normalizedStoreName() {
    const {
      activeStore: { storeName, locationType },
    } = this.state;
    return normalizeStoreName(storeName, locationType);
  }

  renderStoreInfo = () => {
    const { activeStore } = this.state;
    if (activeStore) {
      const {
        place: { address },
        locationInfo,
      } = activeStore;

      const addressLine = address.line1 || address.line2 || '';
      const storeAddress = `${addressLine}, ${address.town}, ${address.postcode}`;
      const storeDistance = this.props.locationStore.getDistanceToLastSearch(
        locationInfo
      );

      return (
        <>
          <InfoHeader>
            <InfoTitle>{this.normalizedStoreName}</InfoTitle>
            <InfoDistance>{storeDistance}</InfoDistance>
          </InfoHeader>
          <InfoAddress>{storeAddress}</InfoAddress>
        </>
      );
    }

    return <>Store Info</>;
  };

  renderInfoWindow = () => {
    const { activeMarker, infoWindowShown } = this.state;

    return (
      // @ts-ignore
      <InfoWindow marker={activeMarker} visible={infoWindowShown}>
        {this.renderStoreInfo()}
      </InfoWindow>
    );
  };

  handleDrag = async (mapProps, map) => {
    const { google } = this.props;
    const newCenter = {
      lat: map.center.lat(),
      lng: map.center.lng(),
    };
    const distance = getMapVisibleDistance(map, google);
    try {
      await commonStoresActions.searchStoresByCoords(newCenter, true, distance);
    } catch (e) {
      console.log('handleDrag error', e);
    }
  };

  renderMap() {
    const {
      locationStore: { stores, clientGeoCoordinates },
      orderCreateSuccess,
    } = this.props;
    const { position, bounds, geo } = this.state;

    return (
      <>
        {orderCreateSuccess && (
          <Map
            {...this.props}
            center={geo}
            // @ts-ignore
            defaultCenter={position}
            centerAroundCurrentLocation={false}
            bounds={bounds}
            containerStyle={{
              height: 'calc(100vh - 530px)',
              minHeight: 500,
              position: 'relative',
            }}
            styles={GOOGLE_MAP_DEFAULT_STYLES}
            onClick={this.onMapClicked}
            onDragend={this.handleDrag}
            // @ts-ignore
            gestureHandling="greedy"
          >
            <Marker
              isDefaultUser
              lat={clientGeoCoordinates.lat}
              lng={clientGeoCoordinates.lng}
            />
            {stores.map(this.renderMarker)}
            {this.renderInfoWindow()}
          </Map>
        )}
      </>
    );
  }

  get userAddress() {
    const {
      orderStore: { isIntegratedFlow, userInfo },
    } = this.props;

    const {
      addressLine1,
      addressLine2,
      city,
      postcode,
      state,
    } = isIntegratedFlow
      ? WhiteLabelUtils.convertAddressForMap(userInfo)
      : this.props.detailsPageStore.formFields;

    return [addressLine1, addressLine2, city, state, postcode]
      .filter(v => v)
      .join(', ');
  }

  render() {
    const icon = (
      <GeoIcon
        image={geoIcon}
        onClick={this.onGeoIconClick}
        width={24}
        height={24}
      />
    );
    const { key } = this.state;
    const {
      locationStore: { isLoading },
      whiteLabeled: { isLocationTypesSelectVisible },
    } = this.props;

    return (
      <>
        <InputWrapper>
          <Input
            placeholder="Search for drop off locations"
            ref={this.inputRef}
            onClick={this.onInputClick}
            disabled={isLoading}
            defaultValue={this.userAddress || ''}
          />
          <ClearButton onClick={this.handleClearSearch}>Clear</ClearButton>
          {icon}
        </InputWrapper>
        {isLocationTypesSelectVisible && this.renderSelect()}
        <Tabs>
          <TabHeader
            active={key === 'list'}
            onClick={() => this.changeTab('list')}
          >
            List view
          </TabHeader>
          <TabHeader
            active={key === 'map'}
            onClick={() => this.changeTab('map')}
          >
            Map view
          </TabHeader>
        </Tabs>
        <TabWrapper>
          <TabContent active={key === 'list'}>
            <StoreListWrapper>
              {this.props.orderCreateSuccess && (
                <StoreList onStoreClick={this.onStoreClick} />
              )}
            </StoreListWrapper>
          </TabContent>
          <TabContent active={key === 'map'}>{this.renderMap()}</TabContent>
        </TabWrapper>
      </>
    );
  }
}

const MapWrapper = (props: WrapperProps) => (
  <Root>
    <Map {...props} visible={false}>
      <MobileMap {...props} />
    </Map>
  </Root>
);

MapWrapper.defaultProps = {
  initialCenter: WHITELABEL_UI.common.defaultMapCenter,
  zoom: 5,
  containerStyle: {
    width: '100%',
    position: 'relative',
  },
};

export default compose(
  withRouter,
  GoogleApiWrapper({
    apiKey: GOOGLE_API_KEY,
    libraries: ['places', 'geometry'],
  }),
  inject('directoryStore', 'locationStore', 'detailsPageStore', 'orderStore'),
  withWhitelabelProps({
    countryCode: 'ui.common.countryCode',
    isLocationTypesSelectVisible:
      'ui.pages.success.isLocationTypesSelectVisible',
  }),
  observer
)(MapWrapper);

import { Button, Layout } from '_common/components';
import styled from 'styled-components';
import { isIE } from 'react-device-detect';

export const Wrapper = styled.div`
  ${Layout.Container} {
    height: calc(100vh - 170px);
    max-height: calc(100vh - 170px);

    @media only screen and (max-width: 768px) {
      height: auto;
      max-height: initial;
    }
  }
  ${Layout.LeftSection} {
    padding: 10px 32px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 250px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .has-error .ant-input {
    border-color: #ff3654 !important;
  }

  @media only screen and (max-width: 768px) {
    ${Layout.LeftSection} {
      width: 100%;
      padding: 0;
      margin-bottom: 0;
      justify-content: flex-start;
      min-height: auto;
    }
  }
`;

export const BackButton = styled(Button)`
  && {
    border-radius: 3px;
    margin-top: 19px;
    font-family: Arial, sans-serif;
    font-size: 23px;
    letter-spacing: 0;
    text-align: center;
    color: #222222;
    background: #fff;
    border: 1px solid #c7c7cc;

    &:hover,
    &:active,
    &:focus {
      background: #fff;
      color: #222222;
      border: 1px solid #949499;
      opacity: 1;
    }

    @media only screen and (max-width: 768px) {
      display: none;
    }

    // Ensure we don't crop buttons on IE.
    ${isIE && 'min-height: 60px'};
  }
`;

export const PrimaryText = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 24px;
  font-weight: bold;
  color: #000;
`;

export const SecondaryText = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 14px;
  font-weight: 300;
  line-height: 1.71;
  color: #000;
`;

export const IframeErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

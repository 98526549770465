import styled from 'styled-components';

const getAlignToLeft = ({ alignToLeft }) =>
  alignToLeft ? 'flex-start' : 'center';

export const StyledLogo = styled.img`
  object-fit: contain;
  max-height: 100%;
  max-width: 200px;
`;

export const LogoWrapper = styled.div<{ alignToLeft: boolean }>`
  width: 200px;
  height: 80px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    height: 69px;
    justify-content: ${getAlignToLeft};
  }
`;

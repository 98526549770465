// eslint-disable-next-line import/no-cycle
import { getMerchantFromUrl } from '../utils';

export const MERCHANT_ORGANISATION = getMerchantFromUrl();
export const ROOT_ORG_ID = process.env.REACT_APP_ROOT_ORG_ID || '';
export const SECURE_PAY_API_KEY =
  process.env.REACT_APP_SECURE_PAY_API_KEY || '';
export const SECURE_PAY_MERCHANT_CODE =
  process.env.REACT_APP_SECURE_PAY_MERCHANT_CODE || '';
export const SECURE_PAY_CLIENT_ID =
  process.env.REACT_APP_SECURE_PAY_CLIENT_ID || '';
export const ROOT_CARRIER_ID = process.env.REACT_APP_CARRIER || '';
export const SECURE_PAY_MERCHANT_ID =
  process.env.REACT_APP_SECURE_PAY_MERCHANT_ID || '';
export const SECURE_PAY_TRANSACTION_PASSWORD =
  process.env.REACT_APP_SECURE_PAY_TRANSACTION_PASSWORD || '';
export const RETURNS_PORTAL_BUILD_NAME =
  process.env.REACT_APP_RETURNS_PORTAL_BUILD_NAME;
export const ALLOW_MULTIPLE_INTEGRATED_RETURNS = !!(
  process.env.REACT_APP_MULTIPLE_INTEGRATED_RETURNS &&
  process.env.REACT_APP_MULTIPLE_INTEGRATED_RETURNS.trim().toUpperCase() ===
    'TRUE'
);
export const HOTJAR_ID_WITH_VERSION =
  process.env.REACT_APP_HOTJAR_ID_WITH_VERSION || '';
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const SENTRY_DSN_URL = process.env.REACT_APP_SENTRY_DSN_URL;

export const AsyncStatus = Object.freeze({
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  IDLE: 'IDLE',
});

export const PAYMENT_TYPES = {
  MERCHANT_PAID: 'MERCHANT_PAID',
  CONSUMER_PAID: 'CONSUMER_PAID',
  BOTH_PAID: 'BOTH_PAID',
};

export const ASPECT_RATIO_16_9 = 1.777777777777778;

export const DEFAULT_SPECIAL_CHARS_REGEX = {
  // eslint-disable-next-line no-useless-escape
  regexValue: /([}{\[\]<>!#$%^*])/,
  errorText: 'Those "<>[]}{}!#$%^*" characters aren\'t allowed',
};

export const CURRENCY_SYMBOLS = {
  USD: '$',
  JPY: '¥',
  GBP: '£',
};

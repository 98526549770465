import lodashGet from 'lodash/get';
import React from 'react';

// TODO: refactor this file at all.
// @ts-ignore
// eslint-disable-next-line import/extensions,import/no-unresolved
import WHITELABEL_CONFIG from './override';

const addGetters = obj => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // eslint-disable-next-line no-param-reassign,func-names
      obj[key].get = function (path) {
        return lodashGet(this, path);
      };
    }
  }
  // eslint-disable-next-line no-param-reassign,func-names
  obj.get = function (path) {
    return lodashGet(this, path);
  };
  return obj;
};

const customConfig = addGetters(WHITELABEL_CONFIG);

customConfig.initServices = methodMap => {
  // eslint-disable-next-line no-restricted-syntax
  for (const serviceName in customConfig.services) {
    if (
      !Object.prototype.hasOwnProperty.call(customConfig.services, serviceName)
    ) {
      // eslint-disable-next-line no-continue
      continue;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const methodName in methodMap) {
      if (!Object.prototype.hasOwnProperty.call(methodMap, methodName)) {
        // eslint-disable-next-line no-continue
        continue;
      }
      customConfig.services[serviceName][methodName] = methodMap[methodName];
    }
  }
};

const { ui, services, stores, utils, initServices, constants } = customConfig;

const withWhitelabelProps = configProps => WrappedComponent => ownProps => {
  const { children, ..._props } = ownProps;
  const populatedProps = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const key in configProps) {
    if (Object.prototype.hasOwnProperty.call(configProps, key)) {
      populatedProps[key] = customConfig.get(configProps[key]);
    }
  }
  return (
    <WrappedComponent {..._props} whiteLabeled={populatedProps}>
      {children}
    </WrappedComponent>
  );
};

export {
  ui as WHITELABEL_UI,
  services as WHITELABEL_SERVICES,
  stores as WHITELABEL_STORES,
  withWhitelabelProps,
  initServices,
  utils as WhiteLabelUtils,
  constants as WhiteLabelConstants,
};

export default customConfig;

import Main from '_common/routes/Main/Main';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import PrivateRoute from '_common/routes/PrivateRoute';
import { ScrollToTop, getMerchantFromUrl } from '_common/utils';

function App() {
  const merchantName = getMerchantFromUrl();
  return (
    <BrowserRouter>
      <>
        <ScrollToTop />
        <PrivateRoute component={Main} merchForRedirect={merchantName} />
      </>
    </BrowserRouter>
  );
}

export default App;

import { find, get } from 'lodash';
import { isPaymentPageAvailable } from '_common/utils';
import { WhiteLabelConstants } from '_common/whitelabelConfig';

export default (stores): string => {
  const { companyConfig, returnReasons } = stores.directoryStore;
  const {
    formFields: { returnReason },
  } = stores.detailsPageStore;
  const { consumerChargeAccount, merchantChargeAccount, paymentType } = get(
    companyConfig,
    `products.${WhiteLabelConstants.PRODUCT_NAME}.customerPayments`
  );
  let accountNumber = merchantChargeAccount;

  /** sent in http headers. */
  if (
    isPaymentPageAvailable(paymentType) &&
    returnReasons &&
    returnReasons.reasons &&
    get(
      find(returnReasons.reasons, { description: returnReason }),
      'paymentRequired'
    )
  ) {
    accountNumber = consumerChargeAccount;
  }

  return accountNumber;
};

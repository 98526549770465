import * as React from 'react';
import BaseModal from '_common/components/BaseModal';
import { ErrorModalWrapper, Text, TextError } from './elements';
import { BackButton } from 'pages/payment/elements/elements';
import { SECURE_PAY_SUMMARY_CODES } from '_common/components/AustraliaPost/SecureFrame/SecureFrame';

type Props = {
  toggle: () => void;
  errorType: number;
};

export default class ErrorModal extends React.Component<Props, null> {
  /**
   * Secure pay transaction responses
   *
   * The one digit summary of the transaction result:
   * 1 = Approved
   * 2 = Declined by the bank
   * 3 = Declined for any other reason
   * 4 = Cancelled by user
   */
  getSecurePayResponse = errorType => {
    switch (errorType) {
      case SECURE_PAY_SUMMARY_CODES.APPROVED:
        return 'Approved, but an error was encountered.';
      case SECURE_PAY_SUMMARY_CODES.DECLINED_BY_BANK:
        return 'Declined by the Bank';
      case SECURE_PAY_SUMMARY_CODES.DECLINED_BY_OTHER:
        return 'Declined for an unknown reason';
      case SECURE_PAY_SUMMARY_CODES.CANCELLED:
        return 'Cancelled by the User';
      default:
        return 'Illegal application state';
    }
  };

  render() {
    const { toggle, errorType } = this.props;
    const securePayType = this.getSecurePayResponse(errorType);
    return (
      <BaseModal>
        <ErrorModalWrapper onClick={toggle}>
          <Text>
            The payment process encountered an error. Please enter your card
            details and try again.
            {securePayType && (
              <div>
                The error encountered was:
                <TextError>{securePayType}</TextError>
              </div>
            )}
          </Text>
          <BackButton onClick={toggle}>Close</BackButton>
        </ErrorModalWrapper>
      </BaseModal>
    );
  }
}

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { IRouterMatch } from 'types/core';
import { Layout } from '_common/components';
import { Wrapper, Title } from 'pages/start/elements';
import { StyledButtonLink } from '_common/components/Button/elements';
import { RightSectionImage } from '_common/global-elements/pages';
import { DirectoryStore } from 'stores';
import { inject } from 'mobx-react';
import { compose } from 'recompose';
import { get } from 'lodash';

type Props = RouteComponentProps<IRouterMatch> & {
  directoryStore: DirectoryStore;
  widthOfBackgroundImage: number;
  whiteLabeled: any;
  merchForRedirect?: string;
};

/**
 * Ensures that we add the protocol onto the url so it's not relative to current
 * portal
 *
 * @param url
 */
const addHttp = (url: string) => {
  let addHttpUrl = url;
  if (!/^https?:\/\//.test(url)) {
    addHttpUrl = `https://${url}`;
  }
  return addHttpUrl;
};

const NotFoundPage = (props: Props) => {
  const { widthOfBackgroundImage, merchForRedirect, directoryStore } = props;

  let isURLDisabled;
  let website;
  /*
   * Confirm that we have a directory store before getting values, as may not
   * be set due to an error
   */
  if (directoryStore) {
    isURLDisabled = get(directoryStore, 'isURLDisabled');
    website = get(directoryStore, 'companyConfig.website');
  }

  let redirectButton;
  if (isURLDisabled && website) {
    redirectButton = (
      <StyledButtonLink href={`${addHttp(website)}`}>
        Visit website
      </StyledButtonLink>
    );
  } else if (merchForRedirect) {
    redirectButton = (
      <StyledButtonLink href={`/${merchForRedirect}`}>
        Visit website
      </StyledButtonLink>
    );
  }

  return (
    <Wrapper>
      <Layout.Container>
        <Layout.LeftSection>
          <div>
            <Title>Ooops, we can’t find the page you’re looking for.</Title>
            {redirectButton}
          </div>
        </Layout.LeftSection>
        <Layout.RightSection widthOfBackgroundImage={widthOfBackgroundImage}>
          <RightSectionImage />
        </Layout.RightSection>
      </Layout.Container>
    </Wrapper>
  );
};

export default compose(inject('directoryStore'))(NotFoundPage);

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import '_common/utils/dynamicManifest';
import App from '_common/routes/App/App';
import { configure } from 'mobx';
import { Provider as MobxProvider } from 'mobx-react';
import { initSentry } from '_common/utils/sentry';
import React from 'react';
import ReactDOM from 'react-dom';
import stores from './stores';
import './themes/globals.css';
import { initHotJar } from '_common/utils/hotJar';

configure({ enforceActions: 'observed' });
initHotJar();
initSentry();

const { href } = window.location;
const MOUNT_NODE = document.getElementById('root');

/** Remove trailing slash from end of url. */
if (/\/{2,}$/.test(href)) {
  window.location.href = href.replace(/\/{2,}$/, '/');
}

ReactDOM.render(
  <MobxProvider {...stores}>
    <App />
  </MobxProvider>,
  MOUNT_NODE
);

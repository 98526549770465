import { Button } from '_common/components';
import styled, { css } from 'styled-components';
import { StyledpostLogo } from '_common/components/PortOfficeLogo/elements';
import { LeftSection } from '_common/components/Layout/Layout';

export const Title = styled.h2<{ integratedFlow?: boolean }>`
  font-family: ${props => props.theme.fonts.basic};
  ${({ integratedFlow }) =>
    integratedFlow
      ? css`
          font-size: 28px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 0.86;
          color: #000000;
          margin-bottom: 33px;
          @media only screen and (max-width: 768px) {
            font-size: 18px;
            line-height: 1.56;
            letter-spacing: -0.23px;
          }
        `
      : css`
          font-size: 24px;
          font-weight: bold;
          letter-spacing: -0.3px;
          color: #000000;
          margin-top: 5px;
          @media only screen and (max-width: 768px) {
            margin-top: 0;
          }
        `};
  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const SubTitle = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0;
  color: #000000;
`;

export const SubText = styled.p<{ integratedFlow?: boolean }>`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  color: #000000;
  ${({ integratedFlow }) =>
    integratedFlow
      ? css`
          margin-top: 18px;
          margin-bottom: 0;
        `
      : css`
          margin-top: 23px;
          margin-bottom: 40px;
        `};

  span {
    text-decoration: underline;
    display: block;
  }

  img {
    margin-right: 5px;
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: ${props => (props.integratedFlow ? '0' : '40px')};
  }
`;

export const Wrapper = styled.div<{
  integrated: boolean;
  withMargin: boolean;
}>`
  height: ${({ integrated }) =>
    integrated ? 'auto' : 'calc(100vh - 170px)'}; // calc(100vh - 170px);
  ${LeftSection} {
    display: flex;
    width: 480px;
    flex-direction: column;
    border: 1px solid #c7c7cc;
    margin-bottom: 0;
    ${StyledpostLogo} {
      margin-top: auto;
    }

    @media only screen and (max-width: 768px) {
      margin-bottom: 0;
      height: initial;
      ${StyledpostLogo} {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    ${LeftSection} {
      padding: 0;
      border: none;
      width: 100%;
    }
  }

  @media only screen and (max-width: 450px) {
    height: auto;
    flex: 1;
  }
`;

export const DownloadButton = styled(Button)`
  ${props =>
    props.integratedFlow &&
    css`
      &&& {
        width: 260px;
        height: 36px;
        font-size: 16px;
        padding: 5px 0;
      }
    `};
  margin-top: 10px;
`;

export const PrintSection = styled.div<{ integratedFlow?: boolean }>`
  ${({ integratedFlow }) =>
    integratedFlow
      ? css`
          text-align: center;
        `
      : css`
          display: flex;
          flex-direction: column;
          padding: 0 40px 20px 40px;
          box-shadow: 0 1px 0 0 #e6e7e8;

          @media only screen and (max-width: 768px) {
            padding: 0 20px 20px;
          }
        `};
`;

export const StoreListWrapper = styled.div<{ isIntegratedFlow?: boolean }>`
  ${({ isIntegratedFlow }) =>
    isIntegratedFlow && 'max-height: calc(100vh - 170px)'};
  overflow-y: auto;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const LoaderBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e8e8e8;
  height: 100%;
  flex-grow: 1;
  @media only screen and (max-width: 768px) {
    height: 200px;
    background-color: transparent;
  }
`;

export const StepsList = styled.div`
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    padding: 0 20px;
    flex-direction: column;
  }
`;

export const IntegratedFlowWrapper = styled.div``;

export const PrintSectionWrapper = styled.div`
  box-shadow: 0 -1px 0 0 #e6e7e8, 0 1px 0 0 #e6e7e8;
  padding-top: 34px;
  padding-bottom: 34px;
  @media only screen and (max-width: 768px) {
    height: auto;
    padding-top: 24px;
  }
`;

export const StoresListError = styled.div`
  text-align: center;
  margin-top: 36px;
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 359px;
  @media only screen and (max-width: 768px) {
    margin-bottom: 30px;
    flex-basis: auto;
    align-items: center;
  }
`;

export const Image = styled.div<{ imageUrl: string }>`
  min-width: 35px;
  min-height: 35px;

  background-image: url(${({ imageUrl }) => imageUrl});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  @media only screen and (max-width: 768px) {
    min-width: 44px;
    max-width: 44px;
    max-height: 50px;
    margin-right: 15px;
    margin-bottom: 15px;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const Caption = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 20px;
  font-weight: bold;
  line-height: 1.8;
  letter-spacing: 0;
  color: #000000;
  @media only screen and (max-width: 768px) {
    line-height: initial;
    margin-bottom: 8px;
    font-size: 16px;
  }
`;

export const Text = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: 0;
  color: #000000;
  @media only screen and (max-width: 768px) {
    line-height: initial;
    font-size: 14px;
  }
`;

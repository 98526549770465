import {
  Checkbox,
  DatePicker,
  Input,
  InputLabel,
  TextArea,
  Layout,
  Progressbar,
} from '_common/components';
import { endsWith } from 'lodash';
import Select, { Option } from '_common/components/Select/Select';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React, { Component, SyntheticEvent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { OrderStore, ThemeStore } from 'stores';
import {
  validateOnSpecialCharacters,
  getMerchantFromUrl,
  getArrayOfErrors,
  getErrorsOfAddressValidation,
  isPaymentPageAvailable,
  validatePhoneNumber,
} from '_common/utils';
import { AsyncStatus } from '_common/constants/common';
import ReturnItem from './ReturnItem';
import {
  BackButton,
  CheckboxFormItemContainer,
  CheckboxWrapper,
  RowWrapper,
  Terms,
  DetailsPageWrapper,
  LeftSection,
  LeftSectionContent,
  Title,
  OrderTitle,
  OrderNumber,
  SubTitle,
  IntegratedButtonsBlock,
  LoadingMessage,
} from '../elements/elements';

import { ConfirmButton, FormErrorMessage } from 'pages/start/elements';
import amplitude from '_common/utils/amplitude';
import DirectoryStore from '_common/stores/directoryStore';
import commonStoresActions from '_common/actions';
import { RightSectionImage } from '_common/global-elements/pages';
import { withWhitelabelProps } from '_common/whitelabelConfig';
import { MOBX_STORES } from 'storage';
import {
  PURCHASE_DATE_ERRORS,
  ALLOWED_DATEPICKER_FORMATS,
} from '_common/constants/date';
import { IDetailsStore } from 'types/mobxStores';
import { IRouterMatch } from 'types/core';

type Props = RouteComponentProps<IRouterMatch> &
  FormComponentProps & {
    detailsPageStore: IDetailsStore;
    orderStore: OrderStore;
    directoryStore: DirectoryStore;
    themeStore: ThemeStore;
    whiteLabeled: any;
  };

@observer
class DetailsPage extends Component<Props> {
  static PAGE_NAME = 'Details page';

  pageTop = React.createRef<HTMLElement>();

  componentDidMount() {
    amplitude.logEventWithOrganisationAndUrl('page_open', {
      page_name: DetailsPage.PAGE_NAME,
    });

    /** extract company on root page */
    const company = getMerchantFromUrl();

    if (company) {
      const productJourney = commonStoresActions.getProductJourneyType();

      if (productJourney.NOT_SUPPORTED || productJourney.INTEGRATED) {
        commonStoresActions.getOrderById(company).catch(() => {
          // of no order - go back to start page
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          productJourney.INTEGRATED && this.props.history.push(`/${company}`);
        });
      }
    }
  }

  onSubmit = (e: SyntheticEvent<any>) => {
    e.preventDefault();

    const {
      match: {
        params: { company },
      },
      form: { validateFieldsAndScroll, setFields },
      history: { push },
      detailsPageStore: { enableFormErrorMessage },
      directoryStore: {
        paymentConfig: { paymentType },
      },
      whiteLabeled: { addressInvalidMsg },
    } = this.props;

    validateFieldsAndScroll(async (errors, values) => {
      this.logFieldEvent('click confirm', null);

      if (errors) {
        amplitude.logValidationErrors(errors, DetailsPage.PAGE_NAME);
        this.pageTop.current.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
        return enableFormErrorMessage(getArrayOfErrors(errors, 'DETAILS')[0]);
      }

      if (!values.confirmTerms) {
        return;
      }
      try {
        enableFormErrorMessage(false);
        await commonStoresActions.validateAddress(values);

        push(
          `/${company}/${
            isPaymentPageAvailable(paymentType) ? 'payment' : 'success'
          }`
        );
      } catch {
        enableFormErrorMessage(addressInvalidMsg);
        amplitude.logValidationErrors(
          getErrorsOfAddressValidation(values),
          DetailsPage.PAGE_NAME
        );
        setFields(getErrorsOfAddressValidation(values));
      }
    });
  };

  /**
   * Back method, will always redirect to home page if a company is present
   */
  goBack = () => {
    const {
      match: {
        params: { company },
      },
      orderStore: { isIntegratedFlow, clearReturnedProducts },
    } = this.props;
    isIntegratedFlow && clearReturnedProducts();
    if (company) {
      this.props.history.push(`/${company}`);
    } else {
      this.props.history.goBack();
    }
  };

  logFieldEvent = (eventName, fieldName, extraPayload?: object) => {
    const { href: url } = window.location;
    const { company: retailerName } = this.props.match.params;
    const payload: any = {
      url,
      retailerName,
      ...extraPayload,
    };
    if (fieldName) {
      payload.fieldName = fieldName;
    }
    amplitude.logEvent(eventName, payload);
  };

  handleInputClick = (fieldName: string) => () => {
    this.logFieldEvent('clicking on form fields', fieldName);
  };

  handleDatePickerClick = (e: SyntheticEvent<any>) => {
    // @ts-ignore
    if (e.target.tagName === 'INPUT') {
      this.logFieldEvent('clicking drop down field', 'purchaseDate');
    }
  };

  handleDatePickerSelect = data => {
    this.logFieldEvent('selected date', 'purchaseDate', {
      purchaseDate: moment(data).format('DD-MM-YYYY'),
    });
  };

  handleDropdownClick = (fieldName: string) => (e: SyntheticEvent<any>) => {
    // @ts-ignore
    if (e.target.tagName !== 'LI') {
      this.logFieldEvent('clicking drop down field', fieldName);
    }
  };

  handleInputBlur = (fieldName: string) => {
    const { href: url } = window.location;
    const { company: retailerName } = this.props.match.params;

    return () => {
      amplitude.logEvent('entering info into form fields', {
        url,
        fieldName,
        retailerName,
      });
    };
  };

  handleSelect = (value: string, { props: { name } }) => {
    const payload = {
      ...this.props.form.getFieldsValue([name]),
      [name]: value,
    };
    this.logFieldEvent('clicking an option', name, payload);
  };

  handleCheckboxChange = () => {
    this.logFieldEvent('tick "I agree with the terms and conditions"', null);
  };

  validatePurchaseDate = current => {
    const {
      directoryStore: { purchaseWarrantyPeriodDays },
    } = this.props;
    return (
      current &&
      current < moment().subtract(purchaseWarrantyPeriodDays + 1, 'days')
    );
  };

  disableFutureDate = current => current && current > moment();

  renderForm = () => {
    const {
      form: { getFieldDecorator, getFieldError },
      detailsPageStore: { showFormErrorMessage, formFields },
      directoryStore: { returnReasons, returnFormFields },
      whiteLabeled: {
        cityText,
        cityPlaceholder,
        zipCodeText,
        zipCodeLength,
        zipCodePlaceholder,
        zipPattern,
        zipError,
        tcUrl,
        countryStates,
        whitelabeledRules,
        getStateLabel,
        showReturnItems,
      },
    } = this.props;

    const shouldRenderReturnReasons =
      returnReasons &&
      returnReasons.enabled &&
      returnReasons.reasons &&
      returnReasons.reasons.length &&
      returnReasons.reasons.some(({ enabled }) => enabled);

    let reasonsCommentsPlaceholder =
      returnFormFields.reasonCommentsFieldName || 'Reason Comments';
    if (
      returnFormFields.reasonCommentsMandatory &&
      !endsWith(reasonsCommentsPlaceholder, '*')
    ) {
      reasonsCommentsPlaceholder = `${reasonsCommentsPlaceholder}*`;
    }

    // @ts-ignore
    return (
      <Form onSubmit={this.onSubmit}>
        <FormErrorMessage hidden={!showFormErrorMessage}>
          {showFormErrorMessage}
        </FormErrorMessage>
        <RowWrapper fullWidth={!showReturnItems}>
          {showReturnItems && (
            <Form.Item>
              <InputLabel htmlFor="returnItems">Number of items*</InputLabel>
              {getFieldDecorator('returnItems', {
                initialValue: formFields.returnItems,
                rules: [
                  {
                    required: true,
                  },
                ],
              })(
                <Select
                  placeholder="Items to return*"
                  onSelect={this.handleSelect}
                  onClick={this.handleDropdownClick('returnItems')}
                >
                  {new Array(10).fill(0).map((el, i) => (
                    <Option key={el} value={i + 1}>
                      {i + 1}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          )}
          {returnFormFields && returnFormFields.purchaseDate && (
            <Form.Item>
              <InputLabel htmlFor="purchaseDate">Purchase date*</InputLabel>
              {getFieldDecorator('purchaseDate', {
                initialValue: formFields.purchaseDate
                  ? moment(formFields.purchaseDate)
                  : formFields.purchaseDate,
                rules: [
                  {
                    validator: (
                      _: any,
                      date: string,
                      callback: (msg?: string) => void
                    ) => {
                      if (!date) {
                        return callback('Purchase date is required');
                      }
                      return this.validatePurchaseDate(moment(date))
                        ? callback(PURCHASE_DATE_ERRORS.FULL_MESSAGE)
                        : callback();
                    },
                  },
                ],
              })(
                <DatePicker
                  format={ALLOWED_DATEPICKER_FORMATS}
                  mode="date"
                  dateErrors={getFieldError('purchaseDate')}
                  disabledDate={this.disableFutureDate}
                  placeholder="Select date"
                  onClick={this.handleDatePickerClick}
                  onChange={this.handleDatePickerSelect}
                  fullWidth={!showReturnItems}
                />
              )}
            </Form.Item>
          )}
        </RowWrapper>
        {shouldRenderReturnReasons && (
          <Form.Item>
            <InputLabel htmlFor="returnReason">Reason for return*</InputLabel>
            {getFieldDecorator('returnReason', {
              initialValue: formFields.returnReason,
              rules: [
                {
                  required: true,
                },
              ],
            })(
              <Select
                placeholder="Select reason for return"
                onSelect={this.handleSelect}
                onClick={this.handleDropdownClick('returnReason')}
              >
                {returnReasons.reasons.map(
                  ({ enabled, description, code }) =>
                    enabled && (
                      <Option key={code} value={description}>
                        {description}
                      </Option>
                    )
                )}
              </Select>
            )}
          </Form.Item>
        )}
        {returnFormFields && returnFormFields.reasonComments && (
          <Form.Item>
            {getFieldDecorator('reasonComments', {
              initialValue: formFields.reasonComments,
              rules: [
                {
                  required: returnFormFields.reasonCommentsMandatory,
                  message: whitelabeledRules.reasonCommentsRequiredText,
                },
                {
                  validator: (
                    rule: any,
                    value: string,
                    callback: (error?: Error) => void
                  ) => {
                    validateOnSpecialCharacters(rule, value, callback);
                  },
                },
              ],
            })(
              <TextArea
                rows={3}
                placeholder={reasonsCommentsPlaceholder}
                onFocus={this.handleInputClick('reasonComments')}
                onBlur={this.handleInputBlur('reasonComments')}
              />
            )}
          </Form.Item>
        )}
        <Form.Item>
          <InputLabel htmlFor="fullName">Full name*</InputLabel>
          {getFieldDecorator('fullName', {
            initialValue: formFields.fullName,
            rules: whitelabeledRules.fullName,
          })(
            <Input
              placeholder="Enter your full name"
              onFocus={this.handleInputClick('fullName')}
              onBlur={this.handleInputBlur('fullName')}
            />
          )}
        </Form.Item>
        <Form.Item>
          <InputLabel htmlFor="addressLine1">Address*</InputLabel>
          {getFieldDecorator('addressLine1', {
            initialValue: formFields.addressLine1,
            rules: whitelabeledRules.addressLine1,
          })(
            <Input
              placeholder="Address line 1"
              onFocus={this.handleInputClick('addressLine1')}
              onBlur={this.handleInputBlur('addressLine1')}
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('addressLine2', {
            initialValue: formFields.addressLine2,
            rules: whitelabeledRules.addressLine2,
          })(
            <Input
              placeholder="Address line 2"
              onFocus={this.handleInputClick('addressLine2')}
              onBlur={this.handleInputBlur('addressLine2')}
            />
          )}
        </Form.Item>
        <Form.Item>
          <InputLabel htmlFor="city">{`${cityText}*`}</InputLabel>
          {getFieldDecorator('city', {
            initialValue: formFields.city,
            rules: whitelabeledRules.city,
          })(
            <Input
              placeholder={cityPlaceholder}
              onFocus={this.handleInputClick('city/suburb')}
              onBlur={this.handleInputBlur('city/suburb')}
            />
          )}
        </Form.Item>
        <RowWrapper>
          <Form.Item>
            <InputLabel htmlFor="postcode">{`${zipCodeText}*`}</InputLabel>
            {getFieldDecorator('postcode', {
              initialValue: formFields.postcode,
              rules: [
                {
                  required: true,
                  message: `${zipCodeText} is required.`,
                },
                {
                  pattern: zipPattern,
                  message: zipError,
                },
                {
                  validator: (
                    rule: any,
                    value: string,
                    callback: (error?: Error) => void
                  ) => {
                    validateOnSpecialCharacters(rule, value, callback);
                  },
                },
              ],
            })(
              <Input
                placeholder={zipCodePlaceholder}
                maxLength={zipCodeLength}
                onFocus={this.handleInputClick('postcode')}
                onBlur={this.handleInputBlur('postcode')}
              />
            )}
          </Form.Item>
          <Form.Item>
            <InputLabel htmlFor="state">Select state*</InputLabel>
            {getFieldDecorator('state', {
              initialValue: formFields.state,
              rules: [
                {
                  required: true,
                },
              ],
            })(
              <Select
                placeholder="State"
                onFocus={this.handleInputClick('state')}
                onBlur={this.handleInputBlur('state')}
                onSelect={this.handleSelect}
                onClick={this.handleDropdownClick('state')}
              >
                {countryStates.map(item => (
                  <Option
                    key={item.value}
                    value={item.value}
                    title={`${item.label} (${item.value})`}
                  >
                    {getStateLabel(item)}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </RowWrapper>
        {returnFormFields && returnFormFields.phoneNumber && (
          <Form.Item>
            <InputLabel htmlFor="phoneNumber">Phone number*</InputLabel>
            {getFieldDecorator('phoneNumber', {
              initialValue: formFields.phoneNumber,
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter a contact phone number',
                },
                {
                  validator: (
                    rule: any,
                    value: string,
                    callback: (error?: Error) => void
                  ) => {
                    validateOnSpecialCharacters(rule, value, callback);
                  },
                },
                {
                  validator: validatePhoneNumber,
                },
              ],
            })(
              <Input
                placeholder="12 3456 78910"
                onFocus={this.handleInputClick('phoneNumber')}
                onBlur={this.handleInputBlur('phoneNumber')}
              />
            )}
          </Form.Item>
        )}
        <CheckboxFormItemContainer>
          <Form.Item>
            <CheckboxWrapper>
              {getFieldDecorator('confirmTerms', {
                initialValue: formFields.confirmTerms,
                valuePropName: 'checked',
                rules: [
                  {
                    required: true,
                    type: 'boolean',
                    transform: value => value || undefined,
                  },
                ],
              })(<Checkbox onChange={this.handleCheckboxChange} />)}
              <Terms>
                I agree to the{' '}
                <a target="_blank" rel="noopener noreferrer" href={tcUrl}>
                  Terms & Conditions
                </a>{' '}
                of using this service
              </Terms>
            </CheckboxWrapper>
          </Form.Item>
        </CheckboxFormItemContainer>
        <ConfirmButton htmlType="submit">Submit</ConfirmButton>
        <BackButton onClick={this.goBack}>Back</BackButton>
      </Form>
    );
  };

  renderNonIntegratedDetails = () => (
    <LeftSectionContent>
      <Title ref={this.pageTop}>Details</Title>
      {this.renderForm()}
    </LeftSectionContent>
  );

  /**
   * Handle confirming a return. Ensures we don't allow continuing if there are
   * any errors present. Logs some data to amplitude as well if we get
   * errors or succeed
   *
   * @param e
   */
  handleReturnConfirmation = (e: SyntheticEvent<any>) => {
    e.preventDefault();

    const {
      match: {
        params: { company },
      },
      form: { validateFieldsAndScroll, setFields },
      history: { push },
      detailsPageStore: { enableFormErrorMessage },
      whiteLabeled: { addressInvalidMsg },
    } = this.props;

    validateFieldsAndScroll((errors, values) => {
      this.logFieldEvent('click confirm', null);

      // If we have errors, don't allow passing through
      if (errors) {
        amplitude.logValidationErrors(errors, DetailsPage.PAGE_NAME);
        return enableFormErrorMessage(getArrayOfErrors(errors, 'DETAILS')[0]);
      }

      try {
        enableFormErrorMessage(false);

        amplitude.logEventWithOrganisationAndUrl('Book my return clicked');

        // TODO: CHECK why there wasn't a values yes??
        commonStoresActions.saveToStorage(MOBX_STORES.DetailsPageStore, null);
        push(
          `/${company}/${
            commonStoresActions.isPaymentsRequiredForIntegrated()
              ? 'payment'
              : 'success'
          }`
        );
      } catch {
        enableFormErrorMessage(addressInvalidMsg);
        amplitude.logValidationErrors(
          getErrorsOfAddressValidation(values),
          DetailsPage.PAGE_NAME
        );
        setFields(getErrorsOfAddressValidation(values));
      }
    });
  };

  renderIntegratedDetails = () => {
    const {
      form,
      orderStore: { orderNumber, products, isIntegratedFlow },
      detailsPageStore: { showFormErrorMessage, isAnyProductSelectedForReturn },
    } = this.props;
    return (
      <Form>
        <Progressbar
          percent={33 * (Number(isAnyProductSelectedForReturn) + 1)}
          strokeWidth={6}
          showInfo={false}
        />
        <LeftSectionContent isIntegratedFlow={isIntegratedFlow}>
          <OrderTitle>Return details </OrderTitle>
          <OrderNumber>Order #{orderNumber}</OrderNumber>
          <SubTitle>Choose item to return</SubTitle>
          {products.map(product => (
            <ReturnItem
              form={form}
              key={product.productId}
              product={product}
              onInputClick={this.handleInputClick}
              onInputBlur={this.handleInputBlur}
            />
          ))}
        </LeftSectionContent>
        <IntegratedButtonsBlock>
          <FormErrorMessage hidden={!showFormErrorMessage}>
            {showFormErrorMessage}
          </FormErrorMessage>
          <ConfirmButton
            accentcolor="#38b13a"
            disabled={!isAnyProductSelectedForReturn}
            onClick={this.handleReturnConfirmation}
          >
            Book my return
          </ConfirmButton>
          <BackButton onClick={this.goBack}>Back</BackButton>
        </IntegratedButtonsBlock>
      </Form>
    );
  };

  renderLoading = () => <LoadingMessage>Loading results…</LoadingMessage>;

  renderLeftSectionContent = () => {
    const {
      orderStore: { isIntegratedFlow, asyncStatus },
      directoryStore: { status },
    } = this.props;

    if (asyncStatus === AsyncStatus.LOADING || status === AsyncStatus.LOADING) {
      return this.renderLoading();
    }
    return isIntegratedFlow
      ? this.renderIntegratedDetails()
      : this.renderNonIntegratedDetails();
  };

  render() {
    const {
      themeStore: { widthOfBackgroundImage },
    } = this.props;

    return (
      <DetailsPageWrapper>
        <Layout.Container>
          <LeftSection>{this.renderLeftSectionContent()}</LeftSection>
          <Layout.RightSection widthOfBackgroundImage={widthOfBackgroundImage}>
            <RightSectionImage />
          </Layout.RightSection>
        </Layout.Container>
      </DetailsPageWrapper>
    );
  }
}

export default compose(
  inject('orderStore', 'detailsPageStore', 'directoryStore', 'themeStore'),
  withWhitelabelProps({
    cityText: 'ui.pages.details.textValues.cityLabel',
    cityPlaceholder: 'ui.pages.details.textValues.cityPlaceholder',
    zipCodeText: 'ui.pages.details.textValues.zipText',
    zipCodeLength: 'ui.pages.details.textValues.zipLength',
    zipCodePlaceholder: 'ui.pages.details.textValues.zipPlaceholder',
    zipPattern: 'ui.pages.details.textValues.zipPattern',
    zipError: 'ui.pages.details.textValues.zipError',
    tcUrl: 'ui.pages.details.textValues.tcUrl',
    countryStates: 'ui.pages.details.textValues.countryStates',
    addressInvalidMsg: 'ui.pages.details.textValues.addressInvalidMsg',
    whitelabeledRules: 'ui.pages.details.rules',
    getStateLabel: 'ui.pages.details.getStateLabel',
    showReturnItems: 'ui.pages.details.showReturnItems',
  }),
  Form.create<Props>({
    onFieldsChange(props, fields) {
      const keys = Object.keys(fields);
      if (!keys.length) {
        return;
      }

      // @ts-ignore
      const { name, value, errors, validating } = fields[keys[0]];
      // do not change state on field validating event
      if (validating) return;

      props.detailsPageStore.setFormField(
        name,
        value,
        errors,
        props.form.setFieldsValue
      );
    },
  })
)(DetailsPage);

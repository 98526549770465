import styled from 'styled-components';

export const StyledpostLogo = styled.div`
  width: 110px;
  position: relative;
  bottom: -40px;
  margin: 30px auto 20px;

  @media only screen and (max-width: 768px) {
    width: 90px;
    margin-top: 40px;
    margin-bottom: 40px;
    bottom: 0;
  }
`;

import React from 'react';
import { Redirect } from 'react-router';
import { Route } from 'react-router-dom';
import {
  localStore as storage,
  sessionStore,
  SUBMITTED_STEPS,
  MOBX_STORES,
  SECURE_FRAME_FINGERPRINT,
} from 'storage';

import { endsWith, includes } from 'lodash';

interface RedirectConfig {
  canActivate: boolean;
  redirectURL: null | string;
}
const getRedirectConfig = (props: any): RedirectConfig => {
  const config = {
    canActivate: true,
    redirectURL: null,
  };

  /** Validate access to details page */
  if (endsWith(props.location.pathname, '/details')) {
    const canActivate = includes(
      sessionStore.get(SUBMITTED_STEPS),
      MOBX_STORES.StartPageStore
    );
    if (!canActivate) {
      config.canActivate = canActivate;
      config.redirectURL = `/${props.location.pathname.split('/')[1]}`;
    }
  }

  /** Validate access to payments page */
  if (endsWith(props.location.pathname, '/payment')) {
    /** Comment this section for debugging payments page! */
    const canActivate = includes(
      sessionStore.get(SUBMITTED_STEPS),
      MOBX_STORES.DetailsPageStore
    );
    if (!canActivate) {
      config.canActivate = canActivate;
      config.redirectURL = `/${props.location.pathname.split('/')[1]}/details`;
    }
  }

  /** Validate access to success page */
  if (endsWith(props.location.pathname, '/success')) {
    /** Success page has internal validation of fingerprint, if user redirected after SecureFrame.
     * Need to check here case when payment not required - DetailsPageStore */
    const canActivate = includes(
      sessionStore.get(SUBMITTED_STEPS),
      MOBX_STORES.DetailsPageStore
    );

    if (!canActivate) {
      const backUrl = storage.get(SECURE_FRAME_FINGERPRINT)
        ? '/payment'
        : '/details';
      config.canActivate = canActivate;
      config.redirectURL = `/${
        props.location.pathname.split('/')[1]
      }${backUrl}`;
    }
  }

  return config;
};

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const renderComponent = (props: any) => {
    const config = getRedirectConfig(props);
    return config.canActivate ? (
      <Component {...props} {...rest} />
    ) : (
      <Redirect to={config.redirectURL} />
    );
  };

  return <Route exact render={renderComponent} />;
};

export default PrivateRoute;

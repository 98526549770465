import styled from 'styled-components';
import { mapBackground } from 'assets';

export const MapBackground = styled.div<{ integratedFlow: boolean }>`
  background: url(${mapBackground}) center;
  width: 100%;
  height: ${({ integratedFlow }) =>
    integratedFlow ? 'calc(100vh - 170px)' : 'calc(100vh - 270px)'};
  background-size: cover;
`;

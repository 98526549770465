import { action, observable } from 'mobx';
import { IPaymentPageStore } from 'types/mobxStores';

class PaymentPageStore implements IPaymentPageStore {
  @observable
  formFields = {
    cardNumber: null,
    expiryMM: undefined,
    expiryYY: undefined,
    ccv: null,
  };

  @action
  setFormField = (field: string, value: string) => {
    this.formFields[field] = value;
  };
}

export default PaymentPageStore;

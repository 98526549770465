export default {
  index: '/',
  root: '/:company',
  notFound: '/not-found',

  details: '/:company/details',
  payment: '/:company/payment',
  success: '/:company/success/:fingerprint?',
  tracking: '/:company/tracking',
  ratings: '/:company/ratings',
};

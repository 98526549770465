import React, { Component } from 'react';
import { compose } from 'recompose';

import { Layout } from '_common/components';
import {
  PrintSection,
  SubText,
  Title,
  SubTitle,
  StoreListWrapper,
  StoresListError,
} from '../elements';
import { AsyncStatus } from '_common/constants/common';
import DesktopMap from './DesktopMap';
import StoreList from './StoreList/StoreList';
import MobileMap from './MobileMap/MobileMap';
import { withWhitelabelProps } from '_common/whitelabelConfig';

interface Props {
  labelLoading: any;
  successButton: any;
  retryButton: any;
  whiteLabeled: any;
  consumerPaymentWasSuccessful: any;
  orderCreateSuccess: any;
  onStoreClick: any;
  loaderText: any;
  onMapInit: any;
  geo: any;
}

class NonIntegratedFlowUI extends Component<Props> {
  getConfig = () => {
    const {
      labelLoading,
      successButton,
      retryButton,
      whiteLabeled: { errorText, getSuccessMessage, getSuccessSubtitle },
      consumerPaymentWasSuccessful,
    } = this.props;

    switch (labelLoading) {
      case AsyncStatus.FAILED:
        return {
          title: <Title>Error creating return.</Title>,
          button: retryButton(),
          subtitle: <SubText>{errorText}</SubText>,
        };
      case AsyncStatus.SUCCESS: {
        const { main, secondary } = getSuccessMessage(
          consumerPaymentWasSuccessful
        );
        const subtitle = getSuccessSubtitle(consumerPaymentWasSuccessful);

        return {
          title: (
            <>
              <Title>{main}</Title>
              {secondary && <SubTitle>{secondary}</SubTitle>}
            </>
          ),
          button: successButton(),
          subtitle: subtitle && <SubText>{subtitle}</SubText>,
        };
      }
      case AsyncStatus.LOADING:
        return {
          title: <Title>Loading results…</Title>,
          button: successButton(),
        };
      default:
        // invalid state.
        console.error('getConfig error! Invalid loading status provided');
        return {};
    }
  };

  renderMainBlock = config => {
    const hasError = this.props.labelLoading === AsyncStatus.FAILED;
    return (
      <>
        <MobileMap orderCreateSuccess={this.props.orderCreateSuccess} />
        {hasError ? (
          <StoresListError>{config.title}</StoresListError>
        ) : (
          <StoreListWrapper>
            <StoreList onStoreClick={this.props.onStoreClick} />
          </StoreListWrapper>
        )}
      </>
    );
  };

  renderLeftSectionContent = config => {
    const { labelLoading, loaderText } = this.props;
    return (
      <>
        <PrintSection>
          {config.title}
          {config.button}
          {config.subtitle}
        </PrintSection>

        {labelLoading === AsyncStatus.LOADING
          ? loaderText('Loading results...')
          : this.renderMainBlock(config)}
      </>
    );
  };

  render() {
    const { labelLoading, onMapInit, orderCreateSuccess } = this.props;
    const config = this.getConfig();
    return (
      <>
        <Layout.Container>
          <Layout.LeftSectionNotResponsive>
            {this.renderLeftSectionContent(config)}
          </Layout.LeftSectionNotResponsive>
          <Layout.RightSectionNotResponsive>
            <DesktopMap
              currentGeo={this.props.geo}
              onMapInit={onMapInit}
              labelLoading={labelLoading === AsyncStatus.LOADING}
              orderCreateSuccess={orderCreateSuccess}
            />
          </Layout.RightSectionNotResponsive>
        </Layout.Container>
      </>
    );
  }
}

export default compose(
  withWhitelabelProps({
    errorText: 'ui.pages.success.textValues.errorStatusText',
    getSuccessMessage: 'ui.pages.success.textValues.getSuccessMessage',
    getSuccessSubtitle: 'ui.pages.success.textValues.getSuccessSubtitle',
  })
)(NonIntegratedFlowUI);

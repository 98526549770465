import React from 'react';
import { Wrapper } from './LoaderArcElements';

export default props => (
  <Wrapper>
    <div />
    <div />
    <div />
    <div />
    <span>{props.displayText || 'Loading...'}</span>
  </Wrapper>
);

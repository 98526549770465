import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 39px;
  display: flex;
  height: 100px;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    align-items: center;
    justify-content: flex-start;
    padding: 20px 30px 0 20px;
    height: 70px;
    margin-bottom: 20px;
  }
`;

export const CustomerLogo = styled.img`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  align-self: center;
  margin-right: 30px;
  display: block;

  @media only screen and (max-width: 768px) {
    height: auto;
    width: 150px;
    display: none;
  }
`;

export const BackButton = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
  width: 40px;
  height: 40px;
  display: none;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    display: flex;
    left: 10px;
    top: 17px;
  }
`;

export const LogoWrapper = styled.div`
  position: relative;
  display: flex;
  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
`;

/* eslint camelcase: 0 */

import { authClient } from '_common/api/clients/clients';
import qs from 'qs';
import { MERCHANT_ORGANISATION, ROOT_ORG_ID } from '_common/constants/common';
import { IApplicationTokenResponse } from 'types/token';

const PERMISSIONS = [
  `organisation_${MERCHANT_ORGANISATION}`,
  `organisation_${ROOT_ORG_ID}`, // need for calling /v2/orders/externalorderid for AP
  'asset-management:read',
  'companies:read',
  'orders:read', // for order lookup for integrated journey
  'stores:read',
  'tracking_public-read',
  'ratings:write',
  'returns_portal_service:app:client', // generic scope for returns-portal-service
];

const authorizeApplication = async <
  T extends IApplicationTokenResponse
>(): Promise<T> => {
  const body = qs.stringify({
    grant_type: 'client_credentials',
    scope: PERMISSIONS.join(' '),
  });

  const response = await authClient.post('/v1/oauth/token', body);

  return response.data;
};

const refreshToken = async (refreshTokenKey: string): Promise<any> => {
  const body = qs.stringify({
    grant_type: 'refresh_token',
    refresh_token: refreshTokenKey,
  });

  const response = await authClient.post('/v1/oauth/token', body);
  return response.data;
};

export default {
  authorizeApplication,
  refreshToken,
};

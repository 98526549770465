import { apiClient } from '_common/api/clients/clients';
import { IOrderServerResponse } from 'types/order';
import { ROOT_ORG_ID } from '_common/constants/common';

const getOrdersByExternalId = async (
  externalOrderId: string,
  company: string,
  email: string
): Promise<IOrderServerResponse> => {
  const response = await apiClient.get(
    `/v1/returns-portal-service/${ROOT_ORG_ID}/get-order/${externalOrderId}/company/${company}/email/${email}`
  );
  return response.data;
};

export default {
  getOrdersByExternalId,
};

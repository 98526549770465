import { Input } from 'antd';
import * as React from 'react';
import styled, { AnyStyledComponent } from 'styled-components';

const { TextArea } = Input;

export const StyledBaseInput: AnyStyledComponent = styled(
  ({ innerRef, ...rest }) => <Input {...rest} />
)`
  & {
    border: solid 1px #e8e8e8 !important;
    border-radius: 3px;
    font-family: ${props => props.theme.fonts.basic};
    font-weight: 300;
    font-size: 16px !important;
    color: #000 !important;
    width: 100%;

    &::placeholder {
      font-weight: 300;
      font-family: ${props => props.theme.fonts.basic};
      text-align: left;
      font-size: 16px !important;
      color: #000 !important;
      opacity: 0.5 !important;
    }
  }
`;

export const StyledBaseTextArea: AnyStyledComponent = styled(
  ({ innerRef, ...rest }) => <TextArea {...rest} />
)`
  & {
    border: solid 1px #e6e7e8;
    border-radius: 3px;
    font-family: ${props => props.theme.fonts.basic};
    font-weight: 300;
    font-size: 16px;
    color: #000 !important;
    width: 100%;

    &::placeholder {
      font-weight: 300;
      font-family: ${props => props.theme.fonts.basic};
      text-align: left;
      font-size: 16px;
      color: #000 !important;
      opacity: 0.5;
    }
  }
`;

export const StyledInput: AnyStyledComponent = styled(StyledBaseInput)`
  && {
    min-height: 48px;
  }
`;

export const StyledTextArea: AnyStyledComponent = styled(StyledBaseTextArea)`
  && {
    min-height: 48px;
  }
`;

export const SearchInputStyles = styled(Input.Search)`
  .ant-input {
    line-height: 60px;
    height: 60px;

    &::placeholder {
      font-weight: 300;
      font-family: ${props => props.theme.fonts.basic};
      text-align: left;
      font-size: 16px;
      color: #231f20;
      opacity: 0.5;
    }
  }

  .ant-btn.ant-input-search-button.ant-btn-primary {
    height: 60px;
    background-color: ${(props: any) =>
      props['disabled-search'] ? '#c7c7cc' : '#4a90e2'};
    font-family: ${props => props.theme.fonts.basic};
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: initial;
  }

  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 50px;
  }
  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 50px;
  }

  @media only screen and (max-width: 768px) {
    .ant-input-group-addon {
      display: none;
    }
  }

  && {
    font-family: ${props => props.theme.fonts.basic};
    font-weight: 300;
    font-size: 16px;
    color: #000;
    width: 100%;
  }
`;

import {
  removeUnsupportedPropsFromProduct,
  getShipmentConfig,
} from '../utils/index';
import saveToStorage from './saveToStorage';
import validateSession from './validateSession';
import getAccountNumberForHeader from './getAccountNumberForHeader';
import resetStoresWithUserData from './resetStoresWithUserData';
import isPaymentsRequiredForIntegrated from './isPaymentsRequiredForIntegrated';
import getProductJourneyType from './getProductJourneyType';
import { TSPResult } from '../components/AustraliaPost/SecureFrame/SecureFrame';

import amplitude from '../utils/amplitude';
import { IMobXStores } from 'types/mobxStores';
import { ICommonStoresActions, IProductJourneyType } from 'types/core';
import { IReturnFormFields } from 'types/company';

export class CommonActions implements ICommonStoresActions {
  stores: IMobXStores | null = null;

  registerStores = (stores: IMobXStores) => {
    /**
     * With that concept - 'this.stores' can be empty before register called.
     * Dont wrap in promise/catch - need to get an error */
    this.stores = stores;
  };

  loadTheme = () => this.stores.themeStore.makeThemeRequest();

  logout = () => this.stores.authStore.logout();

  refreshToken = () => this.stores.authStore.getNewAccessToken();

  getCompany = (companyName: string) =>
    this.stores.directoryStore.getCompany(companyName);

  createShipment = (company: string) => {
    const { companyConfig } = this.stores.directoryStore;
    const { isIntegratedFlow, products, userInfo } = this.stores.orderStore;
    const { securePayData } = this.stores.whitelabelProxyStore;
    const populatedOrders = products
      .map(this.stores.detailsPageStore.populateOrderWithReturnReasons)
      .map(removeUnsupportedPropsFromProduct)
      .filter(Boolean);
    let config;
    try {
      config = getShipmentConfig({
        isIntegratedFlow,
        detailsPageStoreFormFields: {
          ...this.stores.detailsPageStore.formFields,
        },
        startPageStoreFormFields: { ...this.stores.startPageStore.formFields },
        companyConfig: { ...companyConfig },
        securePayData,
        intergratedUserProducts: populatedOrders,
        intergratedOrderInfo: userInfo,
      });
    } catch (e) {
      return Promise.reject(e);
    }

    return this.stores.whitelabelProxyStore.createShipment(config, company);
  };

  getTrackingInfo = (trackingId: string) =>
    this.stores.trackingStore.getTrackingEvents(trackingId);

  saveToStorage = (storeName: string, model: object) =>
    saveToStorage(storeName, model);

  validateAddress = (address: any) =>
    this.stores.whitelabelProxyStore.validateAddress(address);

  validateSession = (
    merchantId: string,
    resetAfterOrdersSubmit: boolean = false
  ) => validateSession(merchantId, resetAfterOrdersSubmit);

  getLocationTypes = () => this.stores.directoryStore.locationTypesOptions;

  getAccountNumberForHeader = (): string =>
    getAccountNumberForHeader(this.stores);

  resetStoresWithUserData = () => resetStoresWithUserData(this.stores);

  setConsumerPaymentWasSuccessful = (paymentDetails: TSPResult) =>
    this.stores.whitelabelProxyStore.setConsumerPaymentWasSuccessful(
      paymentDetails
    );

  setDetailsFormField = (field, value) =>
    this.stores.detailsPageStore.setFormField(field, value);

  saveDetailsFormToStorage = () =>
    this.stores.detailsPageStore.saveFormToStorage();

  getOrderById = (company: string): Promise<void> => {
    const { orderNumber, email } = this.stores.startPageStore.formFields;
    return this.stores.orderStore.getOrderById(orderNumber, company, email);
  };

  getJourneyType = (): string =>
    this.stores.orderStore.isIntegratedFlow
      ? 'Integrated journey'
      : 'Non-integrated journey';

  isPaymentsRequiredForIntegrated = (): boolean =>
    isPaymentsRequiredForIntegrated(this.stores);

  getProductJourneyType = (): IProductJourneyType =>
    getProductJourneyType(this.stores);

  getPurchaseWarrantyPeriod = (): number =>
    this.stores.directoryStore.purchaseWarrantyPeriodDays;

  getReturnFormFields = (): IReturnFormFields =>
    this.stores.directoryStore.returnFormFields;

  searchStoresByCoords = (
    config: { lat: number; lng: number; company?: string },
    onDrag?,
    distance?
  ) => this.stores.locationStore.searchStoresByCoords(config, onDrag, distance);

  searchStoresNearMe = (company: string) =>
    this.stores.locationStore.searchStoresNearMe(company);

  setActiveStoreId = (storeId: string) => {
    amplitude.logEventWithOrganisationAndUrl('selected store', { storeId });
    return this.stores.locationStore.setActiveStoreId(storeId);
  };

  setLocationType = (locationType: string) => {
    amplitude.logEventWithOrganisationAndUrl('selected location type filter', {
      locationType,
    });
    return this.stores.locationStore.setLocationType(locationType);
  };

  searchFromLastLocation = (isNewLocation?: boolean) =>
    this.stores.locationStore.searchFromLastLocation(isNewLocation);

  setSearchGeoCoordinates = (coords: any) =>
    this.stores.locationStore.setSearchGeoCoordinates(coords);
}

export default new CommonActions();

import { action, computed, observable, runInAction } from 'mobx';
import { get, find } from 'lodash';

import { CURRENCY_SYMBOLS } from '_common/constants/common';
import OrdersService from '_common/services/ordersService';
import moment from 'moment';
import {
  IOrder,
  IOrderProductModel,
  IUserInfo,
  IOrderServerResponse,
  IOrderProduct,
} from 'types/order';
import { EAsyncStatus, ICommonStoresActions } from 'types/core';
import { IOrderStore } from 'types/mobxStores';
import { WhiteLabelUtils } from '_common/whitelabelConfig';
import { ORDER_ERRORS } from '_common/constants/orders';

const API_PURCHASE_DATE_FORMAT = 'DD-MM-YYYY';

const ORDERTYPE_STATUS = {
  RETURNED: 'INTERNAL',
  NOT_RETURNED: 'EXTERNAL',
};

class OrderStore implements IOrderStore {
  @observable
  orderData: IOrder | null = null;

  @observable
  orderNumber: string | null = null;

  @observable
  asyncStatus: EAsyncStatus = EAsyncStatus.IDLE;

  @observable
  returnedProducts: Map<string, string> = new Map();

  commonStoresActions: ICommonStoresActions;

  registerCommonActions = (commonStoresActions: ICommonStoresActions) => {
    this.commonStoresActions = commonStoresActions;
  };

  @computed
  get isIntegratedFlow(): boolean {
    return Boolean(this.orderData);
  }

  @computed
  get products(): IOrderProductModel[] {
    if (!this.orderData) return [];
    const { orderLines } = this.orderData;
    if (!orderLines || orderLines.length <= 0) {
      return [];
    }
    // fallback to pounds for AP orders, that don't provide currency property
    return orderLines.map(({ product, priceCurrency = 'GBP' }) => ({
      ...product,
      priceCurrency: get(CURRENCY_SYMBOLS, priceCurrency),
    }));
  }

  @computed
  get userInfo(): IUserInfo | object {
    if (!this.orderData) return {};
    const firstName = get(this.orderData, 'customer.name.firstName', '');
    const lastName = get(this.orderData, 'customer.name.lastName', '');
    const orderDate = Array.isArray(this.orderData.eventHistory)
      ? this.orderData.eventHistory[0].dateTime
      : null;
    const fullName =
      firstName || lastName ? `${firstName} ${lastName}`.trim() : null;
    const addressFields = get(
      this.orderData,
      'externalOrderData.deliveryAddress',
      {}
    );
    return {
      phoneNumber: get(this.orderData, 'customer.mobileNumber', null),
      name: fullName,
      orderDate,
      ...addressFields,
    };
  }

  /**
   * Take the order response and ensure that the product ID is unique, solving
   * issues around multiple products with the same ID being used at once.
   * Will also set a product ID to the product if it doesn't have one
   * in the first place
   *
   * @param response
   */
  @action
  addUIDtoOrderLinesProduct = (response: IOrderServerResponse) => {
    response.resources.forEach((order: IOrder) => {
      if (order.orderLines) {
        order.orderLines.forEach((ol, index) => {
          // eslint-disable-next-line no-param-reassign
          ol.product.productId = this.createUIDFromProduct(ol.product, index);
        });
      }
    });
  };

  /**
   * Takes a product and creates a unique repeatable product ID based on data
   * from the product itself. Takes the index in case we have multiple
   * of the exact same product in an order lines object
   *
   * @param product
   * @param index
   */
  createUIDFromProduct = (product: IOrderProduct, index: number): string =>
    // eslint-disable-next-line
    `${get(product, 'productId', 'productId')}-${get(product, 'sku', 'sku')}-${get(product, 'name', 'name')}-${get(
      product,
      'size',
      'size'
    )}-${get(product, 'colour', 'colour')}-${index}`;

  @action
  checkOrderDateIsValid = (orderPurchaseDate: string): boolean => {
    const purchaseDateValidationDisabled = !this.commonStoresActions.getReturnFormFields()
      .purchaseDate;
    const companyWarrantyPeriod = this.commonStoresActions.getPurchaseWarrantyPeriod();
    /**
     * check only if there's date, warrantyDays provided and
     * product integrated company (ensure only USPS change)
     */

    if (
      purchaseDateValidationDisabled ||
      !orderPurchaseDate ||
      typeof companyWarrantyPeriod !== 'number' ||
      !this.commonStoresActions.getProductJourneyType().INTEGRATED
    )
      return true;
    if (
      moment(orderPurchaseDate, API_PURCHASE_DATE_FORMAT) <
      moment().subtract(companyWarrantyPeriod + 1, 'days')
    ) {
      runInAction(() => {
        this.asyncStatus = EAsyncStatus.FAILED;
        this.orderData = null;
        this.orderNumber = null;
      });
      return false;
    }
    return true;
  };

  /**
   * Get the returned status of a product by checking the map
   * of returned products against a product ID of the same product
   *
   * @param product
   */
  getReturnedStatusForProduct = (product: IOrderProductModel): string =>
    this.returnedProducts.get(product.productId);

  @action
  clearReturnedProducts = (): void => {
    this.returnedProducts.clear();
  };

  @action
  getNotReturnedOrder = (response: IOrderServerResponse): IOrder => {
    const notReturnedOrders: IOrder[] = [];

    response.resources.forEach((order: IOrder) => {
      if (
        WhiteLabelUtils.checkForReturnedProducts &&
        order.orderType === ORDERTYPE_STATUS.RETURNED
      ) {
        order.orderLines.forEach(ol => {
          /**
           * Set the returned item to the product ID as we will always
           * have one, created, or otherwise
           */
          this.returnedProducts.set(
            ol.product.productId,
            ORDER_ERRORS.RETURNED_PRODUCT_ERROR
          );
        });
      }
      if (order.orderType === ORDERTYPE_STATUS.NOT_RETURNED) {
        notReturnedOrders.push(order);
      }
    });
    return notReturnedOrders[0];
  };

  @action
  getOrderById = async (orderNumber, company, email) => {
    if (!orderNumber) {
      this.orderData = null;
      return Promise.reject(Error('orderNumber not provided.'));
    }

    /** For case success page -> details redirect after flow check. */
    if (orderNumber === this.orderNumber) {
      return Promise.resolve();
    }

    try {
      this.asyncStatus = EAsyncStatus.LOADING;
      this.orderNumber = orderNumber;
      const response = await OrdersService.getOrdersByExternalId(
        orderNumber,
        company,
        email
      );

      // Ensure that we have a unique product ID
      this.addUIDtoOrderLinesProduct(response);

      const orderData = WhiteLabelUtils.checkForReturnedProducts
        ? this.getNotReturnedOrder(response)
        : response.resources[0];
      // validate order date
      const orderPurchaseDate = get(
        orderData,
        'externalOrderData.purchaseDate'
      );
      if (!this.checkOrderDateIsValid(orderPurchaseDate)) {
        return Promise.reject(
          Error(
            'Your order was placed outside of our returns window. Please contact customer services.'
          )
        );
      }

      runInAction(() => {
        this.orderData = orderData;
        this.asyncStatus = EAsyncStatus.SUCCESS;
      });

      return Promise.resolve();
    } catch (e) {
      runInAction(() => {
        this.asyncStatus = EAsyncStatus.FAILED;
        this.orderData = null;
        this.orderNumber = null;
      });
      return Promise.reject(Error('Order not found'));
    }
  };

  @action
  updateIntegratedComment = (value: string, productId: string) => {
    const newOrderData = { ...this.orderData };
    const selectedOrderLine = find(
      newOrderData.orderLines,
      orderLine => orderLine.product.productId === productId
    );
    selectedOrderLine.product.reasonComments = value;

    this.orderData = newOrderData;
  };
}

export default OrderStore;

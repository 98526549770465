import styled, { createGlobalStyle } from 'styled-components';

export const DatePickerWrapper = styled.div`
  position: relative;
  .ant-calendar-picker {
    width: 100%;
  }

  .ant-calendar-picker-input {
    height: 48px;
    color: #000000;
  }

  .ant-calendar-picker-input {
    ::placeholder {
      font-family: Arial, sans-serif;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0;
      color: #000000;
      opacity: 0.5;
    }
  }

  & svg {
    cursor: pointer;
  }
`;

export const DatePickerError = styled.span`
  color: #d0021b;
  font-size: 12px;
  letter-spacing: 0;
  position: absolute;
  top: 53px;
  left: 0;
  width: 100%;
  word-wrap: break-word;
  line-height: 12px;
`;

export const DatePickerDropdownStyles = createGlobalStyle<{ width: number }>`
  .rpc-datepicker {
    & .ant-calendar  {
      ${({ width }) => width && `width: ${width}px;`};
      & .ant-calendar-cell {
        height: 34px;
      }
    }
  }
`;

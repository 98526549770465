/* eslint camelcase: 0 */
// eslint-disable-next-line max-classes-per-file
import links from '_common/routes/urls';
import { withRouter } from 'react-router-dom';
import React from 'react';
import { CancelToken, CancelTokenSource } from 'axios';

// eslint-disable-next-line import/no-cycle
import {
  PAYMENT_TYPES,
  DEFAULT_SPECIAL_CHARS_REGEX,
  RETURNS_PORTAL_BUILD_NAME,
} from '_common/constants/common';
import { includes } from 'lodash';
import { AUS_ADDRESS_FIELD_INVALID } from '_common/constants/apiErrorResponces';
import moment from 'moment';
import { WhiteLabelUtils, WhiteLabelConstants } from '_common/whitelabelConfig';

/**
 * Converts string to websafe format.
 * @param str
 * @returns {string}
 */
export const toWebSafeFormat = (str: string): string =>
  str
    .toUpperCase()
    .replace(/[^A-Z0-9_\s]/g, '')
    .trim()
    .replace(/\s+/g, '_');

/**
 * Parse current url and returns merchant's organisation.
 */
export const getMerchantFromUrl = () => {
  const existingRoutes = Object.values(links)
    .map(path => path.replace('/:company', '').split('/')[1])
    .filter(p => p);
  const [company] = window.location.pathname.split('/').filter(p => p);
  if (company && existingRoutes.indexOf(company) === -1) {
    return toWebSafeFormat(company);
  }
  console.error('invalid company name::', company);
};

/**
 * Return config for creating shipment.
 */
export const getShipmentConfig = ({
  isIntegratedFlow,
  detailsPageStoreFormFields,
  startPageStoreFormFields,
  companyConfig,
  securePayData,
  intergratedUserProducts,
  intergratedOrderInfo,
}) => {
  const { PRODUCT_NAME } = WhiteLabelConstants;
  const {
    fullName,
    addressLine1,
    addressLine2,
    city,
    postcode,
    state,
    confirmTerms,
    phoneNumber,
    reasonComments,
    returnReason,
    returnItems,
    purchaseDate,
  } = detailsPageStoreFormFields;
  const { email, orderNumber, ranNumber } = startPageStoreFormFields;
  const {
    // companyName,
    // contactName,
    companyId,
    returns,
    products: {
      [PRODUCT_NAME]: { returnCompanyName, departmentName, showOrderIdOnLabel },
    },
    phoneNumber: toPhoneNumber,
  } = companyConfig;
  const {
    area,
    line1,
    line2,
    postcode: toPostcode,
    town,
  } = returns.routings[0].address;

  return {
    companyId,
    orderNumber,
    appName: RETURNS_PORTAL_BUILD_NAME,
    additionalInfo: 'Return',
    ...(isIntegratedFlow
      ? {
          ...(WhiteLabelUtils.checkForReturnedProducts && {
            externalOrderId: orderNumber /** for integrated flow externalOrderId is responsible for check for returned products on BE  */,
          }),
          from: WhiteLabelUtils.convertFromAddressIntegrated(
            email,
            intergratedOrderInfo
          ),
          returns: WhiteLabelUtils.convertReturns({
            returnItems: `${intergratedUserProducts.length}`,
            purchaseDate: intergratedOrderInfo.orderDate
              ? moment(intergratedOrderInfo.orderDate).format('YYYY-MM-DD')
              : intergratedOrderInfo.orderDate,
            confirmTerms: true,
            returnReason: null,
            reasonComments: null,
            returnsAuthorisationNumber: ranNumber,
            isIntegrated: true,
          }),
          products: intergratedUserProducts,
        }
      : {
          ...(showOrderIdOnLabel && {
            externalOrderId: orderNumber /** for non-integrated flow externalOrderId is responsible for displayng orderId on label */,
          }),
          from: WhiteLabelUtils.convertFromAddress({
            postcode,
            phoneNumber,
            email,
            fullName,
            addressLine1,
            addressLine2,
            city,
            state,
          }),
          returns: WhiteLabelUtils.convertReturns({
            purchaseDate: purchaseDate
              ? moment(purchaseDate).format('YYYY-MM-DD')
              : purchaseDate,
            returnItems: `${returnItems}`,
            returnReason,
            confirmTerms,
            reasonComments,
            returnsAuthorisationNumber: ranNumber,
          }),
        }),
    to: WhiteLabelUtils.convertToAddress({
      line1,
      line2,
      town,
      area,
      returnCompanyName,
      departmentName,
      toPostcode,
      toPhoneNumber,
    }),
    ...(securePayData && {
      consumerPaymentWasSuccessful: securePayData.resultText === 'Approved',
      payment: {
        paymentDateTime: securePayData.timestamp,
        securePayData: securePayData,
      },
    }),
  };
};

export const validateOnSpecialCharacters = (
  _: any,
  value: string,
  callback: (error?: Error) => void,
  customRegex = DEFAULT_SPECIAL_CHARS_REGEX
) => {
  const error = new Error(customRegex.errorText);
  // eslint-disable-next-line
  const regexp = customRegex.regexValue;
  if (regexp.test(value)) {
    return callback(error);
  }
  return callback();
};

export const ScrollToTop = withRouter(
  class ScrollToTopWithoutRouter extends React.Component<any> {
    componentDidUpdate(prevProps: any) {
      if (this.props.location !== prevProps.location) {
        window.scrollTo(0, 0);
      }
    }

    render() {
      return null;
    }
  }
);

interface loadScriptParams {
  scriptId: string;
  successCallback: () => void;
  errorCallback: () => void;
  url: string;
  throttle: number;
  remainingAttempts: number;
}

/**
 * Trying to load script of 3-rd party library.
 * @param config
 */
export const loadDynamicScript = (config: loadScriptParams) => {
  const {
    scriptId,
    successCallback,
    throttle,
    url,
    remainingAttempts,
    errorCallback,
  } = config;
  if (remainingAttempts <= 0) {
    return errorCallback();
  }

  const existingScript = document.getElementById(scriptId);

  if (existingScript) {
    return successCallback();
  }

  const script = document.createElement('script');
  script.src = url;
  script.id = scriptId;
  document.body.appendChild(script);

  script.onload = () => successCallback();

  script.onerror = () => {
    document.body.removeChild(script);
    setTimeout(
      () =>
        loadDynamicScript({
          ...config,
          remainingAttempts: remainingAttempts - 1,
        }),
      throttle
    );
  };
};

/**
 * Validate phone number
 * @param _
 * @param phoneNumber
 * @param callback
 */
export const validatePhoneNumber = (
  _: any,
  phoneNumber: string,
  callback: (error?: Error) => void
) => {
  const error = new Error('Please enter a valid phone number');

  // contains invalid chars (0-9, whitespaces, '(', ')', '-', '+' are valid)
  if (/[^\d\-\s()+]/.test(phoneNumber) || phoneNumber.includes('--')) {
    return callback(error);
  }
  const formattedValue = phoneNumber.replace(/[-\s]/g, '');
  const digitsCount = (formattedValue.match(/\d/g) || []).length;
  const validLength = digitsCount >= 4 && digitsCount <= 15;

  // +XXXXXX or +XX(XXX)XXX
  if (
    validLength &&
    (/^\+?\d+$/.test(formattedValue) ||
      /^\+?\d*\(\d+\)\d+$/.test(formattedValue))
  ) {
    return callback();
  }
  callback(error);
};

const DEFAULT_REQUIRE_ERROR = {
  START: 'Please complete all fields',
  DETAILS: 'Please fill in the required fields marked with *',
};

/** Build array of form errors */
export const getArrayOfErrors = (form: any, PAGE = 'START') => {
  const requireFieldsMsg =
    DEFAULT_REQUIRE_ERROR[PAGE] || DEFAULT_REQUIRE_ERROR.START;
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return Object.entries(form).map(([_, { errors }]) => {
    const errMsg = errors[0].message;
    return /required/.test(errMsg) ? requireFieldsMsg : errMsg;
  });
};

/**
 * Checks is payment page should be rendered or not.
 * paymentType
 */
export const isPaymentPageAvailable = (paymentType: string) =>
  includes([PAYMENT_TYPES.CONSUMER_PAID, PAYMENT_TYPES.BOTH_PAID], paymentType);

/**
 * Return custom errors from address validation function.
 * @param values
 * @returns {{city: {value: *, errors: [*]}, postcode: {value: *, errors: [*]}, state: {value: *, errors: [*]}}}
 */
export const getErrorsOfAddressValidation = (values: any) => ({
  city: {
    value: values.city,
    errors: [{ message: AUS_ADDRESS_FIELD_INVALID, field: 'city' }],
  },
  state: {
    value: values.state,
    errors: [{ message: AUS_ADDRESS_FIELD_INVALID, field: 'state' }],
  },
  postcode: {
    value: values.postcode,
    errors: [{ message: AUS_ADDRESS_FIELD_INVALID, field: 'postcode' }],
  },
});

export const createFormFromModel = (model, constructor) => {
  const formData = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const key in model) {
    if (Object.prototype.hasOwnProperty.call(model, key)) {
      if (key === 'purchaseDate') {
        formData[key] = constructor.createFormField({
          value: model.purchaseDate
            ? moment(model.purchaseDate)
            : model.purchaseDate,
        });
      } else {
        formData[key] = constructor.createFormField({
          value: model[key],
        });
      }
    }
  }
  return formData;
};

class RequestCancelQueue {
  controllersMap: {
    [key: string]: CancelTokenSource;
  } = {};

  enqueueNewRequest(methodPath: string[]) {
    const key = methodPath.join('-');
    if (this.controllersMap[key]) {
      this.controllersMap[key].cancel();
    }
    // @ts-ignore
    this.controllersMap[key] = CancelToken.source();
    const cancelToken = this.controllersMap[key].token;
    return { cancelToken, controller: this.controllersMap[key] };
  }
}

export const requestQueue = new RequestCancelQueue();

export const extractReasonsFromProduct = (order, product) => {
  if (!product) return;
  // IS_EMPTY_RETURN_REASON means its a dummy reason - need to skip it
  const { code, description, IS_EMPTY_RETURN_REASON } = product;
  // eslint-disable-next-line no-param-reassign
  order.returnReason = !IS_EMPTY_RETURN_REASON ? { code, description } : null;
  return order;
};

export const removeUnsupportedPropsFromProduct = product => {
  if (!product) return;
  // priceCurrency is TEMPORAL WORKAROUND AS NO SERVICE-LAYER SUPPORT YET
  const { priceCurrency, isNotReturnable, ...rest } = product;
  return rest;
};

export const truncate = (str: string, n: number): string =>
  str.length > n ? `${str.substr(0, n - 1)}\u2026` : str;

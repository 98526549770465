import styled, { css } from 'styled-components';
import { Button as StyledButton, Select as StyledSelect } from 'antd';
import { Icon } from '_common/components';

const getRootCss = ({ integratedFlow }) =>
  integratedFlow
    ? css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 99;
        height: 100px;
        padding: 20px 20px;
      `
    : css`
        padding: 20px;
      `;
export const Root = styled.div<{ integratedFlow: boolean }>`
  background-color: ${({ theme }) => theme.config.css.accentColor || '#000'};
  color: #fff;
  min-width: 640px;
  ${getRootCss};
`;

export const InputWrapper = styled.div<{ integratedFlow: boolean }>`
  height: 60px;
  padding: 8px;
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  font-size: 18px;
  ${({ integratedFlow }) =>
    integratedFlow
      ? css`
          max-width: 920px;
          margin: 0 auto;
        `
      : css`
          width: 100%;
        `};
`;

const getButtonBgColor = ({ theme }) =>
  theme.config.css.accentColor || '#4a90e2';
export const Button = styled(StyledButton)`
  margin-left: 15px;
  &.ant-btn,
  &.ant-btn:hover {
    background-color: ${getButtonBgColor};
    color: #fff;
  }

  &.ant-btn {
    font-size: 18px;
    line-height: 20px;
    border: none;
    padding: 10px 33px;
    height: 42px;
  }
`;

export const Input = styled.input<{ ref: any }>`
  height: 42px;
  flex-grow: 1;
  font-size: 18px;
  font-family: ${props => props.theme.fonts.basic};
  color: #282d32;
  border: none;
  border-radius: 4px;
  margin-right: 5px;
  padding-left: 20px;
  min-width: 100px;
`;

export const Select = styled(StyledSelect)`
  &.ant-select {
    color: #282d32;
    margin: 0 8px;
    width: 186px;
    flex-shrink: 0;
    border-left: 1px solid #d8d8d8;

    .ant-select-selection {
      height: 42px;
      border: none;

      .ant-select-selection__rendered {
        line-height: 42px;
      }
    }
  }
`;

export const LabelWrapper = styled.div`
  position: relative;
`;

export const GeoIcon = styled(Icon)<{ disabled: boolean }>`
  cursor: pointer;
`;

export const ErrorMessage = styled.div`
  color: #f5222d;
`;

export const ClearButton = styled.div`
  font-size: 14px;
  cursor: pointer;
  color: rgb(199, 199, 204);
  text-decoration: underline;
  margin-right: 10px;
`;

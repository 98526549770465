import { assetsClient } from '_common/api/clients/clients';
import { Theme } from 'themes/defaultTheme';
import { MERCHANT_ORGANISATION } from '_common/constants/common';
import { WhiteLabelConstants } from '_common/whitelabelConfig';

const loadTheme = async (): Promise<Theme> => {
  const themeConfig = await assetsClient.get(
    `/${WhiteLabelConstants.PRODUCT_NAME}/${MERCHANT_ORGANISATION}/application-config.json`
  );

  return themeConfig.data;
};

export default {
  loadTheme,
};

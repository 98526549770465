import apiEndpoins from '_common/api/endpoints';
import themeService from '_common/services/themeService';
import themes from 'themes';
import { Theme } from 'themes/defaultTheme';
import { merge } from 'lodash';
import { action, observable, runInAction } from 'mobx';
import {
  MERCHANT_ORGANISATION,
  ASPECT_RATIO_16_9,
} from '_common/constants/common';
import { IThemeStore } from 'types/mobxStores';

/**
 * Observes loading theme for app.
 */
const EMPTY_ORGANISATION = 'EMPTY_ORGANISATION';

class ThemeStore implements IThemeStore {
  @observable
  state: string = 'INIT';

  @observable
  theme: Theme = themes.default;

  @observable
  widthOfBackgroundImage: number =
    ASPECT_RATIO_16_9 * (window.innerHeight - 170); // 100 header, 70 footer

  @observable
  widthOfBackgroundImageWithoutFooter: number =
    ASPECT_RATIO_16_9 * (window.innerHeight - 100); // 100 header

  constructor() {
    if (window.innerWidth > 768) {
      window.addEventListener(
        'resize',
        () => {
          runInAction(() => {
            this.widthOfBackgroundImage =
              ASPECT_RATIO_16_9 * (window.innerHeight - 170);
            this.widthOfBackgroundImageWithoutFooter =
              ASPECT_RATIO_16_9 * (window.innerHeight - 100);
          });
        },
        true
      );
    }
  }

  @action
  setTheme = (themeConfig: Theme | string) => {
    if (themeConfig === EMPTY_ORGANISATION) return;
    Object.keys(themeConfig.assets).forEach((id: string) => {
      const url = themeConfig.assets[id];
      // eslint-disable-next-line no-param-reassign
      themeConfig.assets[id] = `${
        apiEndpoins.ASSETS_URL
      }/${url}?ts=${Date.now()}`;
    });
    this.state = 'LOADED';
    this.theme = merge({ ...this.theme }, themeConfig);
  };

  @action
  makeThemeRequest = async (): Promise<Theme> => {
    this.state = 'LOADING';
    try {
      /** Dont try to load theme for invalid merchant's params. */
      if (!MERCHANT_ORGANISATION || MERCHANT_ORGANISATION === 'not-found') {
        this.state = EMPTY_ORGANISATION;
        return Promise.resolve(EMPTY_ORGANISATION);
      }
      const theme = await themeService.loadTheme();
      this.setTheme(theme);
      return Promise.resolve(theme);
    } catch (e) {
      console.error('ThemeStore:loadTheme:failed', e);
      this.setThemeFailedState();
      return Promise.reject(e);
    }
  };

  @action
  setThemeFailedState = () => {
    runInAction('ThemeStore:loadTheme:failed', () => {
      this.state = 'FAILED';
    });
  };
}
export default ThemeStore;

import AuthService from '_common/services/authService';
import {
  localStore as storage,
  REFRESH_TOKEN_KEY,
  TOKEN_KEY,
  TOKEN_LIFETIME,
  USER_STORAGE_KEY,
} from 'storage';
import { action, observable, runInAction } from 'mobx';
import moment from 'moment';

import { REFRESH_TOKEN_INTERVAL } from '_common/constants/timeout';
import { IApplicationTokenResponse, IUser } from 'types/token';
import { IAuthStore } from 'types/mobxStores';

class AuthStore implements IAuthStore {
  @observable
  loggedUser: IUser = storage.get(USER_STORAGE_KEY);

  @observable
  accessToken: string | null = storage.get(TOKEN_KEY);

  @observable
  refreshToken: string = storage.get(REFRESH_TOKEN_KEY);

  @observable
  isLoading: boolean = false;

  @observable
  newTokenHandler: any;

  constructor() {
    this.newTokenHandler = setInterval(
      () => this.getNewAccessToken(),
      REFRESH_TOKEN_INTERVAL
    );
  }

  authoriseApplication = async () => {
    try {
      const token = await AuthService.authorizeApplication<
        IApplicationTokenResponse
      >();
      this.setTokens(token);
      return Promise.resolve(token);
    } catch (e) {
      console.error('AuthStore:authoriseApplication:failed', e);
      return Promise.reject(e);
    }
  };

  @action
  setTokens = (tokens: any) => {
    this.accessToken = tokens.access_token;

    if (tokens.refresh_token) {
      this.refreshToken = tokens.refresh_token;
      storage.set(REFRESH_TOKEN_KEY, tokens.refresh_token);
    }

    const tokenExpiredTime = tokens.expires_in;
    storage.set(TOKEN_KEY, this.accessToken);

    if (tokenExpiredTime) {
      storage.set(
        TOKEN_LIFETIME,
        moment().add(tokenExpiredTime - 300, 'seconds')
      );
    }
  };

  isTokenValid = (extraSeconds: number = 300) => {
    const tokeLifetime = storage.get(TOKEN_LIFETIME);
    return (
      tokeLifetime &&
      moment(tokeLifetime).add(extraSeconds, 'seconds') > moment()
    );
  };

  @action
  setUser = (user: IUser) => {
    this.loggedUser = user;
    storage.set(USER_STORAGE_KEY, user);
  };

  @action
  logout = () => {
    this.accessToken = '';
    this.refreshToken = '';
    storage.remove(TOKEN_LIFETIME);
    storage.remove(TOKEN_KEY);
    storage.remove(REFRESH_TOKEN_KEY);
    storage.remove(USER_STORAGE_KEY);

    clearInterval(this.newTokenHandler);
    // redirect to the login page
    window.location.href = '/';
  };

  @action
  getNewAccessToken = async () => {
    if (!this.isTokenValid(0) && storage.get(TOKEN_LIFETIME)) {
      const res = await AuthService.refreshToken(this.refreshToken);
      runInAction(() => {
        this.setTokens({
          access_token: res.access_token,
          expires_in: res.expires_in,
        });
      });
    }
  };
}

export default AuthStore;

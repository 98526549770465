import { Input, Layout, InputLabel } from '_common/components';
import { StartPageStore, ThemeStore } from 'stores';
import commonStoresActions from '_common/actions';
import { Theme } from 'themes/defaultTheme';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { withTheme } from 'styled-components';
import { localStore as storage, MOBX_STORES, START_EVENT_SEND } from 'storage';
import {
  validateOnSpecialCharacters,
  createFormFromModel,
  getArrayOfErrors,
  getMerchantFromUrl,
} from '_common/utils';
import { RightSectionImage } from '_common/global-elements/pages';

import { AsyncStatus, PAYMENT_TYPES } from '_common/constants/common';

import {
  ConfirmButton,
  FormErrorMessage,
  SubText,
  Title,
  Wrapper,
} from '../elements';

import amplitude from '_common/utils/amplitude';
import DirectoryStore from '_common/stores/directoryStore';
import PageStates from '_common/components/PageStates';
import { withWhitelabelProps } from '_common/whitelabelConfig';
import { IRouterMatch } from 'types/core';

type Props = FormComponentProps &
  RouteComponentProps<IRouterMatch> & {
    startPageStore: StartPageStore;
    directoryStore: DirectoryStore;
    themeStore: ThemeStore;
    theme: Theme;
    whiteLabeled: any;
  };

interface State {
  isSubmitting: boolean;
}

@observer
class StartPage extends Component<Props, State> {
  static PAGE_NAME = 'Start page';

  state = {
    isSubmitting: false,
  };

  componentDidMount() {
    setTimeout(() => {
      if (!amplitude) {
        console.log('amplitude not init');
        return;
      }

      if (!storage.get(START_EVENT_SEND)) {
        const { href: url } = window.location;
        const { company: retailerName } = this.props.match.params;
        amplitude.logEvent('Start journey', {
          url,
          retailerName,
          domainNameReferrer: document.referrer.split('/')[2],
          fullUrlReferrer: document.referrer,
        });

        storage.set(START_EVENT_SEND, true);
      }

      amplitude.logEventWithOrganisationAndUrl('page_open', {
        page_name: StartPage.PAGE_NAME,
      });
    }, 1000);
  }

  componentWillUnmount() {
    storage.remove(START_EVENT_SEND);
  }

  onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (this.state.isSubmitting) {
      return;
    }

    this.setState({ isSubmitting: true });

    const {
      form: { validateFieldsAndScroll },
      history,
      startPageStore: { enableFormErrorMessage },
    } = this.props;

    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        amplitude.logValidationErrors(errors, StartPage.PAGE_NAME);
        this.setState({ isSubmitting: false });
        return enableFormErrorMessage(getArrayOfErrors(errors, 'START')[0]);
      }

      /** extract company on root page */
      const company = getMerchantFromUrl();

      if (company) {
        commonStoresActions.saveToStorage(MOBX_STORES.StartPageStore, values);
        const productJourney = commonStoresActions.getProductJourneyType();
        // non-integrated  - don't call api
        if (productJourney.NON_INTEGRATED || productJourney.NOT_SUPPORTED) {
          return history.push(`${company}/details`);
        }
        try {
          await commonStoresActions.getOrderById(company);
          history.push(`${company}/details`);
        } catch (error) {
          this.setState({ isSubmitting: false });
          enableFormErrorMessage(error);
        }
      }
    });
  };

  handleInputClick = (fieldName: string) => () => {
    const { href: url } = window.location;
    const { company: retailerName } = this.props.match.params;
    amplitude.logEvent('clicking on form fields', {
      url,
      fieldName,
      retailerName,
    });
  };

  handleConfirmClick = () => {
    const { href: url } = window.location;
    const { company: retailerName } = this.props.match.params;
    amplitude.logEvent('clicking confirm', {
      url,
      retailerName,
    });
  };

  handleTermsClick = () => {
    const { href: url } = window.location;
    const { company: retailerName } = this.props.match.params;
    amplitude.logEvent('clicking on view T&Cs', {
      url,
      retailerName,
    });
  };

  handleInputBlur = (fieldName: string) => {
    const { href: url } = window.location;
    const { company: retailerName } = this.props.match.params;

    return () => {
      amplitude.logEvent('entering info into form fields', {
        url,
        fieldName,
        retailerName,
      });
    };
  };

  trimInput = name => ({ target }) => {
    const newVal = { [name]: target.value.trim() };
    this.props.startPageStore.setFormField(newVal);
    this.props.form.setFieldsValue(newVal);
  };

  renderOrderIdInput = () => {
    const {
      directoryStore: { validationRegex },
      form: { getFieldDecorator },
      whiteLabeled: { orderIdRegex },
    } = this.props;
    return (
      <Form.Item key="orderNumber">
        <InputLabel htmlFor="orderNumber">Order ID</InputLabel>
        {getFieldDecorator('orderNumber', {
          rules: [
            {
              required: true,
              message: 'Order ID is required',
            },
            validationRegex && {
              pattern: new RegExp(`^${validationRegex}$`),
              message: 'Invalid order ID. Please try again.',
            },
            {
              validator: (
                rule: any,
                value: string,
                callback: (error?: Error) => void
              ) => {
                validateOnSpecialCharacters(
                  rule,
                  value,
                  callback,
                  orderIdRegex
                );
              },
            },
          ],
        })(
          <Input
            id="orderNumber"
            placeholder="Enter your Order ID"
            onFocus={this.handleInputClick('orderNumber')}
            onBlur={this.handleInputBlur('orderNumber')}
          />
        )}
      </Form.Item>
    );
  };

  renderRmaInput = () => {
    const {
      form: { getFieldDecorator },
    } = this.props;
    return (
      <Form.Item key="rmaNumber">
        <InputLabel htmlFor="rmaNumber">RMA ID</InputLabel>
        {getFieldDecorator('rmaNumber', {
          rules: [
            {
              required: true,
            },
            {
              validator: (
                rule: any,
                value: string,
                callback: (error?: Error) => void
              ) => {
                validateOnSpecialCharacters(rule, value, callback);
              },
            },
          ],
        })(
          <Input
            id="rmaNumber"
            placeholder="Enter your RMA ID"
            onFocus={this.handleInputClick('rmaNumber')}
            onBlur={this.handleInputBlur('rmaNumber')}
          />
        )}
      </Form.Item>
    );
  };

  renderRanNumberInput = () => {
    const {
      directoryStore: {
        returnFormFields: { RANFieldName },
        returnReasons: { RANValidationRegex },
      },
      form: { getFieldDecorator },
      whiteLabeled: { ranPlaceholder },
    } = this.props;
    return (
      <Form.Item key="ranNumber">
        <InputLabel htmlFor="ranNumber">
          {RANFieldName || ranPlaceholder}
        </InputLabel>
        {getFieldDecorator('ranNumber', {
          rules: [
            {
              required: true,
              message: 'RAN ID is required',
            },
            RANValidationRegex && {
              pattern: new RegExp(`^${RANValidationRegex}$`),
              message: 'Invalid RAN. Please try again.',
            },
            {
              validator: (
                rule: any,
                value: string,
                callback: (error?: Error) => void
              ) => {
                validateOnSpecialCharacters(rule, value, callback);
              },
            },
          ],
        })(
          <Input
            id="ranNumber"
            placeholder="ABC12345678"
            onFocus={this.handleInputClick('ranNumber')}
            onBlur={this.trimInput('ranNumber')}
          />
        )}
      </Form.Item>
    );
  };

  renderEmailInput = () => {
    const {
      form: { getFieldDecorator },
    } = this.props;
    return (
      <Form.Item key="email">
        <InputLabel htmlFor="email">Email</InputLabel>
        {getFieldDecorator('email', {
          rules: [
            {
              required: true,
            },
            {
              type: 'email',
              message: 'Email is not valid',
            },
            {
              validator: (
                rule: any,
                value: string,
                callback: (error?: Error) => void
              ) => {
                validateOnSpecialCharacters(rule, value, callback);
              },
            },
          ],
        })(
          <Input
            id="email"
            placeholder="your.name@mail.com"
            onFocus={this.handleInputClick('email')}
            onBlur={this.handleInputBlur('email')}
          />
        )}
      </Form.Item>
    );
  };

  generateInputs = () => {
    const {
      directoryStore: {
        rma,
        returnFormFields: { RAN, orderId },
      },
    } = this.props;
    const inputs = [];

    if (orderId) {
      inputs.push(this.renderOrderIdInput());
    }
    if (rma === 'PRE_SUPPLIED') {
      inputs.push(this.renderRmaInput());
    }
    if (RAN) {
      inputs.push(this.renderRanNumberInput());
    }
    inputs.push(this.renderEmailInput());
    return inputs.map(jsx => jsx);
  };

  renderForm = () => {
    const {
      startPageStore: { showFormErrorMessage },
    } = this.props;
    const { isSubmitting } = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        <FormErrorMessage hidden={!showFormErrorMessage}>
          {showFormErrorMessage}
        </FormErrorMessage>
        {this.generateInputs()}
        <ConfirmButton
          htmlType="submit"
          onClick={this.handleConfirmClick}
          disabled={isSubmitting}
        >
          Next
        </ConfirmButton>
      </Form>
    );
  };

  formatTermsAndConditionUrl = (url: string) => {
    if (!url) return '#';
    return `https://${url.replace(/(^\w+:|^)\/\//, '')}`;
  };

  getTitleText = () => {
    const {
      directoryStore: {
        paymentConfig: { paymentType, price },
      },
      whiteLabeled: { returnIsFreeText },
    } = this.props;
    const { MERCHANT_PAID, CONSUMER_PAID, BOTH_PAID } = PAYMENT_TYPES;

    switch (paymentType) {
      case MERCHANT_PAID:
        return returnIsFreeText;
      case CONSUMER_PAID:
        return `Your return will cost ${price}`;
      case BOTH_PAID:
        return 'You may need to pay for your return';
      default:
        return 'Unable to calculate cost';
    }
  };

  renderLayout = () => {
    const {
      directoryStore: { termsAndConditionsUrl },
      themeStore: { widthOfBackgroundImage },
      whiteLabeled: { introText },
    } = this.props;

    return (
      <Wrapper>
        <Layout.Container>
          <Layout.LeftSection>
            <div>
              <Title>{this.getTitleText()}</Title>
              <SubText breakLine>
                {introText}
                {termsAndConditionsUrl && (
                  <a
                    href={this.formatTermsAndConditionUrl(
                      termsAndConditionsUrl
                    )}
                    onClick={this.handleTermsClick}
                    onAuxClick={this.handleTermsClick}
                  >
                    Check your retailer’s returns policy here
                  </a>
                )}
              </SubText>
              {this.renderForm()}
            </div>
          </Layout.LeftSection>
          <Layout.RightSection widthOfBackgroundImage={widthOfBackgroundImage}>
            <RightSectionImage />
          </Layout.RightSection>
        </Layout.Container>
      </Wrapper>
    );
  };

  render() {
    const {
      directoryStore: { status },
    } = this.props;

    switch (status) {
      case AsyncStatus.SUCCESS:
        return this.renderLayout();
      case AsyncStatus.FAILED:
        return <PageStates.PageError />;
      case AsyncStatus.LOADING:
        return <PageStates.PageLoader />;
      default:
        return null;
    }
  }
}

export default compose(
  inject('startPageStore', 'directoryStore', 'themeStore'),
  withTheme,
  withWhitelabelProps({
    orderIdRegex: 'ui.pages.start.orderIdRegex',
    introText: 'ui.pages.start.introText',
    returnIsFreeText: 'ui.pages.start.returnIsFreeText',
    ranPlaceholder: 'ui.pages.start.ranPlaceholder',
  }),
  Form.create<Props>({
    mapPropsToFields(props) {
      return createFormFromModel(props.startPageStore.formFields, Form);
    },
    onValuesChange(props, fields) {
      const keys = Object.keys(fields);
      if (!keys.length) {
        return;
      }
      props.startPageStore.setFormField(fields);
      // @ts-ignore
      props.startPageStore.enableFormErrorMessage(false);
    },
  })
)(StartPage);

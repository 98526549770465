/* eslint no-console: 0 */
import React, { Component } from 'react';
import { Wrapper, CardPlaceholder } from './SecurePayUiElements';
import {
  SECURE_PAY_API_KEY,
  SECURE_PAY_CLIENT_ID,
  SECURE_PAY_MERCHANT_CODE,
  AsyncStatus,
} from '_common/constants/common';
import { loadDynamicScript } from '_common/utils';

import {
  LoaderDots,
  LoaderText,
  LoaderWrapper,
} from '_common/components/PageStates/element';

type Props = {
  renderButtons: any;
  renderErrorPage: any;
  onSuccessPayment: () => void;
  onErrorPayment: () => void;
};

type State = {
  scriptLoadingStatus: string;
  securePayError: string | null;
};

/**
 * Provides wrapper around secure pay library for australian post merchants.
 */
class SecurePayUi extends Component<Props, State> {
  static CONTAINER_ID = 'securepay-ui-container';

  static SCRIPT_ID = 'securepay-ui-js';

  static LIB_URL =
    'https://payments-stest.npe.auspost.zone/v3/ui/client/securepay-ui.min.js';

  mySecurePayUI;

  state = {
    scriptLoadingStatus: AsyncStatus.IDLE,
    securePayError: null,
  };

  componentDidMount() {
    this.loadScript();
  }

  loadScript = () => {
    this.setState({ scriptLoadingStatus: AsyncStatus.LOADING });
    return loadDynamicScript({
      url: SecurePayUi.LIB_URL,
      throttle: 1000,
      successCallback: () => {
        this.setState(
          { scriptLoadingStatus: AsyncStatus.SUCCESS },
          this.handleScriptLoad
        );
      },
      errorCallback: () => {
        this.setState({ scriptLoadingStatus: AsyncStatus.FAILED });
      },
      scriptId: SecurePayUi.SCRIPT_ID,
      remainingAttempts: 3,
    });
  };

  handleScriptLoad = () => {
    // @ts-ignore
    // eslint-disable-next-line new-cap
    this.mySecurePayUI = new window.securePayUI.init({
      containerId: SecurePayUi.CONTAINER_ID,
      scriptId: SecurePayUi.SCRIPT_ID,
      apiKey: SECURE_PAY_API_KEY,
      merchantCode: SECURE_PAY_MERCHANT_CODE,
      clientId: SECURE_PAY_CLIENT_ID,
      card: {
        allowedCardTypes: ['visa', 'mastercard'],
        showCardIcons: true,
        onCardTypeChange: cardType => {
          // card type has changed
          console.log('mySecurePayUI :: onCardTypeChange', cardType);
        },
        onBINChange: cardBIN => {
          // card BIN has changed
          console.log('mySecurePayUI :: onBINChange', cardBIN);
        },
        onFormValidityChange: valid => {
          // form validity has changed
          console.log('mySecurePayUI :: onFormValidityChange', valid);
        },
        onTokeniseSuccess: tokenisedCard => {
          // card was successfully tokenised or saved card was successfully retrieved
          console.log('mySecurePayUI :: onTokeniseSuccess', tokenisedCard);
          this.props.onSuccessPayment();
        },
        onTokeniseError: errors => {
          // tokenization failed
          console.log(
            'mySecurePayUI :: onTokeniseError',
            errors,
            errors.detail
          );
          this.setState({ securePayError: JSON.parse(errors).detail });
          this.props.onErrorPayment();
        },
      },
      style: {
        backgroundColor: '#fff',
        label: {
          font: {
            family: 'Arial, Helvetica, sans-serif',
            size: '1.1rem',
            color: 'darkblue',
          },
        },
      },
      onLoadComplete: () => {
        // the UI Component has successully loaded and is ready to be interacted with
      },
      onLoadError: () => {
        // the UI Component has successully loaded and is ready to be interacted with
      },
    });
  };

  handleTokenise = () => {
    this.setState({ securePayError: null });
    this.mySecurePayUI.tokenise();
  };

  renderBody = () => {
    const { scriptLoadingStatus, securePayError } = this.state;
    const { renderButtons, renderErrorPage } = this.props;

    switch (scriptLoadingStatus) {
      case AsyncStatus.SUCCESS:
        return (
          <>
            <CardPlaceholder id={SecurePayUi.CONTAINER_ID} />
            {securePayError && <div>{securePayError}</div>}
            {renderButtons({ confirm: this.handleTokenise })}
          </>
        );
      case AsyncStatus.FAILED:
        return renderErrorPage({ retry: this.loadScript });
      case AsyncStatus.LOADING:
        return (
          <LoaderWrapper>
            <LoaderText>Loading</LoaderText>
            <LoaderDots>
              <div />
              <div />
              <div />
              <div />
            </LoaderDots>
          </LoaderWrapper>
        );
      default:
        return null;
    }
  };

  render() {
    return <Wrapper>{this.renderBody()}</Wrapper>;
  }
}

export default SecurePayUi;

import { observer, inject } from 'mobx-react';
import { reaction } from 'mobx';
import React, { ComponentType } from 'react';
import { compose } from 'recompose';
import { Redirect, RouteComponentProps } from 'react-router';
import { Route, Switch, withRouter } from 'react-router-dom';
import { endsWith, isEmpty } from 'lodash';
import { ThemeProvider } from 'styled-components';

import links from '_common/routes/urls';
import {
  Footer,
  Header,
  DependenciesLoader,
  PageStates,
} from '_common/components';
import Details from 'pages/details/routes';
import Payment from 'pages/payment/routes';
import Start from 'pages/start/routes';
import Success from 'pages/success/routes';
import Tracking from 'pages/tracking/routes';
import { getMerchantFromUrl } from '_common/utils';
import { DirectoryStore, ThemeStore, AuthStore } from 'stores';
import commonStoresActions from '_common/actions';
import amplitude from '_common/utils/amplitude';
import {
  withWhitelabelProps,
  WHITELABEL_SERVICES,
} from '_common/whitelabelConfig';
import { AsyncStatus } from '_common/constants/common';
import { IRouterMatch } from 'types/core';
import NotFound from 'pages/not-found';

interface Props extends RouteComponentProps<IRouterMatch> {
  directoryStore: DirectoryStore;
  themeStore: ThemeStore;
  authStore: AuthStore;
  whiteLabeled: any;
  merchForRedirect?: string;
}

type State = {
  hideHeaderAndFooter: boolean;
};

const isNotFoundPage = (location = window.location.pathname) =>
  endsWith(location, '/not-found');

const isPageWithoutHeaderAndFooter = (location = window.location.pathname) =>
  endsWith(location, '/tracking') || endsWith(location, '/ratings');

@observer
class Main extends React.Component<Props, State> {
  disposeReaction: any;

  state = {
    hideHeaderAndFooter: isPageWithoutHeaderAndFooter(),
  };

  componentDidMount(): void {
    const company = getMerchantFromUrl();
    if (company) {
      commonStoresActions.getCompany(company);
      commonStoresActions.validateSession(company);
      amplitude.initAmplitude(company);
    }
    this.disposeReaction = reaction(
      () => this.props.directoryStore.isURLDisabled,
      isURLDisabled => {
        if (isURLDisabled) {
          this.props.history.push('/not-found');
        }
      }
    );
  }

  componentWillUnmount() {
    this.disposeReaction();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        hideHeaderAndFooter: isPageWithoutHeaderAndFooter(
          this.props.location.pathname
        ),
      });
    }
  }

  renderSwitch() {
    const { hideHeaderAndFooter } = this.state;
    const {
      whiteLabeled: { footerWlProps },
      directoryStore: { status, companyConfig },
      themeStore: { widthOfBackgroundImage },
      merchForRedirect,
    } = this.props;
    const routes = [
      ...Start,
      ...Details,
      ...Payment,
      ...Success,
      ...Tracking,
      ...WHITELABEL_SERVICES.getWhitelabelRoutes(),
    ];
    const companyConfigProcessedAndValid =
      status !== AsyncStatus.LOADING && !isEmpty(companyConfig);

    return (
      <>
        {isNotFoundPage || companyConfigProcessedAndValid ? (
          <>
            {!hideHeaderAndFooter && <Header />}
            <Switch>
              <Route
                exact
                path={links.notFound}
                render={(props: Props) => (
                  <NotFound
                    {...props}
                    merchForRedirect={merchForRedirect}
                    widthOfBackgroundImage={widthOfBackgroundImage}
                  />
                )}
              />
              {routes.map(
                (route: { path: string; component: ComponentType<any> }) => (
                  <Route exact key={route.path} {...route} />
                )
              )}
              <Redirect to={links.notFound} />
            </Switch>
            {!hideHeaderAndFooter && <Footer {...footerWlProps} />}
          </>
        ) : null}
      </>
    );
  }

  render() {
    const { themeStore, authStore } = this.props;
    return (
      <DependenciesLoader
        dependenciesList={[
          themeStore.makeThemeRequest,
          authStore.authoriseApplication,
        ]}
        loadingComponent={<PageStates.PageLoader />}
        errorComponent={<PageStates.PageError />}
      >
        <ThemeProvider theme={themeStore.theme}>
          {this.renderSwitch()}
        </ThemeProvider>
      </DependenciesLoader>
    );
  }
}

export default compose(
  withRouter,
  inject('directoryStore', 'themeStore', 'authStore'),
  withWhitelabelProps({
    footerWlProps: 'ui.common.footer',
  })
)(Main);

import { apiClient, pureClient } from '_common/api/clients/clients';
import { CancelToken } from 'axios';
import { MAX_STORES, MAX_DISTANCE } from 'pages/success/utils/locationUtils';
import { ROOT_ORG_ID } from '_common/constants/common';
import { WHITELABEL_UI } from '_common/whitelabelConfig';
import { IChannelStoreModel } from 'types/store';

const getGeoQueryStores = async (
  config,
  cancelToken: CancelToken
): Promise<IChannelStoreModel[]> => {
  const { lat, lng, locationTypes, distance, extendedHours } = config;
  const { distanceUnit } = WHITELABEL_UI.pages.success;
  const distanceToUse = distance || MAX_DISTANCE;
  const response = await apiClient.get(
    `/v3/stores/latitude/${lat}/longitude/${lng}?distance=${distanceToUse}&unit=${distanceUnit}&limit=${MAX_STORES}&companyId=${ROOT_ORG_ID}&storesFeed=true&locationTypes=${locationTypes}&services=RETURNS&includeOpeningHours=true&extendedHours=${extendedHours}`,
    { cancelToken }
  );
  return response.data.resources;
};

type ReverseGeocodeConfig = {
  lat: number;
  lng: number;
  key: string;
};
const getReverseGeocode = async ({ lat, lng, key }: ReverseGeocodeConfig) => {
  const res = await pureClient.get(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${key}`
  );
  return res.data;
};

export default {
  getGeoQueryStores,
  getReverseGeocode,
};

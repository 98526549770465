import React from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';

import { geoIcon } from 'assets';
import {
  Button,
  ClearButton,
  ErrorMessage,
  GeoIcon,
  Input,
  InputWrapper,
  LabelWrapper,
  Root,
  Select,
} from './SearchHeaderElements';
import commonStoresActions from '_common/actions';
import DirectoryStore from '_common/stores/directoryStore';
import { WhiteLabelUtils, WHITELABEL_UI } from '_common/whitelabelConfig';

const { Option } = Select;

type Props = {
  directoryStore: DirectoryStore;
  detailsPageStore: any;
  logFieldEvent: (eventName: string, extraPayload?: any) => void;
  company: string;
  disabled: boolean;
  integratedFlow?: boolean;
  orderStore: any;
  whiteLabeled: any;
};

type State = {
  hasError: boolean;
  isSearchButtonDisabled: boolean;
};

@observer
class SearchHeader extends React.Component<Props, State> {
  autocompleteRef = React.createRef();

  state = {
    hasError: false,
    isSearchButtonDisabled: false,
  };

  getSearchInput = () => this.autocompleteRef.current;

  onGeoIconClick = async () => {
    this.enableSearchButton();
    const searchInput: any =
      this.autocompleteRef && this.autocompleteRef.current;
    if (!searchInput || this.props.disabled) return;
    this.props.logFieldEvent('click my location icon');
    searchInput.value = '';
    searchInput.value = await commonStoresActions.searchStoresNearMe(
      this.props.company
    );
  };

  onInputClick = () => {
    this.props.logFieldEvent('click on search field');
  };

  onSearchClick = async () => {
    this.setState({ hasError: false });
    if (this.autocompleteRef.current) {
      const extraPayload = {
        locationInput: (this.autocompleteRef.current as any).value,
      };
      this.props.logFieldEvent('click search', extraPayload);
    }
    try {
      await commonStoresActions.searchFromLastLocation();
    } catch (e) {
      this.setState({ hasError: true });
    }
  };

  renderOption = (option: { value: string; label: string }, index: number) => {
    const { value, label } = option;
    return (
      <Option key={`option-${index}`} value={value}>
        {label}
      </Option>
    );
  };

  renderSelect() {
    const { locationTypesOptions } = this.props.directoryStore;
    const { disabled } = this.props;
    const defaultValue = locationTypesOptions.length
      ? locationTypesOptions[0].value
      : null;
    return (
      <Select
        defaultValue={defaultValue}
        placeholder="Filter by location type"
        onChange={commonStoresActions.setLocationType}
        disabled={disabled}
      >
        {locationTypesOptions.map(this.renderOption)}
      </Select>
    );
  }

  get userAddress() {
    const {
      orderStore: { isIntegratedFlow, userInfo },
    } = this.props;

    const {
      addressLine1,
      addressLine2,
      city,
      postcode,
      state,
    } = isIntegratedFlow
      ? WhiteLabelUtils.convertAddressForMap(userInfo)
      : this.props.detailsPageStore.formFields;

    return [addressLine1, addressLine2, city, state, postcode]
      .filter(v => v)
      .join(', ');
  }

  handleClearSearch = () => {
    // @ts-ignore
    this.autocompleteRef.current.value = '';
    this.setState({ isSearchButtonDisabled: true });
  };

  enableSearchButton = () => {
    this.setState({ isSearchButtonDisabled: false });
  };

  render() {
    const { disabled, integratedFlow } = this.props;

    const { successSearchPlaceholder } = WHITELABEL_UI.pages.success.textValues;
    const { isLocationTypesSelectVisible } = WHITELABEL_UI.pages.success;

    const { hasError, isSearchButtonDisabled } = this.state;
    const icon = (
      <GeoIcon
        image={geoIcon}
        onClick={this.onGeoIconClick}
        width={24}
        height={24}
        disabled={disabled}
      />
    );

    return (
      <Root integratedFlow={integratedFlow}>
        {!integratedFlow && <LabelWrapper />}
        <InputWrapper integratedFlow={integratedFlow}>
          <Input
            placeholder={
              integratedFlow
                ? successSearchPlaceholder
                : 'Search for drop off locations'
            }
            ref={this.autocompleteRef}
            onClick={this.onInputClick}
            disabled={disabled}
            defaultValue={this.userAddress || ''}
            onChange={this.enableSearchButton}
          />
          <ClearButton onClick={this.handleClearSearch}>Clear</ClearButton>
          {icon}
          {isLocationTypesSelectVisible && this.renderSelect()}
          <Button
            onClick={this.onSearchClick}
            disabled={disabled || isSearchButtonDisabled}
          >
            Search
          </Button>
        </InputWrapper>
        {hasError && <ErrorMessage>Error while loading</ErrorMessage>}
      </Root>
    );
  }
}

export default compose(
  inject('directoryStore', 'detailsPageStore', 'orderStore')
)(SearchHeader);

import backgroundImage from 'assets/images/brooke-cagle.png';
import logoImage from 'assets/images/logo.png';

const defaultTheme = {
  fonts: {
    basic: 'Arial, sans-serif',
  },
  assets: {
    backgroundImage,
    logoImage,
  },
  config: {
    css: {
      accentColor: '#DC1928',
    },
    termsAndConditionsLink: '',
  },
};
export type Theme = any;
export default defaultTheme;

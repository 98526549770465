import React, { Component } from 'react';
import { ModalBody, ModalOverlay } from './elements';

type ModalProps = {
  children?: any;
  toggle?: () => void;
};

class BaseModal extends Component<ModalProps, {}> {
  handleOverlayClick = () => {
    this.props.toggle && this.props.toggle();
  };

  render() {
    return (
      <ModalOverlay onClick={this.handleOverlayClick}>
        <ModalBody onClick={this.handleOverlayClick}>
          {this.props.children}
        </ModalBody>
      </ModalOverlay>
    );
  }
}

export default BaseModal;

import React, { Component } from 'react';
import { compose } from 'recompose';

import { Layout } from '_common/components';
import {
  PrintSection,
  SubText,
  Title,
  StoreListWrapper,
  IntegratedFlowWrapper,
  PrintSectionWrapper,
  StoresListError,
} from '../elements';
import { AsyncStatus } from '_common/constants/common';
import DesktopMap from './DesktopMap';
import StoreList from './StoreList/StoreList';
import MobileMap from './MobileMap/MobileMap';
import { withWhitelabelProps } from '_common/whitelabelConfig';
import { IStore } from 'types/store';

interface Props {
  labelLoading: any;
  successButton: any;
  retryButton: any;
  whiteLabeled: any;
  orderCreateSuccess: any;
  onStoreClick: (store: IStore) => void;
  loaderText: any;
  onMapInit: any;
  geo: any;
}

class IntegratedFlowUI extends Component<Props> {
  getConfig = () => {
    const {
      labelLoading,
      successButton,
      retryButton,
      whiteLabeled: { errorText, successText },
    } = this.props;

    switch (labelLoading) {
      case AsyncStatus.FAILED:
        return {
          title: <Title integratedFlow>Error creating return.</Title>,
          button: retryButton(),
          subtitle: <SubText integratedFlow>{errorText}</SubText>,
        };
      case AsyncStatus.SUCCESS:
        return {
          title: <Title integratedFlow>{successText}</Title>,
          button: successButton(),
        };
      case AsyncStatus.LOADING:
        return {
          title: <Title integratedFlow>Booking return...</Title>,
          button: successButton(),
        };
      default:
        // invalid state.
        console.error('getConfig error! Invalid loading status provided');
        return {};
    }
  };

  renderMainBlock = config => {
    const hasError = this.props.labelLoading === AsyncStatus.FAILED;
    return (
      <>
        <MobileMap orderCreateSuccess={this.props.orderCreateSuccess} />
        {hasError ? (
          <StoresListError>{config.title}</StoresListError>
        ) : (
          <StoreListWrapper isIntegratedFlow>
            <StoreList onStoreClick={this.props.onStoreClick} />
          </StoreListWrapper>
        )}
      </>
    );
  };

  renderPrintSection = config => (
    <IntegratedFlowWrapper>
      <PrintSection integratedFlow>{config.title}</PrintSection>
      {this.props.whiteLabeled.renderIntegratedSteps(config)}
    </IntegratedFlowWrapper>
  );

  renderLeftSectionContent = config => {
    const { labelLoading, loaderText } = this.props;
    return (
      <>
        {labelLoading === AsyncStatus.LOADING
          ? loaderText('Booking return...')
          : this.renderMainBlock(config)}
      </>
    );
  };

  render() {
    const { labelLoading, onMapInit, orderCreateSuccess } = this.props;
    const config = this.getConfig();
    return (
      <>
        <PrintSectionWrapper>
          {this.renderPrintSection(config)}
        </PrintSectionWrapper>
        <Layout.Container integratedFlow>
          <Layout.LeftSectionNotResponsive integratedFlow>
            {this.renderLeftSectionContent(config)}
          </Layout.LeftSectionNotResponsive>
          <Layout.RightSectionNotResponsive integratedFlow>
            <DesktopMap
              currentGeo={this.props.geo}
              integratedFlow
              onMapInit={onMapInit}
              labelLoading={labelLoading === AsyncStatus.LOADING}
              orderCreateSuccess={orderCreateSuccess}
            />
          </Layout.RightSectionNotResponsive>
        </Layout.Container>
      </>
    );
  }
}

export default compose(
  withWhitelabelProps({
    errorText: 'ui.pages.success.textValues.errorStatusText',
    successText: 'ui.pages.success.textValues.successStatusText',
    renderIntegratedSteps: 'ui.pages.success.renderIntegratedSteps',
  })
)(IntegratedFlowUI);

import styled from 'styled-components';

export const ProgressWrapper = styled.div`
  & .ant-progress-bg {
    border-radius: 0 !important;
    background-color: #4a90e2;
  }
  & .ant-progress {
    display: block !important;
    line-height: 0 !important;
  }
  & .ant-progress-status-success .ant-progress-bg {
    background-color: #4a90e2 !important;
  }
`;

import { Icon, Select as AntdSelect } from 'antd';
import React from 'react';
import { StyledSelect } from './elements';

export const { Option } = AntdSelect;

type Props = {
  [key: string]: any;
};

const CustomIcon = (props: any) => {
  const Svg = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="8"
      viewBox="0 0 14 8"
    >
      <path
        fill="#111"
        fillRule="nonzero"
        d="M7 5.648L12.627 0 14 1.378 7 8 0 1.378 1.373 0z"
      />
    </svg>
  );

  return <Icon component={Svg} {...props} />;
};

const Select = React.forwardRef(({ ...props }: Props, ref: any) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
  <div onClick={props.onClick}>
    <StyledSelect suffixIcon={<CustomIcon />} {...props} ref={ref}>
      {props.children}
    </StyledSelect>
  </div>
));

export default Select;

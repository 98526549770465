/* eslint camelcase: 0 */

import { action, observable, runInAction } from 'mobx';
import { localStore as storage, PDF_LABEL_URL } from 'storage';

import {
  LABEL_POLLER_ATTEMPTS,
  LABEL_POLLER_INTERVAL,
} from '_common/constants/timeout';
import AusPostServices from '../services';
import { TSPResult } from '_common/components/AustraliaPost/SecureFrame/SecureFrame';
import { CommonActions } from '_common/actions';
import amplitude from '../../../utils/amplitude';
import { IOrderCreationStatus } from 'types/order';
import { IWhitelabelProxyStore } from 'types/mobxStores';

/**
 * Observes state of creating labels via aus.post proxy.
 */
class AusPostProxyStore implements IWhitelabelProxyStore {
  commonStoresActions: CommonActions;

  registerCommonActions = (commonStoresActions: CommonActions) => {
    this.commonStoresActions = commonStoresActions;
  };

  @observable
  pdfLabelURL = storage.get(PDF_LABEL_URL);

  @observable
  shipmentCreationResponse = null;

  @observable
  consumerPaymentWasSuccessful: boolean = false;

  @observable
  orderWasSubmitted: boolean = false;

  securePayData: TSPResult | null = null;

  @action
  resetStore = () => {
    this.shipmentCreationResponse = null;
    this.consumerPaymentWasSuccessful = false;
    this.orderWasSubmitted = false;
    this.securePayData = null;
  };

  @action
  createShipment = (config, company: string): Promise<void> =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      let accountNumber;
      let bookingId;
      try {
        accountNumber = this.commonStoresActions.getAccountNumberForHeader();
        // Shipment creation step.
        const shipmentCreationResponse = await AusPostServices.proxyService.createShipments(
          { ...config, accountNumber }
        );
        bookingId = shipmentCreationResponse.bookingId;
      } catch (e) {
        return reject(e);
      }

      // Multiple attempts to get shipment data.
      const poller = async (currentIndex): Promise<void> => {
        if (currentIndex >= LABEL_POLLER_ATTEMPTS) {
          amplitude.logEventWithOrganisationAndUrl('Return creation failed');
          return reject(new Error('Error while getting shipment data'));
        }

        try {
          // Get shipment data.
          const detailedShipment: IOrderCreationStatus = await AusPostServices.proxyService.getShipments(
            bookingId
          );

          if (detailedShipment.status === 'COMPLETE - ORDER CREATED') {
            const { pdfLabelUrl } = detailedShipment;
            runInAction(() => {
              this.pdfLabelURL = pdfLabelUrl;
              this.shipmentCreationResponse = detailedShipment;
              this.orderWasSubmitted = true;
              storage.set(PDF_LABEL_URL, pdfLabelUrl);
            });
            amplitude.logEventWithOrganisationAndUrl('Return created');
            process.env.NODE_ENV === 'production' &&
              amplitude.changeProp('add', 'returns completed', 1);
            company && this.commonStoresActions.validateSession(company, true);
            return resolve();
          }
          /** next tick */
          setTimeout(() => poller(currentIndex + 1), LABEL_POLLER_INTERVAL);
        } catch {
          /** In error case also re-trying. */
          setTimeout(() => poller(currentIndex + 1), LABEL_POLLER_INTERVAL);
        }
      };

      poller(0);
    });

  @action
  validateAddress = async (address: any): Promise<void> => {
    try {
      const accountNumber = this.commonStoresActions.getAccountNumberForHeader();
      const mappedAddress = AusPostServices.proxyService.mapFrontAddressToBack(
        address
      );
      const validationResult = await AusPostServices.proxyService.validateAddress(
        mappedAddress,
        accountNumber
      );
      const { found } = validationResult;
      if (found) {
        this.commonStoresActions.saveDetailsFormToStorage();
        return Promise.resolve();
      }
      return Promise.reject();
    } catch (e) {
      return Promise.reject();
    }
  };

  @action
  setConsumerPaymentWasSuccessful = (paymentData: TSPResult) => {
    this.securePayData = paymentData;
    this.consumerPaymentWasSuccessful = true;
  };
}

export default AusPostProxyStore;

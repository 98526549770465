import React, { Component } from 'react';
import {
  ReturnReasonWrapper,
  ReturnReasonButtonWrapper,
  ReturnReasonRow,
  Heading,
} from './elements';
import { chunk } from 'lodash';
import { IReason } from 'types/company';
import { truncate } from '_common/utils';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { IDirectoryStore } from 'types/mobxStores';

interface Props {
  directoryStore: IDirectoryStore;
  returnReasons: IReason[];
  onSelect: (selectedReason: any) => void;
  noReasonsText: any | null;
  withBottomMargin: boolean;
}

@observer
class ReturnReasons extends Component<Props> {
  render() {
    const {
      onSelect,
      returnReasons,
      noReasonsText,
      withBottomMargin,
    } = this.props;

    if (returnReasons.length <= 0) {
      return noReasonsText;
    }

    const handleReasonSelect = (reason: IReason) => () => onSelect(reason);
    const renderReason = (reason: IReason) => (
      <ReturnReasonButtonWrapper
        onClick={handleReasonSelect(reason)}
        key={reason.code}
        title={reason.description}
      >
        {truncate(String(reason.description), 20)}
      </ReturnReasonButtonWrapper>
    );
    const renderRequestsRow = (chunkedArray, arrayIndex) => (
      <ReturnReasonRow key={arrayIndex}>
        {chunkedArray.map(renderReason)}
      </ReturnReasonRow>
    );

    return (
      <ReturnReasonWrapper marginBottom={withBottomMargin && 10}>
        <Heading>Reason for return</Heading>
        {chunk(returnReasons, 4).map(renderRequestsRow)}
      </ReturnReasonWrapper>
    );
  }
}

export default compose()(ReturnReasons);

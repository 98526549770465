/* eslint-disable */
import { HOTJAR_ID_WITH_VERSION } from '_common/constants/common';

export const initHotJar = () => {
  if (HOTJAR_ID_WITH_VERSION === 'N/A') {
    console.error('HotJar is disabled for current env.');
  }
  const [hjid, hjsv = 6] = HOTJAR_ID_WITH_VERSION.split(',');
  if (!hjid) {
    console.error('Unable to initialise HotJar');
    return;
  }
  ((h: any, o, t, j, a, r) => {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid, hjsv };
    h._scriptPath = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    if (!document.querySelector(`script[src*="${h._scriptPath}"]`)) {
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = h._scriptPath;
      a.appendChild(r);
    }
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
};

/* eslint camelcase: 0 */
import React, { Component } from 'react';
import Iframe from 'react-iframe';
import moment from 'moment';
import SHA1 from 'js-sha1';
import apiEndpoins from '_common/api/endpoints';
import { isIE } from 'react-device-detect';
import {
  LoaderDots,
  LoaderText,
  LoaderWrapper,
} from '_common/components/PageStates/element';
import { Wrapper } from './SecureFrameElements';
import {
  SECURE_PAY_MERCHANT_ID,
  SECURE_PAY_TRANSACTION_PASSWORD,
} from '_common/constants/common';

/** Links to S3 stored file in  APAC Prod asset management Returns Portal - AP-SECUREPAY */
const SECURE_PAY_IFRAME_CSS =
  'https://company-assets.apac-prod.doddle.tech/RETURNS_PORTAL/AP_SECURE_PAY/SecurePayIframe.css';

const TXN_TYPE_CARD = 0;
const IS_TEST =
  apiEndpoins.SECURE_PAY_URL === 'https://test.payment.securepay.com.au';
const TEST_AMOUNT = 100;
const TEST_MERCHANT_ID = 'ABC0010';
const TRANSACTION_PASSWORD = IS_TEST
  ? 'abc123'
  : SECURE_PAY_TRANSACTION_PASSWORD;

const CALLBACK_SERVER = apiEndpoins.LEGACY_DODDLE_API_URL;
const COMPLETE_URL = `${CALLBACK_SERVER}/v1/returns-portal-service/securepay-callback`;
const CONFIRM_URL = '';

/*
  The one digit summary of the transaction result:
  1 = Approved
  2 = Declined by the bank
  3 = Declined for any other reason
  4 = Cancelled by user
  Use “rescode” and “restext” for more detail of the transaction result.
*/
export const SECURE_PAY_SUMMARY_CODES = {
  APPROVED: 1,
  DECLINED_BY_BANK: 2,
  DECLINED_BY_OTHER: 3,
  CANCELLED: 4,
};

const formFieldsMapper = (item: {
  name: string;
  value: string | number;
}): string =>
  `<input type="hidden" name="${item.name}" value="${item.value}" />`;

const rawFormFieldsMapper = (item: {
  name: string;
  value: string | number;
}): string => `${item.name}=${encodeURIComponent(item.value)}`;

/*
  Secure Pay result sample (success)

  {
    "settdate":"20191105",
    "suramount":"0",
    "expirydate":"01/20",
    "amount":"100",
    "restext":"Approved",
    "merchant":"ABC0010",
    "baseamount":"100",
    "rescode":"00",
    "surfee":"0",
    "fingerprint":"63cb0c15d0783a58c60f734000c382fcf8a59908",
    "currency":"AUD",
    "refid":"123-456-7890",
    "cardtype":"Visa",
    "pan":"444433...111",
    "summarycode":"1",
    "txnid":"217386",
    "surrate":"0",
    "timestamp":"20191105123412"
  }
*/

export interface TSPResult {
  summaryCode: number;
  fingerprint?: string;
  transactionId?: string;
  timestamp?: string;
  transactionDate?: string;
  amount?: number;
  surAmount?: number;
  surFee?: number;
  surRate?: number;
  baseAmount?: number;
  currency?: string;
  cardExpDate?: string;
  cardtype?: string;
  pan?: string;
  resultText?: string;
  spMerchantId?: string;
  resCode?: string;
  referenceId?: string;
}

type Props = {
  amount: number;
  referenceId: string;
  onPaymentDone: (details: Object) => void;
  onPaymentCancel: () => void;
  onPaymentError: (error: number) => void;
  onLoadError: () => void;
};

type State = {
  loadCounter: number;
};

class SecureFrame extends Component<Props, State> {
  securePayMerchantId: string;

  transactionType: number;

  referenceId: string;

  amount: number;

  timestamp: string;

  fingerprint: string;

  formFields = [];

  __isMounted: boolean;

  constructor(props: Props) {
    super(props);

    this.init(props);

    this.state = {
      loadCounter: 0,
    };

    this.__isMounted = true;
  }

  init(props: Props) {
    this.securePayMerchantId = !IS_TEST
      ? SECURE_PAY_MERCHANT_ID
      : TEST_MERCHANT_ID; /* chnage to the real prod ID */
    this.transactionType = TXN_TYPE_CARD;
    this.referenceId = props.referenceId;
    this.amount = !IS_TEST ? props.amount : TEST_AMOUNT;
    this.timestamp = moment.utc().format('YYYYMMDDHHmmss');
    this.fingerprint = this.getFingerprint(
      this.securePayMerchantId,
      this.transactionType,
      this.referenceId,
      this.amount,
      this.timestamp
    );

    const onMessage = e => {
      if (e.origin !== CALLBACK_SERVER) return;
      this.onGetResult(e.data);
      window.removeEventListener('message', onMessage, false);
    };
    window.addEventListener('message', onMessage, false);

    // Setup the basic form fields, once we have established defaults
    this.formFields = [
      { name: 'bill_name', value: 'transact' },
      { name: 'merchant_id', value: this.securePayMerchantId },
      { name: 'primary_ref', value: this.referenceId },
      { name: 'txn_type', value: this.transactionType },
      { name: 'amount', value: this.amount },
      { name: 'fp_timestamp', value: this.timestamp },
      { name: 'fingerprint', value: this.fingerprint },
      { name: 'return_url', value: COMPLETE_URL },
      { name: 'return_url_text', value: 'Continue...' },
      { name: 'cancel_url', value: COMPLETE_URL },
      { name: 'template', value: 'iframe' },
      { name: 'callback_url', value: CONFIRM_URL },
      { name: 'confirmation', value: 'no' },
      { name: 'page_style_url', value: SECURE_PAY_IFRAME_CSS },
      { name: 'display_receipt', value: 'no' },
      { name: 'currency', value: 'AUD' },
    ];
  }

  decodeSPResults(data: string): TSPResult {
    let result: any = {};
    try {
      result = JSON.parse(data);
    } catch (e) {
      console.error('Cant decode callback from secureFrame');
    }

    const toNumber = (value?: string): number => {
      if (value === undefined) return;
      return Number(value);
    };

    return {
      summaryCode:
        toNumber(result.summarycode) || SECURE_PAY_SUMMARY_CODES.CANCELLED,
      fingerprint: result.summarycode,
      transactionId: result.txnid,
      timestamp: result.timestamp,
      transactionDate: result.settdate,
      amount: toNumber(result.amount),
      surAmount: toNumber(result.suramount),
      surFee: toNumber(result.surfee),
      surRate: toNumber(result.surrate),
      baseAmount: toNumber(result.baseamount),
      currency: result.currency,
      cardExpDate: result.expirydate,
      cardtype: result.cardtype,
      pan: result.pan,
      resultText: result.restext,
      spMerchantId: result.merchant,
      resCode: result.rescode,
      referenceId: result.refid,
    };
  }

  onGetResult = (data: string) => {
    const results = this.decodeSPResults(data);
    const { summaryCode } = results;
    switch (summaryCode) {
      case SECURE_PAY_SUMMARY_CODES.APPROVED:
        this.props.onPaymentDone(results);
        break;
      case SECURE_PAY_SUMMARY_CODES.DECLINED_BY_BANK:
        this.props.onPaymentError(summaryCode);
        break;
      case SECURE_PAY_SUMMARY_CODES.DECLINED_BY_OTHER:
        this.props.onPaymentError(summaryCode);
        break;
      case SECURE_PAY_SUMMARY_CODES.CANCELLED:
        this.props.onPaymentCancel();
        break;
      default:
        throw new Error('Illegal application state');
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.init(nextProps);
  }

  componentWillUnmount() {
    this.__isMounted = false;
  }

  componentDidMount() {
    if (isIE) {
      document.getElementById('secureFrameIE').onload = () => {
        this.setState({ loadCounter: 2 });
      };
    }
  }

  getFingerprint(
    securePayMerchantId: string,
    transactionType: number,
    referenceId: string,
    amount: number,
    timestamp: string
  ) {
    return SHA1(
      `${securePayMerchantId}|${TRANSACTION_PASSWORD}|${transactionType}|${referenceId}|${amount}|${timestamp}`
    );
  }

  handleLoad = () => {
    if (!this.__isMounted) return;
    this.setState(prevState => ({ loadCounter: prevState.loadCounter + 1 }));
  };

  getBase64EncodedURI(): string {
    const formAction = `${apiEndpoins.SECURE_PAY_URL}/secureframe/invoice`;
    return btoa(`
      <!DOCTYPE html>
      <html lang="en">
        <body onload="document.forms[0].submit()">
          <form action="${formAction}" method="post">
            ${this.formFields.map(formFieldsMapper).join('')}
          </form>
        </body>
      </html>
    `);
  }

  getEncodedURI(): string {
    const formAction = `${apiEndpoins.SECURE_PAY_URL}/secureframe/invoice`;
    return `${formAction}?${this.formFields
      .map(rawFormFieldsMapper)
      .join('&')}`;
  }

  render() {
    const { loadCounter } = this.state;
    const loadingState = loadCounter <= 1;

    return (
      <Wrapper>
        {loadingState && (
          <LoaderWrapper>
            <LoaderText>Loading</LoaderText>
            <LoaderDots>
              <div />
              <div />
              <div />
              <div />
            </LoaderDots>
          </LoaderWrapper>
        )}
        {isIE ? (
          // If the user is on IE, load a different iFrame
          <iframe
            title="SecureFrame Payment window"
            id="secureFrameIE"
            name="SecurePayFrame"
            height={loadingState ? 0 : 420}
            src={this.getEncodedURI()}
          />
        ) : (
          <Iframe
            id="secureFrame"
            loading="auto"
            display={loadingState ? 'none' : 'block'}
            position="relative"
            onLoad={this.handleLoad}
            url={`data:text/html;base64,${this.getBase64EncodedURI()}`}
          />
        )}
      </Wrapper>
    );
  }
}

export default SecureFrame;

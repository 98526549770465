import services from './services';
import whiteLabelStores from './stores';
import ui from './ui';
import utils from './_utils';
import { PRODUCT_NAME, TRACKING_EVENT_TYPES } from './_constants';

export default {
  stores: whiteLabelStores,
  services,
  ui,
  utils,
  constants: { PRODUCT_NAME, TRACKING_EVENT_TYPES },
};

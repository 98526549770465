import styled from 'styled-components';
import { Select as StyledSelect } from 'antd';
import { Icon } from '_common/components';

export const Root = styled.div`
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

export const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(151, 151, 151, 0.25);
`;

export const TabHeader = styled.div<{ active: boolean }>`
  font-size: 14px;
  font-weight: bold;

  text-align: center;
  width: 50%;
  color: #231f20;
  margin-right: 0;
  padding: 16px;
  border-bottom: ${({ active }) => (active ? '1px solid #231f20' : 'none')};
  cursor: pointer;
`;

export const TabWrapper = styled.div`
  position: relative;
  height: calc(100vh - 460px);
  min-height: 500px;

  @media only screen and (max-width: 768px) {
    min-height: initial;
    //height: calc(100vh - 124px);
    height: auto;
  }
`;

export const TabContent = styled.div<{ active: boolean }>`
  position: absolute;
  top: 0;
  display: ${({ active }) => (active ? 'block' : 'none')};
  width: 100%;
  height: 100%;
  @media only screen and (max-width: 768px) {
    position: initial;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const Input = styled.input<{ ref: any }>`
  width: 100%;
  height: 50px;
  font-size: 16px;
  color: #231f20;
  padding-right: 95px;
  padding-left: 20px;
  border: solid 1px rgba(151, 151, 151, 0.25);
`;

export const GeoIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  right: 22px;
  top: 13px;
`;

export const Select = styled(StyledSelect)`
  &.ant-select {
    color: #282d32;
    width: 100%;
    border: 1px solid rgba(151, 151, 151, 0.25);
    border-top: none;

    .ant-select-selection {
      height: 50px;
      border: none;

      .ant-select-selection__rendered {
        line-height: 50px;
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }
`;

export const StoreListWrapper = styled.div`
  height: calc(100vh - 540px);
  min-height: 500px;
  overflow-y: auto;

  // Medium devices (tablets, less than 992px)
  @media only screen and (max-width: 991.98px) {
    height: calc(100vh - 500px);
  }

  @media only screen and (max-width: 768px) {
    min-height: initial;
    height: calc(100vh - 450px);
  }

  @media only screen and (max-width: 450px) {
    height: calc(100vh - 400px);
  }
`;

export const InfoHeader = styled.div`
  font-size: 13px;
  display: flex;
  width: 200px;
`;

export const InfoTitle = styled.h4`
  flex-grow: 1;
  margin-right: 10px;
`;

export const InfoDistance = styled.div`
  font-size: 13px;
`;

export const InfoAddress = styled.div`
  margin-top: 5px;
  font-size: 12px;
`;

export const ClearButton = styled.div`
  font-size: 14px;
  cursor: pointer;
  color: rgb(199, 199, 204);
  text-decoration: underline;
  position: absolute;
  right: 55px;
  top: 14px;
`;

import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { reaction } from 'mobx';
import { compose } from 'recompose';
import { find } from 'lodash';
import { Beforeunload } from 'react-beforeunload';

import { Layout, AustraliaPost } from '_common/components';
import {
  OrderStore,
  PaymentPageStore,
  StartPageStore,
  DirectoryStore,
  ThemeStore,
} from 'stores';
import commonStoresActions from '_common/actions';
import { ConfirmButton, Title } from 'pages/start/elements';
import { AsyncStatus, PAYMENT_TYPES } from '_common/constants/common';
import {
  PrimaryText,
  SecondaryText,
  IframeErrorContainer,
  BackButton,
  Wrapper,
} from 'pages/payment/elements/elements';
import { isPaymentPageAvailable, createFormFromModel } from '_common/utils';
import {
  RightSectionImage,
  RightSectionWrapper,
} from '_common/global-elements/pages';

import ErrorModal from './ErrorModal';
import amplitude from '_common/utils/amplitude';
import { TSPResult } from '_common/components/AustraliaPost/SecureFrame/SecureFrame';
import { IRouterMatch } from 'types/core';

type Props = RouteComponentProps<IRouterMatch> &
  FormComponentProps & {
    paymentPageStore: PaymentPageStore;
    directoryStore: DirectoryStore;
    detailsPageStore: any;
    startPageStore: StartPageStore;
    themeStore: ThemeStore;
    orderStore: OrderStore;
  };

type State = {
  shipmentCreationStatus: string;
  activePaymentMethod: string;
  paymentStatus: string;
  onLoadError: boolean;
  refreshIframe: boolean;
  isGoForward: boolean;
  isErrorModalOpen: boolean;
  errorType: number;
};

@observer
class PaymentPage extends Component<Props, State> {
  static PAGE_NAME = 'Payment page';

  state = {
    activePaymentMethod: 'card',
    shipmentCreationStatus: AsyncStatus.IDLE,
    paymentStatus: AsyncStatus.IDLE,
    onLoadError: false,
    refreshIframe: false,
    isGoForward: false,
    isErrorModalOpen: false,
    errorType: null,
  };

  componentDidMount() {
    const {
      match: {
        params: { company },
      },
      directoryStore: {
        paymentConfig: { paymentType },
      },
    } = this.props;

    amplitude.logEventWithOrganisationAndUrl('page_open', {
      page_name: PaymentPage.PAGE_NAME,
    });

    reaction(
      () => this.props.directoryStore.returnReasons,
      (returnReasons, reactionFunc) => {
        reactionFunc.dispose();
        /** Check is selected on details page reason requires payments . */
        this.redirectIsPaymentNotRequired();
      }
    );

    /** Check payments configs */
    if (!isPaymentPageAvailable(paymentType)) {
      return this.props.history.push(`/${company}/success`);
    }

    this.redirectIsPaymentNotRequired();
  }

  redirectIsPaymentNotRequired = () => {
    const {
      match: {
        params: { company },
      },
      directoryStore: {
        returnReasons,
        paymentConfig: { paymentType },
      },
      detailsPageStore: {
        formFields: { returnReason: selectedByUserReturnReason },
      },
      orderStore: { isIntegratedFlow },
    } = this.props;

    if (isIntegratedFlow) {
      if (!commonStoresActions.isPaymentsRequiredForIntegrated()) {
        return this.props.history.push(`/${company}/success`);
      }
    } else {
      /** MERCHANT_PAID - always skipped. */
      if (paymentType === PAYMENT_TYPES.MERCHANT_PAID) {
        console.log('PAYMENT_TYPES.MERCHANT_PAID');
        return this.props.history.push(`/${company}/success`);
      }

      /** CONSUMER_PAID - depends of reason codes */
      if (paymentType === PAYMENT_TYPES.CONSUMER_PAID) {
        /** All reason codes disabled - consumer must pay. */
        if (returnReasons.reasons.every(({ enabled }) => !enabled)) {
          console.log('PAYMENT_TYPES.CONSUMER_PAID: all reasons disabled');
          return;
        }

        /** Reason code was selected, checking it's requires a payment. */
        const { enabled, paymentRequired } = find(returnReasons.reasons, {
          description: selectedByUserReturnReason,
        });

        /** If selected reason not requires payment and enabled (for double-check) - skip payments; */
        if (enabled && !paymentRequired) {
          console.log(
            'PAYMENT_TYPES.CONSUMER_PAID - selected reason not required payment.'
          );
          return this.props.history.push(`/${company}/success`);
        }
      }

      /** BOTH_PAID - this is depends of selected reasons. */
      if (paymentType === PAYMENT_TYPES.BOTH_PAID) {
        /** If selected reason not requires payment and enabled (for double-check) - skip payments; */
        const { enabled, paymentRequired } = find(returnReasons.reasons, {
          description: selectedByUserReturnReason,
        });
        if (enabled && !paymentRequired) {
          console.log(
            'PAYMENT_TYPES.CONSUMER_PAID - selected reason not required payment.'
          );
          return this.props.history.push(`/${company}/success`);
        }
      }
    }
  };

  goBack = ({ withConfirm = false }) => {
    const {
      match: {
        params: { company },
      },
    } = this.props;
    const message =
      'Do you really want to go back? Changes you made may not be saved.';

    const confirmedAction =
      process.env.NODE_ENV === 'production' && withConfirm
        ? window.confirm(message)
        : true;
    confirmedAction && this.props.history.push(`/${company}/details`);
  };

  goToNextPage = () => {
    const {
      match: {
        params: { company },
      },
    } = this.props;
    this.props.history.push(`/${company}/success`);
  };

  handleSuccessPayment = () => {
    this.setState({ paymentStatus: AsyncStatus.SUCCESS });
    this.goToNextPage();
  };

  handleErrorPayment = () => {
    this.setState({ paymentStatus: AsyncStatus.FAILED });
  };

  renderSecurePayButtons = ({ confirm }) => {
    const { shipmentCreationStatus, paymentStatus } = this.state;
    const onClick = () => {
      this.setState({ paymentStatus: AsyncStatus.LOADING });
      confirm();
    };

    return (
      <>
        <ConfirmButton
          onClick={onClick}
          disabled={
            [shipmentCreationStatus, paymentStatus].indexOf(
              AsyncStatus.LOADING
            ) !== -1
          }
        >
          Confirm
        </ConfirmButton>
        <BackButton onClick={this.goBack}>Back</BackButton>
      </>
    );
  };

  renderErrorPage = () => (
    <IframeErrorContainer>
      <div>
        <PrimaryText>An error has occurred</PrimaryText>
        <SecondaryText>Please retry or start again</SecondaryText>
      </div>
      <div>
        <ConfirmButton onClick={this.refreshIframe}>Retry</ConfirmButton>
        <BackButton onClick={this.goBack}>Start again</BackButton>
      </div>
    </IframeErrorContainer>
  );

  handleToggleErrorModal = () => {
    if (this.state.isErrorModalOpen === true) {
      this.setState({ isErrorModalOpen: false });
    } else {
      this.setState({ isErrorModalOpen: true });
    }
  };

  onLoadError = () => {
    this.setState({
      onLoadError: true,
    });
  };

  refreshIframe = () => {
    this.setState(prevState => ({ refreshIframe: !prevState.refreshIframe }));
  };

  /** Include to render methods to support PayHive payments system. But requires backend to be done. */
  renderSecurePay = () => (
    <AustraliaPost.SecurePayUi
      renderButtons={this.renderSecurePayButtons}
      renderErrorPage={this.renderErrorPage}
      onSuccessPayment={this.handleSuccessPayment}
      onErrorPayment={this.handleErrorPayment}
    />
  );

  renderCommonPaymentsBlock = () => {
    const {
      activePaymentMethod,
      shipmentCreationStatus,
      isErrorModalOpen,
      errorType,
    } = this.state;
    return (
      <>
        {activePaymentMethod === 'card' && this.renderSecurePayFrame()}
        {activePaymentMethod !== 'card' && (
          <div>
            <ConfirmButton
              onClick={this.goToNextPage}
              disabled={shipmentCreationStatus === AsyncStatus.LOADING}
            >
              Confirm
            </ConfirmButton>
            <BackButton onClick={this.goBack}>Back</BackButton>
          </div>
        )}
        {isErrorModalOpen && (
          <ErrorModal
            errorType={errorType}
            toggle={this.handleToggleErrorModal}
          />
        )}
      </>
    );
  };

  getCompanyId() {
    const {
      match: {
        params: { company },
      },
    } = this.props;
    return company;
  }

  getForwardLink() {
    return `/${this.getCompanyId()}/success`;
  }

  onPaymentDone = (details: TSPResult) => {
    commonStoresActions.setConsumerPaymentWasSuccessful(details);
    amplitude.logEventWithOrganisationAndUrl('payment happens');

    this.setState({ isGoForward: true });
  };

  /**
   * On Payment error, update error state and then toggle the error modal
   */
  onPaymentError = (error: number) => {
    console.error(`Secure Pay iframe error code: ${error}`);

    // Wait for state set before toggle
    this.setState({ errorType: error }, () => {
      if (this.state.isErrorModalOpen !== true) {
        this.handleToggleErrorModal();
      }
      this.refreshIframe();
    });
  };

  renderSecurePayFrame = () => {
    const {
      directoryStore: {
        paymentConfig: { paymentType, price },
      },
      startPageStore: {
        formFields: { orderNumber },
      },
    } = this.props;

    if (!isPaymentPageAvailable(paymentType)) {
      return null;
    }

    // @ts-ignore
    const amount = Math.round(price * 100); // TODO: Number??

    return (
      <>
        <AustraliaPost.SecureFrame
          amount={amount}
          referenceId={orderNumber}
          onPaymentDone={this.onPaymentDone}
          onPaymentCancel={() => this.goBack({ withConfirm: false })}
          onPaymentError={this.onPaymentError}
          onLoadError={this.onLoadError}
        />
        <BackButton onClick={this.goBack}>Back</BackButton>
      </>
    );
  };

  render() {
    const {
      directoryStore: {
        paymentConfig: { price },
      },
      themeStore: { widthOfBackgroundImage },
    } = this.props;

    const { onLoadError, isGoForward } = this.state;
    const isAutoPageTransition = isGoForward;

    return (
      <Beforeunload
        onBeforeunload={() => {
          if (isAutoPageTransition) return;
          return 'Are you sure you want to close? Your payment is in progress.';
        }}
      >
        {isGoForward && <Redirect push to={this.getForwardLink()} />}
        <Wrapper>
          <Layout.Container>
            <Layout.LeftSection>
              {price ? (
                <>
                  <Title>Return payment : ${price}</Title>
                  {this.renderCommonPaymentsBlock()}
                </>
              ) : onLoadError ? (
                this.renderErrorPage()
              ) : (
                <Title>Price not provided!</Title>
              )}
            </Layout.LeftSection>
            <Layout.RightSection
              widthOfBackgroundImage={widthOfBackgroundImage}
            >
              <RightSectionWrapper>
                <RightSectionImage />
              </RightSectionWrapper>
            </Layout.RightSection>
          </Layout.Container>
        </Wrapper>
      </Beforeunload>
    );
  }
}

export default compose(
  inject(
    'paymentPageStore',
    'directoryStore',
    'detailsPageStore',
    'startPageStore',
    'themeStore',
    'orderStore'
  ),
  Form.create<Props>({
    mapPropsToFields(props) {
      return createFormFromModel(props.paymentPageStore.formFields, Form);
    },
    onValuesChange(props, fields) {
      const keys = Object.keys(fields);
      if (!keys.length) {
        return;
      }

      // @ts-ignore
      const { name, value } = fields[keys[0]];

      props.paymentPageStore.setFormField(name, value);
    },
  })
)(PaymentPage);

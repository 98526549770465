const storesToBeCleared = [
  'startPageStore',
  'detailsPageStore',
  'locationStore',
  'whitelabelProxyStore',
  'trackingStore',
];

export default stores =>
  storesToBeCleared.forEach(storeName => stores[storeName].resetStore());

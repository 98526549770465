import styled from 'styled-components';

export const TrackersList = styled.div`
  flex-grow: 1;
  padding-left: 42px;
`;

const getBgColor = ({ inactive }) =>
  inactive ? '#e6e7e8;' : 'rgb(56, 177, 58);';
export const Tracker = styled.div<{ inactive: boolean }>`
  width: 100%;
  display: flex;
  padding: ${props => (props.inactive ? '15px 0;' : '10px 0;')}
  position: relative;

  &:after {
    content: '';
    width: 4px;
    height: 70px;
    background-color: ${getBgColor};
    display: block;
    position: absolute;
    top: 50px;
    left: 18px;
  }

  &:last-of-type {
    &:after {
      display: none;
    }
  }
`;
export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;
export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 35px;
`;
export const PrimaryText = styled.div<{ inactive: boolean }>`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 24px;
  color: ${props => (props.inactive ? '#a9a9a9' : '#000000')};
`;
export const SecondaryText = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 16px;
  color: #9b9b9b;
`;

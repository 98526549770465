// eslint-disable-next-line max-classes-per-file
import React from 'react';
import { StyledBaseInput, StyledInput, StyledTextArea } from './elements';

type Props = {
  [key: string]: any;
  getRef?: (ref: React.Ref<React.Component>) => void;
};

export const BaseInput = (props: Props) => <StyledBaseInput {...props} />;

export class Input extends React.Component<Props> {
  inputRef = (ref: React.Ref<React.Component>) => {
    if (this.props.getRef) {
      this.props.getRef(ref);
    }
  };

  render() {
    const { getRef, ...rest } = this.props; // excluded getRef prop. DO NOT DELETE!!!

    return <StyledInput innerRef={this.inputRef} {...rest} />;
  }
}

export class TextArea extends React.Component<Props> {
  textAreaRef = (ref: React.Ref<React.Component>) => {
    if (this.props.getRef) {
      this.props.getRef(ref);
    }
  };

  render() {
    const { getRef, ...rest } = this.props; // excluded getRef prop. DO NOT DELETE!!!

    return <StyledTextArea innerRef={this.textAreaRef} {...rest} />;
  }
}

export default {
  BaseInput,
  Input,
  TextArea,
};

import Button from 'antd/lib/button';
import styled, { AnyStyledComponent, css } from 'styled-components';
import { darken } from 'polished';

const colors = css`
  background-color: ${({ theme }) => theme.config.css.accentColor || '#4a90e2'};
  color: #fff;
`;

const StyledBaseButton: AnyStyledComponent = styled(Button)`
  && {
    text-align: center;
    width: 100%;
    height: 63px;
    border: none;
    cursor: pointer;
    border-radius: 3px;
    font-family: ${props => props.theme.fonts.basic};
    font-size: 23px;

    &:hover {
      opacity: 0.7;
    }

    &:disabled,
    &:hover:disabled {
      background-color: #e1e0e1;
      color: #fff;
    }

    @media only screen and (max-width: 768px) {
      height: 48px;
      font-size: 20px;
    }
  }
`;

export const StyledButton: AnyStyledComponent = styled(StyledBaseButton)`
  && {
    ${colors};

    &:focus,
    &:active {
      ${colors};
    }

    &:focus,
    &:hover {
      background-color: ${({ theme }) =>
        darken(0.3, theme.config.css.accentColor || '#4a90e2')};
      color: #fff;
    }

    ${(props: any) =>
      props['disabled-styles'] === 'true' &&
      css`
        background-color: #e1e0e1;
        color: #fff;

        &:hover,
        &:active,
        &:focus {
          background-color: #e1e0e1;
          color: #fff;
        }
      `};
  }
`;

export const StyledButtonLink: AnyStyledComponent = styled(StyledButton)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

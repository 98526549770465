import React, { Component } from 'react';
import { Map, GoogleApiWrapper } from 'google-maps-react';
import { observer, inject } from 'mobx-react';
import { invoke } from 'lodash';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { reaction } from 'mobx';

import commonStoresActions from '_common/actions';
import amplitude from '_common/utils/amplitude';
import SearchHeader from '../SearchHeader';
import Marker from '../Marker/Marker';
import { MapBackground } from './DesktopMapElements';
import { GOOGLE_MAP_DEFAULT_STYLES } from '_common/constants/googleMap';
import { withWhitelabelProps, WHITELABEL_UI } from '_common/whitelabelConfig';

import { getMapVisibleDistance } from 'pages/success/utils/locationUtils';
import { IStore, LatLng } from 'types/store';
import { ILocationStore } from 'types/mobxStores';
import { IRouterMatch } from 'types/core';
import { GOOGLE_API_KEY } from '_common/constants/common';

interface WrapperProps extends RouteComponentProps<IRouterMatch> {
  initialCenter: LatLng;
  zoom: number;
  google: any;
  map?: any;
  locationStore: ILocationStore;
  integratedFlow?: boolean;
  orderCreateSuccess: boolean;
  labelLoading: boolean;
  whiteLabeled: any;
  onMapInit: () => void;
}
@observer
class Contents extends Component<WrapperProps> {
  searchHeaderRef = React.createRef();

  disposeReaction: any;

  state = {
    bounds: null,
  };

  componentDidMount() {
    this.props.locationStore.hideMapForInitialState();
    this.initAutocomplete();
    this.disposeReaction = reaction(
      () => this.props.locationStore.stores,
      () => {
        this.setBounds();
      }
    );
  }

  setBounds = () => {
    const {
      locationStore: {
        stores,
        isNewLocationSearch,
        mapDragActive,
        clientGeoCoordinates,
      },
      google,
    } = this.props;
    if (!isNewLocationSearch || mapDragActive) return;
    const bounds = new google.maps.LatLngBounds();
    if (!stores.length) {
      bounds.extend({
        lat: clientGeoCoordinates.lat - 0.1,
        lng: clientGeoCoordinates.lng - 0.1,
      });
      bounds.extend({
        lat: clientGeoCoordinates.lat + 0.1,
        lng: clientGeoCoordinates.lng + 0.1,
      });
    } else {
      stores.forEach(({ geo }) => {
        bounds.extend({ lat: geo.lat, lng: geo.lon });
      });
    }
    this.setState({ bounds });
  };

  componentWillUnmount() {
    this.disposeReaction();
  }

  componentDidUpdate(prevProps) {
    if (this.props.map !== prevProps.map) {
      this.initAutocomplete();
    }
    if (
      this.props.labelLoading !== prevProps.labelLoading &&
      !this.props.labelLoading
    ) {
      this.setBounds();
    }
  }

  logFieldEvent = (eventName, extraPayload?: any) => {
    const { href: url } = window.location;
    const { company: retailerName } = this.props.match.params;
    amplitude.logEvent(eventName, {
      url,
      retailerName,
      ...extraPayload,
    });
  };

  initAutocomplete() {
    const { google, map, whiteLabeled: countryCode } = this.props;
    const searchHeader: any = this.searchHeaderRef.current;
    const searchInput = searchHeader
      ? invoke(searchHeader.wrappedInstance, 'getSearchInput')
      : null;
    if (!google || !map || !searchInput) return;

    const autocomplete = new google.maps.places.Autocomplete(searchInput, {
      types: ['geocode'],
      componentRestrictions: { country: [countryCode.countryCode] },
    });
    autocomplete.bindTo('bounds', map);
    autocomplete.setComponentRestrictions({
      country: [countryCode.countryCode],
    });

    if (searchInput.value && searchInput.value.length > 0) {
      const geocoder = new google.maps.Geocoder();

      geocoder.geocode(
        {
          address: searchInput.value,
          componentRestrictions: {
            country: countryCode.countryCode,
          },
        },

        async (results, status) => {
          if (status === 'OK') {
            const position = results[0].geometry.location;
            await commonStoresActions.setSearchGeoCoordinates({
              lat: position.lat(),
              lng: position.lng(),
            });
            commonStoresActions.searchFromLastLocation(true);
          }
        }
      );
    }

    autocomplete.addListener('place_changed', () => {
      this.logFieldEvent('click search', { locationInput: searchInput.value });
      const place = autocomplete.getPlace();

      if (!place.geometry) return;

      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(14);
      }

      const position = place.geometry.location;

      commonStoresActions.setSearchGeoCoordinates({
        lat: position.lat(),
        lng: position.lng(),
      });
    });
  }

  handleDrag = async (mapProps, map) => {
    const { google } = this.props;
    const newCenter = {
      lat: map.center.lat(),
      lng: map.center.lng(),
    };
    const distance = getMapVisibleDistance(map, google);
    try {
      await commonStoresActions.searchStoresByCoords(newCenter, true, distance);
    } catch (error) {
      console.error('Error while dragging', error);
    }
  };

  renderMarker = (storeData: IStore) => {
    const { storeId, geo, locationType, companyId } = storeData;
    const {
      locationStore: { assetsHub },
    } = this.props;
    const assets = assetsHub.get(companyId);

    return (
      <Marker
        key={storeId}
        storeId={storeId}
        lat={geo.lat}
        lng={geo.lon}
        locationType={locationType}
        merchantPins={assets && assets.pins}
        isDesktop
      />
    );
  };

  renderMap() {
    const {
      locationStore: {
        stores,
        clientGeoCoordinates,
        lastSearch,
        isMapShouldBeVisible,
      },
      integratedFlow,
      labelLoading,
      orderCreateSuccess,
    } = this.props;

    const isShowMap =
      !!lastSearch &&
      isMapShouldBeVisible &&
      !labelLoading &&
      orderCreateSuccess;
    const cssOffset = integratedFlow
      ? 'calc(100vh - 170px)'
      : 'calc(100vh - 271px)';

    return (
      <>
        {isShowMap ? (
          <Map
            {...this.props}
            centerAroundCurrentLocation={false}
            bounds={this.state.bounds}
            containerStyle={{
              height: isShowMap ? cssOffset : 0,
              position: 'relative',
            }}
            visible={isShowMap}
            // @ts-ignore
            styles={GOOGLE_MAP_DEFAULT_STYLES}
            onDragend={this.handleDrag}
          >
            <Marker
              isDefaultUser
              lat={clientGeoCoordinates.lat}
              lng={clientGeoCoordinates.lng}
            />
            {stores.map(this.renderMarker)}
          </Map>
        ) : (
          <MapBackground integratedFlow={integratedFlow} />
        )}
      </>
    );
  }

  render() {
    const {
      match: {
        params: { company },
      },
      locationStore: { isLoading },
      integratedFlow,
      labelLoading,
      whiteLabeled: { successSearchPlaceholder },
    } = this.props;
    return (
      <div style={{ position: integratedFlow ? undefined : 'relative' }}>
        <SearchHeader
          ref={this.searchHeaderRef}
          logFieldEvent={this.logFieldEvent}
          company={company}
          disabled={isLoading || labelLoading}
          integratedFlow={integratedFlow}
          whiteLabeled={{ successSearchPlaceholder }}
        />
        {this.renderMap()}
      </div>
    );
  }
}

const MapWrapper = (props: WrapperProps) => {
  const containerStyle = {
    width: '100%',
    height: 'calc(100vh - 170px)',
    position: props.integratedFlow ? undefined : 'relative',
    top: 0,
  };
  return (
    <Map {...props} containerStyle={containerStyle} visible={false}>
      <Contents {...props} />
    </Map>
  );
};

MapWrapper.defaultProps = {
  initialCenter: WHITELABEL_UI.common.defaultMapCenter,
  zoom: 5,
};

export default compose(
  GoogleApiWrapper({
    apiKey: GOOGLE_API_KEY,
    libraries: ['places', 'geometry'],
  }),
  withRouter,
  inject('locationStore'),
  withWhitelabelProps({
    countryCode: 'ui.common.countryCode',
    successSearchPlaceholder:
      'ui.pages.success.textValues.successSearchPlaceholder',
  }),
  observer
)(MapWrapper);

import postlogo from 'assets/images/auspost.png';
import React from 'react';
import { StyledpostLogo } from './elements';

function PostOfficeLogo() {
  return (
    <StyledpostLogo>
      <img src={postlogo} alt="Post office" />
    </StyledpostLogo>
  );
}

export default PostOfficeLogo;

import { Button, Layout } from '_common/components';
import styled, { css } from 'styled-components';

export const Title = styled.h2`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.3px;
  color: #000000;
  margin-top: 20px;

  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const SubText = styled.p<{ breakLine: boolean }>`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 16px;
  font-weight: 300;
  line-height: 1.71;
  color: #000000;
  @media only screen and (min-width: 768px) {
    ${({ breakLine }) => breakLine && 'white-space: pre-line;'}
  }

  a,
  a:visited,
  a:hover,
  a:active {
    text-decoration: underline;
    display: block;
    color: #000000;
  }

  a:focus {
    outline: 2px solid rgba(24, 144, 255, 0.2);
  }

  margin-bottom: 45px;
`;

export const Wrapper = styled.div`
  ${Layout.Container} {
    height: calc(100vh - 170px);
    max-height: calc(100vh - 170px);

    @media only screen and (max-width: 768px) {
      height: auto;
      max-height: initial;
    }
  }
  ${Layout.LeftSection} {
    padding-right: 32px;
    padding-left: 39px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 250px);
    margin-bottom: 80px;
  }

  .has-error .ant-input {
    border-color: #ff3654 !important;
  }

  @media only screen and (max-width: 768px) {
    flex: 1;
    ${Layout.LeftSection} {
      padding: 0 20px;
      width: 100%;
      min-height: auto;
      margin-bottom: 0;
    }
  }
`;

export const ConfirmButton = styled(Button)`
  && {
    margin-top: 10px;
  }
`;

export const FormErrorMessage = styled.div`
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  color: #d0021b;
  max-width: 350px;

  ${props =>
    props.hidden &&
    css`
      display: none;
    `}
`;

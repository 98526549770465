import React from 'react';
import { NavLink } from 'react-router-dom';
import { StyledLogo, LogoWrapper } from './elements';

type Props = {
  url: string;
  alt: string;
  path: string;
  alignToLeft: boolean;
};

const Logo = (props: Props) => (
  <NavLink to={props.path} tabIndex={-1}>
    <LogoWrapper alignToLeft={props.alignToLeft}>
      <StyledLogo {...props} src={props.url} alt={props.alt} />
    </LogoWrapper>
  </NavLink>
);

export default Logo;

import { action, observable } from 'mobx';
import { sessionStore, MOBX_STORES } from 'storage';
import { getMerchantFromUrl } from '_common/utils';
import { IStartStore } from 'types/mobxStores';

class StartPageStore implements IStartStore {
  static FORM_FIELDS = {
    email: null,
    orderNumber: null,
    rmaNumber: null,
    ranNumber: null,
  };

  @observable
  formFields = { ...StartPageStore.FORM_FIELDS };

  registerCommonActions = commonStoresActions => {
    commonStoresActions.validateSession(getMerchantFromUrl());
    this.formFields = {
      ...this.formFields,
      ...sessionStore.get(MOBX_STORES.StartPageStore),
    };
  };

  @action
  getFormFields = () => this.formFields;

  @observable
  showFormErrorMessage: string | false = false;

  @action
  setFormField = (field: object) => {
    Object.keys(field).forEach(key => {
      this.formFields[key] = field[key];
    });
  };

  @action
  enableFormErrorMessage = (error: Error | string) => {
    this.showFormErrorMessage =
      typeof error === 'string' ? error : error.message;
  };

  @action
  resetStore = () => {
    this.formFields = { ...StartPageStore.FORM_FIELDS };
  };
}

export default StartPageStore;

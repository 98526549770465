import {
  ausPostLogoImage,
  auspostStackedLogo,
  facebookIcon,
  twitterIcon,
  linkedinIcon,
  parcelFilled,
  printerDarkIcon,
  markerWithDot,
} from 'assets';
import { isIntergratedFlow } from '../_utils';
import { validateOnSpecialCharacters } from '_common/utils';
import { TRACKING_EVENT_TYPES } from '../_constants';
import { Heading } from 'pages/details/components/ReturnReasons/elements';
import React from 'react';
import { PrintSection, StepsList } from 'pages/success/elements';
import Step from 'pages/success/components/Step';

export default {
  common: {
    carrierLogo: ausPostLogoImage,
    logoDimensions: {
      width: 318,
      height: 45,
    },
    countryCode: 'AU',
    footer: {
      logo: { src: auspostStackedLogo, alt: 'Easy returns by Australia Post' },
      termsAndConditions: {
        href:
          'https://auspost.com.au/terms-conditions/general-terms-conditions',
        label: 'Australia Post Terms & Conditions',
      },
      privacy: {
        href: 'https://auspost.com.au/privacy',
        label: 'Australia Post Privacy Policy',
      },
      socialLinks: [
        {
          href: 'https://www.facebook.com/australiapost',
          src: facebookIcon,
          alt: 'facebook',
        },
        {
          href: 'https://twitter.com/auspost',
          src: twitterIcon,
          alt: 'twitter',
        },
        {
          href: 'https://www.linkedin.com/company/australia-post/',
          src: linkedinIcon,
          alt: 'linkedin',
        },
      ],
    },
    defaultMapCenter: {
      lat: -37.80205068,
      lng: 144.95724385,
    },
  },
  pages: {
    start: {
      introText:
        'Enter your order information to start the returns process. \nOne returns request should be created for each parcel to be returned.',
      orderIdRegex: {
        // eslint-disable-next-line no-useless-escape
        regexValue: /([}{\[\]<>!$%^*])/,
        errorText: 'Those "<>[]}{}!$%^*" characters aren\'t allowed',
      },
      returnIsFreeText: 'Your return is FREE',
      ranPlaceholder: 'Return authorisation number',
    },
    details: {
      getStateLabel: item => item.value,
      showReturnItems: false,
      textValues: {
        cityLabel: 'City / Suburb',
        cityPlaceholder: 'Enter your city or suburb',
        zipText: 'Postcode',
        zipLength: 4,
        zipPlaceholder: '0000',
        zipPattern: /^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/,
        zipError: 'Invalid Australian postcode.',
        tcUrl:
          'https://auspost.com.au/content/dam/auspost_corp/media/documents/easy-returns-terms-and-conditions.pdf',
        countryStates: [
          { value: 'ACT', label: 'Australian Capital Territory' },
          { value: 'NT', label: 'Northern Territory' },
          { value: 'NSW', label: 'New South Wales' },
          { value: 'QLD', label: 'Queensland' },
          { value: 'SA', label: 'South Australia' },
          { value: 'TAS', label: 'Tasmania' },
          { value: 'VIC', label: 'Victoria' },
          { value: 'WA', label: 'Western Australia' },
        ],
        addressInvalidMsg: 'Entered address not found.',
      },
      rules: {
        fullName: [
          {
            required: true,
          },
          {
            pattern: /([a-z]+(-[a-z]+)*\s*)+$/i,
            message: 'Full name must contains only letters and spaces',
          },
          {
            validator: (
              rule: any,
              value: string,
              callback: (error?: Error) => void
            ) => {
              validateOnSpecialCharacters(rule, value, callback);
            },
          },
        ],
        addressLine1: [
          {
            required: true,
          },
          {
            validator: (
              rule: any,
              value: string,
              callback: (error?: Error) => void
            ) => {
              validateOnSpecialCharacters(rule, value, callback);
            },
          },
        ],
        addressLine2: [
          {
            validator: (
              rule: any,
              value: string,
              callback: (error?: Error) => void
            ) => {
              validateOnSpecialCharacters(rule, value, callback);
            },
          },
        ],
        city: [
          {
            required: true,
          },
          {
            validator: (
              rule: any,
              value: string,
              callback: (error?: Error) => void
            ) => {
              validateOnSpecialCharacters(rule, value, callback);
            },
          },
        ],
      },
      noReasonsText: <Heading>No return reasons available</Heading>,
      noActiveReasonsAllowed: false,
      integratedReasonCommentsEnabled: false,
      reasonCommentsRequiredText: 'Return reason comment is required.',
    },
    tracking: {
      trackersList: [
        {
          type: TRACKING_EVENT_TYPES.ORDER_RETURN_CREATED,
          description: 'Return requested',
        },
        {
          type: TRACKING_EVENT_TYPES.CARRIER_COLLECTED,
          description: 'On its way back',
        },
        {
          type: TRACKING_EVENT_TYPES.BACK_AT_WAREHOUSE,
          description: 'Back at warehouse',
        },
      ],
    },
    success: {
      renderIntegratedSteps: config => (
        <StepsList>
          <Step
            text="Package your items for return"
            caption="Step 1. Pack your items"
            imageUrl={parcelFilled}
          />
          <Step caption="Step 2. Attach label" imageUrl={printerDarkIcon}>
            <PrintSection integratedFlow>
              {config.button}
              {config.subtitle}
            </PrintSection>
          </Step>
          <Step
            text="Search a location and drop it off"
            caption="Step 3. Search location"
            imageUrl={markerWithDot}
          />
        </StepsList>
      ),
      isIntergratedFlowViewActive: isIntergratedFlow,
      isLocationTypesSelectVisible: true,
      textValues: {
        infoPanelText:
          'Australia Post does not have permission for this browser to use your location',
        errorStatusText: 'Please retry or contact your retailer.',
        successStatusText:
          'Your return is booked, we’ve sent you an email with everything you need!',
        successSearchPlaceholder:
          'Search for drop off locations by postcode, city, suburb',
        getSuccessMessage: consumerPaymentWasSuccessful =>
          consumerPaymentWasSuccessful
            ? {
                main: 'Success, payment received.',
                secondary:
                  'We`ve sent your return details and a tax invoice to your email address.',
              }
            : {
                main: 'Success, return booked!',
                secondary:
                  'We`ve sent your return details to your email address.',
              },
        getSuccessSubtitle: () => null,
      },
      distanceUnit: 'km',
      storeLogosEnabled: true,
    },
  },
};

import styled from 'styled-components';
import { Layout } from '_common/components';

export const Wrapper = styled.div`
  ${Layout.LeftSection} {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }

  @media only screen and (max-width: 768px) {
    ${Layout.LeftSection} {
      padding: 0;
      border: none;
      width: 100%;
    }
  }
`;

export const Container = styled(Layout.Container)`
  background-color: #f8f8f8;
`;

export const PrimaryText = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 24px;
  font-weight: bold;
  line-height: 36px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #231f20;
  padding: 0 42px;
`;

export const SecondaryText = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 20px;
  line-height: 1.5;
  color: #231f20;
  margin-bottom: 60px;
  padding: 0 42px;

  span {
    margin-right: 5px;
  }
  span:first-child {
    display: inline-block;
    width: 180px;
  }

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const MerchantLogo = styled.div`
  background-image: url(${({ theme }) => theme.assets.logoImage});
  background-position: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  min-width: inherit !important;
  height: 80px;
  margin-left: 42px;
  margin-top: 20px;
  margin-bottom: 44px;
`;

export const RootLogo = styled.div<{ image: string }>`
  width: 200px;
  height: 50px;
  margin: 25px auto;
  background-image: url(${({ image }) => image});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const DesktopLogo = styled(RootLogo)`
  margin-left: auto;
  margin-right: 30px;
  width: 320px;
  height: 50px;
`;

export const MobileLogo = styled(RootLogo)<{ image: string }>`
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

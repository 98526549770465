import Logo from '_common/components/Logo/Logo';
import backBtn from 'assets/images/back-arrow.svg';
import { Theme } from 'themes/defaultTheme';
import { observer } from 'mobx-react';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';
import { withTheme } from 'styled-components';
import {
  BackButton,
  HeaderWrapper,
  LogoWrapper,
  CustomerLogo,
} from './elements';
import { includes } from 'lodash';
import { withWhitelabelProps } from '_common/whitelabelConfig';
import { getMerchantFromUrl } from '_common/utils';
import { IRouterMatch } from 'types/core';

interface Props extends RouteComponentProps<IRouterMatch> {
  theme: Theme;
  whiteLabeled: any;
}

@observer
class Header extends React.Component<Props, any> {
  onBack = () => {
    this.props.history.goBack();
  };

  isHidden = () => {
    const disabledOn = ['', '/', '/not-found'];
    const { pathname } = this.props.location;

    // Check to see if we're on the default merchant path
    const merchant = getMerchantFromUrl();
    if (merchant) {
      const basePath = `/${merchant}`.toUpperCase();
      if (pathname.toUpperCase().endsWith(basePath)) {
        return true;
      }
    }

    if (includes(pathname, 'success')) {
      return true;
    }

    return !!disabledOn.includes(pathname.replace(/\/$/, ''));
  };

  render() {
    const {
      theme: {
        assets: { logoImage },
      },
      whiteLabeled: {
        carrierLogo,
        logoDimensions: { width, height },
      },
      location: { pathname },
    } = this.props;

    const alignToLeft = includes(pathname, 'success');
    return (
      <HeaderWrapper>
        <LogoWrapper>
          <BackButton onClick={this.onBack} hidden={this.isHidden()}>
            <img src={backBtn} alt="back button" />
          </BackButton>
          <Logo
            path="#"
            url={logoImage}
            alt="merchant logo"
            alignToLeft={alignToLeft}
          />
        </LogoWrapper>
        <CustomerLogo src={carrierLogo} width={width} height={height} />
      </HeaderWrapper>
    );
  }
}

export default compose(
  withTheme,
  withRouter,
  withWhitelabelProps({
    carrierLogo: 'ui.common.carrierLogo',
    logoDimensions: 'ui.common.logoDimensions',
  })
)(Header);

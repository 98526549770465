import { action, computed, observable, ObservableMap } from 'mobx';
import { sessionStore, MOBX_STORES } from 'storage';
import { getMerchantFromUrl, extractReasonsFromProduct } from '_common/utils';
import { CommonActions } from '_common/actions';
import amplitude from '../../../utils/amplitude';
import { IReason, IDummyReason } from 'types/company';
import { IDetailsStore } from 'types/mobxStores';
import { IOrderProductModel } from 'types/order';

const ITEMS_AMPLITUDE_EVENTS = {
  ADDED: 'Item added to return list',
};

class DetailsPageStore implements IDetailsStore {
  static FORM_FIELDS = {
    purchaseDate: null,
    returnReason: undefined,
    fullName: null,
    addressLine1: null,
    addressLine2: null,
    city: null,
    postcode: null,
    state: undefined,
    confirmTerms: undefined,
    phoneNumber: null,
    reasonComments: null,
  };

  commonStoresActions: CommonActions;

  @observable
  formFields = { ...DetailsPageStore.FORM_FIELDS };

  @observable
  showFormErrorMessage: string | false = false;

  @observable
  returningProductId: string | null = null;

  @observable
  selectedReturnReasonsForProduct: ObservableMap<
    string,
    IReason | IDummyReason
  > = observable.map();

  registerCommonActions = (commonStoresActions: CommonActions) => {
    this.commonStoresActions = commonStoresActions;
    commonStoresActions.validateSession(getMerchantFromUrl());
    this.formFields = {
      ...this.formFields,
      ...sessionStore.get(MOBX_STORES.DetailsPageStore),
    };
  };

  @computed
  get isAnyProductSelectedForReturn() {
    return this.selectedReturnReasonsForProduct.size > 0;
  }

  @action
  setFormField = (field: string, value: string) => {
    this.formFields[field] = value;
  };

  saveFormToStorage() {
    this.commonStoresActions.saveToStorage(
      MOBX_STORES.DetailsPageStore,
      this.formFields
    );
  }

  @action
  enableFormErrorMessage = (error: string) => {
    this.showFormErrorMessage = error;
  };

  @action
  resetStore = () => {
    this.formFields = { ...DetailsPageStore.FORM_FIELDS };
  };

  @action
  setReturningProductId = (productId: string | null) => {
    if (productId === null) {
      return (this.returningProductId = null);
    }

    if (this.returningProductId === productId) {
      this.returningProductId = null;
    } else {
      amplitude.logEventWithOrganisationAndUrl(ITEMS_AMPLITUDE_EVENTS.ADDED, {
        returningProductId: productId,
      });
      this.returningProductId = productId;
    }
  };

  @action
  setSelectedReturnReasonsForProduct = (
    product: IOrderProductModel,
    returnReason: IReason | IDummyReason
  ) => {
    this.selectedReturnReasonsForProduct.set(product.productId, returnReason);
    amplitude.logEventWithOrganisationAndUrl(
      'Selected return reason for product',
      { returningProductId: product, returnReason: returnReason.description }
    );
  };

  @action
  clearSelectedReturnReasonsForProduct = () => {
    this.selectedReturnReasonsForProduct.clear();
  };

  @action
  deleteSelectedReturnReasonsForProduct = (productId: string) => {
    this.selectedReturnReasonsForProduct.delete(productId);
  };

  populateOrderWithReturnReasons = order =>
    extractReasonsFromProduct(
      order,
      this.selectedReturnReasonsForProduct.get(order.productId)
    );
}

export default DetailsPageStore;

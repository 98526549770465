import { apiClient } from '_common/api/clients/clients';

const getTrackingInfo = async (trackingId): Promise<any> => {
  const response = await apiClient.get(`v1/tracking/anyRef/${trackingId}`);
  return response.data.events;
};

export default {
  getTrackingInfo,
};

import { Icon } from 'antd';
import React from 'react';
import { SearchInputStyles } from './elements';
import { omit } from 'lodash';

type Props = {
  [key: string]: any;
  onGeoLocate: () => void;
};

const SearchIcon = (props: any) => {
  const Svg = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <path
        fill="#231F20"
        fillRule="nonzero"
        stroke="#FFF"
        d="M28.23 28.23a2.63 2.63 0 0 1-3.72 0l-4.64-4.641a12.173 12.173 0 0 1-6.599 1.953C6.494 25.542 1 20.048 1 13.271 1 6.494 6.494 1 13.271 1c6.777 0 12.271 5.493 12.271 12.271 0 2.434-.73 4.69-1.953 6.6l4.64 4.64a2.629 2.629 0 0 1 0 3.719zM13 4a9 9 0 0 0-9 9 9 9 0 0 0 9 9 9 9 0 0 0 9-9 9 9 0 0 0-9-9z" // tslint:disable-line
      />
    </svg>
  );

  return <Icon component={Svg} {...props} />;
};

const GeoIcon = (props: any) => {
  const Svg = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      onClick={props.onClick}
    >
      <path
        fill="#231F20"
        fillRule="nonzero"
        d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm.8 22.36V18.4a.8.8 0 0 0-1.6 0v3.96a10.391 10.391 0 0 1-9.562-9.56H5.6a.8.8 0 0 0 0-1.6H1.638A10.39 10.39 0 0 1 11.2 1.64V5.6a.8.8 0 0 0 1.6 0V1.64a10.39 10.39 0 0 1 9.562 9.56H18.4a.8.8 0 0 0 0 1.6h3.962a10.391 10.391 0 0 1-9.562 9.56z" // tslint:disable-line
      />
    </svg>
  );

  return <Icon component={Svg} {...props} />;
};

function SearchInput(props: Props) {
  /** to avoid react error message for unknown props */
  const innerProps = omit({ ...props }, 'onGeoLocate');

  return (
    <SearchInputStyles
      prefix={<SearchIcon />}
      suffix={<GeoIcon onClick={props.onGeoLocate} />}
      enterButton="Search"
      placeholder={props.placeholder}
      disabled-search={props['disabled-search']}
      {...innerProps}
    />
  );
}

export default SearchInput;

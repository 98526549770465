export const isIntergratedFlow = orderStore => orderStore.isIntegratedFlow;

export const convertFromAddress = ({
  postcode,
  phoneNumber,
  email,
  fullName,
  addressLine1,
  addressLine2,
  city,
  state,
}) => ({
  postcode,
  phoneNumber,
  email,
  name: fullName,
  line1: addressLine1,
  line2: addressLine2,
  town: city,
  area: state,
});

interface IIntegratedOrder {
  area: string | null;
  country: string | null;
  line1: string | null;
  line2: string | null;
  line3: string | null;
  name: string | null;
  orderDate: string | null;
  phoneNumber: string | null;
  postcode: string | null;
  town: string | null;
}

export const convertFromAddressIntegrated = (
  email: string,
  { postcode, phoneNumber, name, line1, line2, town, area }: IIntegratedOrder
) => ({
  postcode,
  phoneNumber,
  email,
  name,
  line1,
  line2,
  town,
  area,
});

export const convertToAddress = ({
  line1,
  line2,
  town,
  area,
  returnCompanyName,
  departmentName,
  toPostcode,
  toPhoneNumber,
}) => ({
  line1,
  line2,
  town,
  area,
  name: returnCompanyName,
  deptName: departmentName,
  postcode: toPostcode,
  phoneNumber: toPhoneNumber,
  email: 'apemails@doddle.com',
});

export const convertReturns = ({
  purchaseDate,
  returnReason,
  confirmTerms,
  returnItems,
  reasonComments,
  returnsAuthorisationNumber,
  isIntegrated,
}) => ({
  purchaseDate,
  returnReason,
  confirmTerms,
  reasonComments,
  returnsAuthorisationNumber,
  ...(isIntegrated && {
    returnItems,
  }),
});

const convertAddressForMap = ({
  postcode,
  line1,
  line2,
  town,
  area,
}: IIntegratedOrder) => ({
  addressLine1: line1,
  addressLine2: line2,
  city: town,
  postcode,
  state: area,
});

const checkForReturnedProducts = false;

const assetsForStoresEnabled = true;

export default {
  convertFromAddress,
  convertToAddress,
  convertAddressForMap,
  convertReturns,
  convertFromAddressIntegrated,
  checkForReturnedProducts,
  assetsForStoresEnabled,
};

import React from 'react';
import { StyledButton } from './elements';

type Props = {
  [key: string]: any;
};

const Button: any = ({ integratedFlow, ...props }: Props) => (
  <StyledButton {...props}>{props.children}</StyledButton>
);

export default Button;

import styled from 'styled-components';
import { Button } from '_common/components';
import { StyledTextArea } from '_common/components/Input/elements';

export const ReturnItemWrapper = styled.div`
  min-width: calc(360px - 40px); /* min device width - double padding */
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  border-top: solid 1px #eeeeee;
  &:last-of-type {
    border-bottom: solid 1px #eeeeee;
  }
  ${StyledTextArea} {
    margin-bottom: 15px;
  }
`;
export const ItemContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
`;

export const Image = styled.div<{ imageUrl: string }>`
  width: 96px;
  min-width: 96px;
  height: 96px;
  min-height: 96px;

  background-image: url(${({ imageUrl }) => imageUrl});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  @media only screen and (max-width: 768px) {
    width: 80px;
    min-width: 80px;
    height: 80px;
    min-height: 80px;
  }
`;

export const DetailsBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 10%;
  @media only screen and (max-width: 768px) {
    margin-right: 33px;
    margin-left: 7px;
  }
`;

export const Description = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 14px;
  font-weight: bold;
  line-height: 2.14;
  letter-spacing: 0;
  color: #000000;
`;

export const DetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonsBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const ReturnButton = styled(Button)`
  && {
    border-radius: 3px;
    font-family: ${props => props.theme.fonts.basic};
    font-size: 14px;
    letter-spacing: 0;
    text-align: center;
    color: #231f20;
    background: #fff;
    border: 1px solid #c7c7cc;

    width: 120px;
    height: 36px;

    @media only screen and (max-width: 768px) {
      width: 100px;
      height: 36px;
    }

    &:hover,
    &:active,
    &:focus {
      background: #fff;
      color: #222222;
      border: 1px solid #949499;
      opacity: 1;
    }
  }
`;

export const SelectedReturnButton = styled(ReturnButton)`
  &&& {
    min-width: 92px;
    min-height: 92px;

    & > span {
      white-space: normal;
      word-break: break-word;
    }
  }
`;

export const ReturnItemError = styled.div`
  color: #d0021b;
  font-size: 12px;
`;

import styled from 'styled-components';

export const ModalBody = styled.div`
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #979797;
  background-color: #f4f4f5;
  z-index: 55;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export const ModalOverlay = styled.div`
  && {
    background: rgba(0, 0, 0, 0.8);
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    max-width: inherit;
    position: fixed;
    transition: opacity 1s ease-in;
    overflow-y: auto;
    width: 100%;
    z-index: 99999;
  }
`;

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Wrapper,
  MerchantLogo,
  PrimaryText,
  MobileLogo,
  DesktopLogo,
  SecondaryText,
  Container,
} from '../elements';
import { Layout } from '_common/components';
import { compose } from 'recompose';
import { RouteComponentProps } from 'react-router-dom';

import { StatusTracker } from 'pages/tracking/components/StatusTracker';
import { TrackersList } from 'pages/tracking/elements/tracker';
import { ThemeStore, TrackingStore } from 'stores';
import commonStoresActions from '_common/actions';
import qs from 'qs';
import {
  RightSectionImage,
  RightSectionWrapper,
} from '_common/global-elements/pages';
import { withWhitelabelProps } from '_common/whitelabelConfig';
import amplitude from '_common/utils/amplitude';
import { TrackingEventWithInternalState } from 'types/tracking';

type Props = RouteComponentProps & {
  trackingStore: TrackingStore;
  themeStore: ThemeStore;
  whiteLabeled: any;
};

type State = {
  trackingId: string;
};

@observer
class TrackingPage extends Component<Props, State> {
  static PAGE_NAME = 'Tracking page';

  constructor(props) {
    super(props);
    const { id: trackingId } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    this.state = {
      trackingId,
    };
  }

  componentDidMount() {
    amplitude.logEventWithOrganisationAndUrl('page_open', {
      page_name: TrackingPage.PAGE_NAME,
    });
    commonStoresActions.getTrackingInfo(this.state.trackingId);
  }

  renderTrackers = () => {
    const {
      trackingStore: { events },
    } = this.props;
    return (
      <TrackersList>
        {[...events.values()].map((event: TrackingEventWithInternalState) => (
          <StatusTracker key={event.doddleTrackingEventId} {...event} />
        ))}
      </TrackersList>
    );
  };

  render() {
    const {
      themeStore: { widthOfBackgroundImageWithoutFooter },
      trackingStore: { events, latestEvent },
      whiteLabeled: { logo },
    } = this.props;

    return (
      <Wrapper>
        <Container>
          <Layout.LeftSection>
            <MerchantLogo />
            <PrimaryText>{latestEvent}</PrimaryText>
            <SecondaryText>
              <span>Tracking number</span>
              <span>{this.state.trackingId}</span>
            </SecondaryText>
            {events.size ? this.renderTrackers() : null}
            <MobileLogo image={logo} />
          </Layout.LeftSection>
          <Layout.RightSection
            widthOfBackgroundImage={widthOfBackgroundImageWithoutFooter}
          >
            <RightSectionWrapper>
              <DesktopLogo image={logo} />
              <RightSectionImage />
            </RightSectionWrapper>
          </Layout.RightSection>
        </Container>
      </Wrapper>
    );
  }
}

export default compose(
  inject('trackingStore', 'themeStore'),
  withWhitelabelProps({
    trackersList: 'ui.pages.tracking.trackersList',
    logo: 'ui.common.carrierLogo',
  })
)(TrackingPage);

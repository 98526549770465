import React from 'react';
import { StyledInputLabel } from './elements';

type Props = {
  [key: string]: any;
};

export function InputLabel(props: Props) {
  return <StyledInputLabel {...props} />;
}

export default InputLabel;

import apiEndpoins from '_common/api/endpoints';
import {
  API_ERROR_CODES,
  AXIOS_CANCELLED,
} from '_common/constants/apiErrorResponces';
import { localStore as storage, TOKEN_KEY } from 'storage';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Base64 } from 'js-base64';
import createClient from './clientFactory';
import { includes } from 'lodash';
import commonStoresActions from '_common/actions';

const apiKey = process.env.REACT_APP_DODDLE_API_KEY || '';
const apiSecret = process.env.REACT_APP_DODDLE_API_SECRET || '';

/** To avoid unresolved token need to wrap getting token in promise. */
let tokenPromiseResolver;
const tokenPromise = new Promise(resolve => (tokenPromiseResolver = resolve));

/** It would be fired once. */
storage.watch(TOKEN_KEY, newTokenValue => {
  if (newTokenValue) {
    tokenPromiseResolver(newTokenValue);
  }
});

const pureClient = createClient({});

const authClient = createClient({
  baseUrl: apiEndpoins.LEGACY_DODDLE_API_URL,
  headers: {
    Authorization: `Basic ${Base64.encode(`${apiKey}:${apiSecret}`)}`,
  },
});

const apiClient = createClient({
  baseUrl: apiEndpoins.LEGACY_DODDLE_API_URL,
  headers: {
    'cache-control': 'no-cache',
  },
});

const assetsClient = createClient({
  baseUrl: apiEndpoins.ASSETS_URL,
  headers: {},
});

const interceptorsReq = (config: AxiosRequestConfig) => ({
  ...config,
  headers: {
    ...config.headers,
  },
});

const apiClientInterceptorsReq = async (config: AxiosRequestConfig) => {
  const token = await tokenPromise;
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    },
  };
};

const interceptorsReqError = (error: any) => {
  Promise.reject(error);
};

const interceptorsRes = (response: AxiosResponse) => {
  const { headers, status, data } = response;

  /** This is a huge work-around above proxy on cloudfront.
   *  Justification: Proxy always returns HTML file for case if requested resource not found (or 403 also).
   *  We cannot remove that logic, otherwise FE will just fails with error for /url/part, which handled by SPA.
   *  Need to manually throw an error for that case, instead of trying to handle it as success flow.
   * */
  if (
    headers['content-type'] === 'text/html' &&
    headers['x-cache'] === 'Error from cloudfront' &&
    status === 200 &&
    includes(data, 'You need to enable JavaScript to run this app.')
  ) {
    const customError: any = new Error('Application config not found');
    customError.response = response;
    customError.request = response.request;
    customError.config = response.config;
    customError.isAxiosError = true;

    return Promise.reject(customError);
  }
  return response;
};

const interceptorsResError = (error: AxiosError) => {
  if (axios.isCancel(error)) {
    return Promise.reject(AXIOS_CANCELLED);
  }
  if (!error.response) {
    return Promise.reject(Error('Empty error response'));
  }

  if (error.response.status === 401) {
    if (
      error.response.data.errors.some(
        (err: { code: string }) =>
          err.code === API_ERROR_CODES.InvalidUserCredentials
      )
    ) {
      return Promise.reject(error);
    }

    commonStoresActions.logout();
  }

  return Promise.reject(error);
};

authClient.interceptors.request.use(interceptorsReq, interceptorsReqError);
authClient.interceptors.response.use(interceptorsRes, interceptorsResError);

apiClient.interceptors.request.use(
  apiClientInterceptorsReq,
  interceptorsReqError
);
apiClient.interceptors.response.use(interceptorsRes, interceptorsResError);

assetsClient.interceptors.request.use(interceptorsReq, interceptorsReqError);
assetsClient.interceptors.response.use(interceptorsRes, interceptorsResError);

export { authClient, apiClient, assetsClient, pureClient };
